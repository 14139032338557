import React, {createContext, useState} from 'react';
import PromoService from "../services/PromoService";
import confirmationModal from "../components/common/ConfirmationModal";

export const PromoContext = createContext("PromoContext");

const PromoContextProvider = ({children}) => {

    const [promos, setPromos] = useState(null);
    const [promo, setPromo] = useState();
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [totalElements, setTotalElements] = useState('');

    async function getAllPromos({page, size, id, promoCode}) {

        try {
            setLoading(true);
            setErrorMsg('');
            const res = await PromoService.getAllPromos(page, size, id, promoCode);
            setPromos(res.data.content)
            setTotalElements(res.data.totalElements);
            setLoading(false);
            return res.data.content;
        } catch (error) {
            setLoading(false);
            setErrorMsg("Can not load Promos. Please check the console.");
            console.log("error", console.error());
            return false;
        }

    }

    async function getPromoById(id) {

        try {
            setLoading(true);
            setErrorMsg('');
            const res = await PromoService.getPromoById(id);
            setPromo(res.data);
            setLoading(false);
            return 200;
        } catch (error) {
            setLoading(false);
            setErrorMsg("Can not load Promos. Please check the console.");
            console.log("error", error)
            return error.response ? error.response.status : 500;
        }

    }

    async function addPromo(data) {

        try {
            setErrorMsg('');
            setLoading(true);
            const res = await PromoService.addPromo(data);
            setPromo(res.data);
            setLoading(false);
            confirmationModal(`Promo Id - ${res.data.id} saved successfully!`, "success");
            return true;
        } catch (err) {
            setLoading(false);
            const message = err.response ? err.response.data.message : err.message;
            setErrorMsg("Can not add Promos. Please check the console.");
            console.log("post promo error", message);
            confirmationModal("Can not save Promo. " + message, "error");
            return false;
        }

    }

    async function updatePromo(data, id) {
        try {
            setLoading(true);
            setErrorMsg('');
            const res = await PromoService.updatePromo(data, id);
            setPromo(res.data);
            confirmationModal(`Promo Id - ${res.data.id} updated successfully!`, "success");
            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);
            const message = error.response ? error.response.data.message : error.message;
            confirmationModal("Can not update promo. " + message, "error");
            setErrorMsg("Can not update Promo. Please check the console.");
            console.log("Promo update error.", error);
            return false;
        }

    }

    async function deletePromo(id) {

        if (!window.confirm("Are you sure you want to delete this Promo ?")) return;

        try {
            setLoading(true);
            setErrorMsg('');
            await PromoService.deletePromo(id);
            let updatedPromos = promos;
            updatedPromos = promos.filter(e => e.id !== id);
            setPromos(updatedPromos);
            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);
            const message = error.response ? error.response.data.message : error.message;
            confirmationModal("Can not deleted promo. " + message, "error");
            setErrorMsg("Can not delete Promos. Please check the console.");
            console.log("Promo delete error.", error);
            return false;
        }
    }


    return (
        <PromoContext.Provider
            value={{
                promos,
                promo,
                getPromoById,
                loading,
                errorMsg,
                totalElements,
                getAllPromos,
                updatePromo,
                deletePromo,
                addPromo
            }}
        >
            {children}
        </PromoContext.Provider>
    );
}

export default PromoContextProvider;