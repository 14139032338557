import * as URL from "../helpers/Constants";
import axios from "axios";

export default class NotificationService {
    static getAllNotifications(page, size, id, title, notificationType) {
        return axios.get(URL.GET_ALL_NOTIFICATIONS, {
            ...NotificationService.getAuthHeader(),
            params: {page, size, id, title, notificationType}
        });
    }

    static getNotificationById(id) {
        return axios.get(`${URL.GET_NOTIFICATION_BY_ID}/${id}`, NotificationService.getAuthHeader());
    }

    static addNotification(data) {
        return axios.post(URL.POST_NOTIFICATION, data, NotificationService.getAuthHeader());
    }

    static updateNotification(data, id) {
        return axios.put(`${URL.UPDATE_NOTIFICATION}/${id}`, data, NotificationService.getAuthHeader());
    }

    // static deleteNotification(id) {
    //     return axios.delete(`${URL.DELETE_NOTIFICATION}/${id}`, NotificationService.getAuthHeader());
    // }

    static getAuthHeader() {
        const accessToken = localStorage.getItem("oushodsheba_access_token");
        const options = {headers: {"Authorization": `Bearer ${accessToken}`}}
        return options;
    }

    // static changeNotificationStatus(email, status) {
    //     return axios.post(`${URL.UPDATE_NOTIFICATION_STATUS}/${email}?status=${status}`)
    // }
}