import axios from "axios";
import * as URL from "../../helpers/Constants";

export default class PMSConfigServices {

    static addPrintingConfig(data, id) {
        return axios.post(`${URL.ADD_PRINTING_CONFIG}/${id}`, data, PMSConfigServices.getAuthHeader())
    }

    static getPrintingConfigByPharmacyId(id) {
        return axios.get(`${URL.GET_PRINTING_CONFIG_BY_ID}/${id}`, PMSConfigServices.getAuthHeader())
    }

    static getAuthHeader() {
        const accessToken = localStorage.getItem("oushodsheba_access_token");
        return {headers: {"Authorization": `Bearer ${accessToken}`}};
    }

}