import React, {createContext, useState} from 'react';
import ThanaService from "../services/ThanaService";
import confirmationModal from "../components/common/ConfirmationModal";

export const ThanaContext = createContext("ThanaContext");

const ThanaContextProvider = ({children}) => {

    const [thanas, setThanas] = useState(null);
    const [thana, setThana] = useState(null);
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [totalElements, setTotalElements] = useState(0);

    async function getAllThanas({page, size, id, name, districtName}) {

        try {
            setLoading(true);

            setErrorMsg('');
            const res = await ThanaService.getAllThanas(page, size, id, name, districtName);
            setThanas(res.data.content);
            setTotalElements(res.data.totalElements);
            setLoading(false);
            return res.data.content;
        } catch (error) {
            setLoading(false);
            setErrorMsg("Can not load Thanas. Please check the console.");
            console.log("error", error)
            return false;
        }

    }

    async function getThanaById(id) {

        try {
            setLoading(true);

            setErrorMsg('');
            const res = await ThanaService.getThanaById(id);
            setThana(res.data);
            setLoading(false);
            return 200;
        } catch (error) {
            setLoading(false);
            setErrorMsg("Can not load Thanas. Please check the console.");
            console.log("error", error)
            return error.response ? error.response.status : 500;
        }

    }

    async function addThana(data) {

        try {
            setErrorMsg('');
            setLoading(true);
            const res = await ThanaService.addThana(data)
            setThana(res.data);
            setLoading(false);
            confirmationModal("Thana saved successfully.", "success");
            return res.data;
        } catch (error) {
            const message = error.response ? error.response.data.message : error.message;
            setErrorMsg("Can not add Thanas. Please check the console.");
            console.log("post thana error", message);
            confirmationModal("Can not save Thana. " + message, "error");
            return false;
        }

    }

    async function updateThana(data, id) {
        try {
            setLoading(true);
            setErrorMsg('');
            const res = await ThanaService.updateThana(data, id);
            setThana(res.data);
            setLoading(false);
            confirmationModal("Thana updated successfully.", "success");
            return res.data;
        } catch (error) {
            const message = error.response ? error.response.data.message : error.message;
            setErrorMsg("Can not update Thana. Please check the console.");
            confirmationModal("Can not update Thana. " + message, "error");
            console.log("Thana update error.", error);
            return false;
        }

    }

    async function deleteThana(id) {

        if (!window.confirm("Are you sure you want to delete this Thana ?")) return;

        try {
            setLoading(true);
            setErrorMsg('');
            await ThanaService.deleteThana(id);
            let updatedThanas = thanas;
            updatedThanas = thanas.filter(e => e.id !== id);
            setThanas(updatedThanas);
            setLoading(false);
            return true;
        } catch (error) {
            setErrorMsg("Can not delete Thanas. Please check the console.");
            console.log("Thana delete error.", error);
            return false;
        }
    }

    return (
        <ThanaContext.Provider
            value={{
                thanas,
                thana,
                totalElements,
                loading,
                errorMsg,
                getAllThanas,
                updateThana,
                deleteThana,
                getThanaById,
                addThana
            }}
        >
            {children}
        </ThanaContext.Provider>
    );
}

export default ThanaContextProvider;