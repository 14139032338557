import React, {createContext, useState} from 'react';
import GlobalConfigService from "../services/GlobalConfigService";
import confirmationModal from "../components/common/ConfirmationModal";

export const GlobalConfigContext = createContext("GlobalConfigContext");


const GlobalConfigContextProvider = ({children}) => {

    const [globalConfigs, setGlobalConfigs] = useState(null);
    const [globalConfig, setGlobalConfig] = useState();
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [totalElements, setTotalElements] = useState('');

    async function getAllGlobalConfigs({page, size, id}) {

        try {
            setLoading(true);
            setErrorMsg('');
            const res = await GlobalConfigService.getAllGlobalConfigs(page, size, id);
            setGlobalConfigs(res.data.content)
            setTotalElements(res.data.totalElements);
            setLoading(false);
            return res.data.content;
        } catch (error) {
            setLoading(false);
            setErrorMsg("Can not load GlobalConfigs. Please check the console.");
            console.log("error", console.error());
            return false;
        }

    }

    async function getAllActiveGlobalConfigs() {

        try {
            setLoading(true);
            setErrorMsg('');
            const res = await GlobalConfigService.getAllActiveGlobalConfigs();
            setGlobalConfigs(res.data.content)
            setTotalElements(res.data.totalElements);
            setLoading(false);
            return res.data.content;
        } catch (error) {
            setLoading(false);
            setErrorMsg("Can not load GlobalConfigs. Please check the console.");
            console.log("error", console.error());
            return false;
        }

    }

    async function getGlobalConfigById(id) {

        try {
            setLoading(true);
            setErrorMsg('');
            const res = await GlobalConfigService.getGlobalConfigById(id);
            setGlobalConfig(res.data);
            setLoading(false);
            return res.data;
        } catch (error) {
            setLoading(false);
            setErrorMsg("Can not load GlobalConfigs. Please check the console.");
            console.log("error", error)
            return false;
        }

    }

    async function addGlobalConfig(data) {

        try {
            setErrorMsg('');
            setLoading(true);
            const res = await GlobalConfigService.addGlobalConfig(data)
            setGlobalConfig(res.data);
            setLoading(false);
            confirmationModal("GlobalConfig saved successfully. ", "success");
            return true;
        } catch (err) {
            setLoading(false);
            const message = err.response ? err.response.data.message : err.message;
            setErrorMsg("Can not add Global Configs. Please check the console.");
            console.log("post globalConfig error", message);
            confirmationModal("Can not save GlobalConfig. " + message, "error");
            return false;
        }

    }

    async function updateGlobalConfig(data, id) {
        try {
            setLoading(true);
            setErrorMsg('');
            const res = await GlobalConfigService.updateGlobalConfig(data, id);

            setGlobalConfig(res.data);
            confirmationModal("GlobalConfig updated successfully. ", "success");
            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);
            const message = error.response ? error.response.data.message : error.message;
            setErrorMsg("Can not update GlobalConfig. Please check the console.");
            confirmationModal("Can not update Global Config. " + message, "error");
            return false;
        }

    }

    async function deleteGlobalConfig(id) {

        if (!window.confirm("Are you sure you want to delete this GlobalConfig ?")) return;

        try {
            setLoading(true);
            setErrorMsg('');
            await GlobalConfigService.deleteGlobalConfig(id);
            let updatedGlobalConfigs = globalConfigs;
            updatedGlobalConfigs = globalConfigs.filter(e => e.id !== id);
            setGlobalConfigs(updatedGlobalConfigs);
            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);
            setErrorMsg("Can not delete GlobalConfigs. Please check the console.");
            console.log("GlobalConfig delete error.", error);
            return false;
        }
    }


    return (
        <GlobalConfigContext.Provider
            value={{
                globalConfigs,
                globalConfig,
                getGlobalConfigById,
                loading,
                errorMsg,
                totalElements,
                getAllGlobalConfigs,
                getAllActiveGlobalConfigs,
                updateGlobalConfig,
                deleteGlobalConfig,
                addGlobalConfig
            }}
        >
            {children}
        </GlobalConfigContext.Provider>
    );
}

export default GlobalConfigContextProvider;