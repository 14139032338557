import React, {createContext, useState} from "react";
import PMSVendorPaymentServices from "../../services/pms_services/PMSVendorPaymentServices";
import ErrorModal from "../../components/common/ErrorModal";
import Notification from "../../components/common/Notification";

export const PMSVendorPaymentContext = createContext("PMSVendorPaymentContext");

const PMSVendorPaymentContextProvider = ({children}) => {
    const [loading, setLoading] = useState(false);
    const [loadingCancelVendorPayment, setLoadingCancelVendorPayment] = useState(
        false
    );
    const [loadingCreateVendorPayment, setLoadingCreateVendorPayment] = useState(
        false
    );
    const [errorMsg, setErrorMsg] = useState("");

    const [totalElements, setTotalElements] = useState(0);
    const [payments, setPayments] = useState([]);

    const getPayments = async (id, params) => {
        try {
            setLoading(true);
            setErrorMsg("");
            const res = await PMSVendorPaymentServices.getPayments(id, params);
            setPayments(res.data.content);
            setTotalElements(res.data.totalElements);
            setLoading(false);
            return false;
        } catch (error) {
            setLoading(false)
            ErrorModal(error)
            return false;
        }
    }

    const createPayment = async (data) => {
        try {
            setLoadingCreateVendorPayment(true)
            setErrorMsg("")
            await PMSVendorPaymentServices.createPayment(data)
            Notification("success", "Success", "Payment added successfully.");
            setLoadingCreateVendorPayment(false)
            return true
        } catch (error) {
            setLoadingCreateVendorPayment(false)
            ErrorModal(error)
            return false
        }
    }

    const cancelPayment = async (customerId, search, id) => {
        try {
            setLoadingCancelVendorPayment(true)
            setErrorMsg("")
            await PMSVendorPaymentServices.cancelPayment(id)
            Notification("success", "Canceled", "Payment successfully canceled");
            setLoadingCancelVendorPayment(false)
            return true;
        } catch (error) {
            setLoadingCancelVendorPayment(false)
            ErrorModal(error)
            return false
        }
    }

    return (
        <PMSVendorPaymentContext.Provider
            value={{
                loading,
                errorMsg,
                payments,
                totalElements,
                loadingCreateVendorPayment,
                getPayments,
                createPayment,
                cancelPayment,
                loadingCancelVendorPayment,
            }}
        >
            {children}
        </PMSVendorPaymentContext.Provider>
    );
};

export default PMSVendorPaymentContextProvider;
