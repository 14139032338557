import React, {createContext, useContext, useState} from 'react';
import AuthService from "../services/AuthService";
import {PMSPharmacyContext} from "./pms_contexts/PMSPharmacyContext";
import * as AppType from "../helpers/AppType";
import {PMSConfigContext} from "./pms_contexts/PMSConfigContextProvider";

export const AuthContext = createContext("AuthContext");

const auth = localStorage.getItem("oushodsheba_access_token");
let permissions_ = localStorage.getItem("oushodsheba_permissions");
let appType_ = localStorage.getItem("appType");
permissions_ = permissions_ ? JSON.parse(permissions_) : [];

const AuthContextProvider = ({children}) => {

    const pmsPharmacyContext = useContext(PMSPharmacyContext);
    const pmsConfigContext = useContext(PMSConfigContext);
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [isLogin, setIsLogin] = useState(!!auth);
    const [permissions, setPermissions] = useState([...permissions_, 'ALL']);
    const [appType, setAppType] = useState(appType_);

    const loginRequest = async (data, isPharmacist = false, adminLogin = false) => {
        try {
            setLoading(true);
            setErrorMsg("");
            const res = await AuthService.login(data, isPharmacist);
            localStorage.setItem("oushodsheba_access_token", res.data.token.access);
            let moduleBasePermissions = res.data.moduleBasePermissions;

            if (adminLogin) {
                setAppType(AppType.OS);
                localStorage.setItem("appType", AppType.OS);
            } else {
                setAppType(AppType.PMS);
                localStorage.setItem("appType", AppType.PMS);
            }

            if (isPharmacist) {
                localStorage.setItem("selectedPharmacy", JSON.stringify(res.data.liteUserDTO.pharmacy));
                pmsPharmacyContext.setSelectedPharmacy(res.data.liteUserDTO.pharmacy);
                pmsConfigContext.getPMSPrintingConfigByPharmacyId(res.data.liteUserDTO.pharmacy.id);
            }

            let permissionList = [];

            for (let e in moduleBasePermissions) {
                permissionList.push(e);
                moduleBasePermissions[e].forEach(p => {
                    permissionList.push(p.name);
                });
            }

            setPermissions(permissionList);
            localStorage.setItem("oushodsheba_permissions", JSON.stringify(permissionList));

            setIsLogin(true);
            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);
            const message = error.response ? error.response.data.message : error.message
            setErrorMsg(message);
            return false;
        }

    }

    const loginAdminRequest = async (data) => {
        try {
            setLoading(true);
            setErrorMsg("");
            const res = await AuthService.adminLogin(data);
            localStorage.setItem("oushodsheba_access_token", res.data.token.access);
            let moduleBasePermissions = res.data.moduleBasePermissions;

            setAppType(AppType.OS);
            localStorage.setItem("appType", AppType.OS);

            let permissionList = [];

            for (let e in moduleBasePermissions) {
                permissionList.push(e);
                moduleBasePermissions[e].forEach(p => {
                    permissionList.push(p.name);
                });
            }

            setPermissions(permissionList);
            localStorage.setItem("oushodsheba_permissions", JSON.stringify(permissionList));

            setIsLogin(true);
            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);
            const message = error.response ? error.response.data.message : error.message
            setErrorMsg(message);
            return false;
        }

    }

    const logoutRequest = () => {
        setIsLogin(false);
        localStorage.removeItem("selectedPharmacy");
        localStorage.removeItem("oushodsheba_access_token");
        localStorage.removeItem("oushodsheba_permissions");
        localStorage.removeItem("appType");
        localStorage.removeItem("printing_config");
        pmsPharmacyContext.setSelectedPharmacy(null);
        // localStorage.clear();
    }

    return (
        <AuthContext.Provider
            value={{
                loading,
                errorMsg,
                isLogin,
                appType,
                loginRequest,
                logoutRequest,
                loginAdminRequest,
                permissions
            }}
        >
            {children}
        </AuthContext.Provider>
    );
}

export default AuthContextProvider;