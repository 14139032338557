import axios from "axios";
import * as URL from "../helpers/Constants";

export default class TemporaryOrderService {

    static getAllDeliveryPlans(page, size) {
        return axios.get(URL.GET_ALL_TEMPORARY_ORDERS, {
            ...TemporaryOrderService.getAuthHeader(),
            params: {page, size}
        });
    }

    static getTemporaryOrderById(id) {
        return axios.get(`${URL.GET_TEMPORARY_ORDER_BY_ID}/${id}`, TemporaryOrderService.getAuthHeader());
    }

    static createTemporaryOrder(data) {
        return axios.post(URL.CREATE_TEMPORARY_ORDER, data, TemporaryOrderService.getAuthHeader());
    }


    static updateTemporaryOrderById(data, id) {
        return axios.put(`${URL.UPDATE_TEMPORARY_ORDER_BY_ID}/${id}`, data, TemporaryOrderService.getAuthHeader());
    }

    static deleteTemporaryOrder(id) {
        return axios.delete(`${URL.DELETE_TEMPORARY_ORDER_BY_ID}/${id}`, TemporaryOrderService.getAuthHeader());
    }


    static getAuthHeader() {
        const accessToken = localStorage.getItem("oushodsheba_access_token");
        const options = {headers: {"Authorization": `Bearer ${accessToken}`}}
        return options;
    }
}