import React, {createContext, useState} from "react";
import PMSCustomerPaymentServices from "../../services/pms_services/PMSCustomerPaymentServices";
import ErrorModal from "../../components/common/ErrorModal";
import Notification from "../../components/common/Notification";

export const PMSCustomerPaymentContext = createContext("PMSCustomerPaymentContext");

const PMSCustomerPaymentContextProvider = ({children}) => {
    const [loading, setLoading] = useState(false);
    const [loadingCancelCustomerPayment, setLoadingCancelCustomerPayment] = useState(
        false
    );
    const [loadingCreateCustomerPayment, setLoadingCreateCustomerPayment] = useState(
        false
    );
    const [errorMsg, setErrorMsg] = useState("");

    const [totalElements, setTotalElements] = useState(0);
    const [payments, setPayments] = useState([]);

    const getPayments = async (id, params) => {
        try {
            setLoading(true);
            setErrorMsg("");
            const res = await PMSCustomerPaymentServices.getPayments(id, params);
            setPayments(res.data.content);
            setTotalElements(res.data.totalElements);
            setLoading(false);
            return false;
        } catch (error) {
            setLoading(false)
            ErrorModal(error)
            return false;
        }
    }

    const createPayment = async (data) => {
        try {
            setLoadingCreateCustomerPayment(true)
            setErrorMsg("")
            await PMSCustomerPaymentServices.createPayment(data)
            Notification("success", "Success", "Payment successful.");
            setLoadingCreateCustomerPayment(false)
            return true
        } catch (error) {
            setLoadingCreateCustomerPayment(false)
            ErrorModal(error)
            return false
        }
    }

    const cancelPayment = async (customerId, search, id) => {
        try {
            setLoadingCancelCustomerPayment(true)
            setErrorMsg("")
            await PMSCustomerPaymentServices.cancelPayment(id)
            Notification("success", "Canceled", "Payment successfully canceled");
            setLoadingCancelCustomerPayment(false)
            return true;
        } catch (error) {
            setLoadingCancelCustomerPayment(false)
            ErrorModal(error)
            return false
        }
    }

    return (
        <PMSCustomerPaymentContext.Provider
            value={{
                loading,
                errorMsg,
                payments,
                totalElements,
                loadingCreateCustomerPayment,
                getPayments,
                createPayment,
                cancelPayment,
                loadingCancelCustomerPayment,
            }}
        >
            {children}
        </PMSCustomerPaymentContext.Provider>
    );
};

export default PMSCustomerPaymentContextProvider;
