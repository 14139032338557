import React, {createContext, useState} from 'react';
import PMSOfflineConfigService from "../services/PMSOfflineConfigService";
import Notification from "../components/common/Notification";

export const PMSOfflineConfigContext = createContext("PMSOfflineConfigContext");

const PMSOfflineConfigContextProvider = ({children}) => {

    const [loading, setLoading] = useState(false);
    const [updateLoading, setUpdateLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [totalElements, setTotalElements] = useState(0);
    const [configs, setConfigs] = useState([]);
    const [config, setConfig] = useState(null);

    const getAllPMSOfflineConfigs = async params => {

        try {
            setLoading(true);
            setErrorMsg('');
            const res = await PMSOfflineConfigService.getAllPMSOfflineConfigs(params);
            setConfigs(res.data.content)
            setTotalElements(res.data.totalElements);
            setLoading(false);
            return res.data.content;
        } catch (error) {
            setLoading(false);
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            Notification("error", "ERROR", `${_error}`);
            return false;
        }

    }

    const getPMSOfflineConfigById = async id => {

        try {
            setLoading(true);
            setErrorMsg('');
            const res = await PMSOfflineConfigService.getPMSOfflineConfigById(id);
            setConfig(res.data);
            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            Notification("error", "ERROR", `${_error}`);
            return false;
        }

    }

    const generatePMSOConfigToken = async pharmacyId => {
        try {
            setLoading(true);
            setErrorMsg('');
            const res = await PMSOfflineConfigService.generatePMSOConfigToken(pharmacyId);
            setLoading(false);
            return res.data;
        } catch (error) {
            setLoading(false);
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            Notification("error", "ERROR", `${_error}`);
            return false;
        }

    }

    const createPMSOfflineConfig = async data => {
        try {
            setErrorMsg('');
            setLoading(true);
            await PMSOfflineConfigService.createPMSOfflineConfig(data);
            Notification("success", "CREATED", "PMS offline config created successfully");
            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }

    const updatePMSOfflineConfig = async (data, id) => {
        try {
            setUpdateLoading(true);
            setErrorMsg('');
            await PMSOfflineConfigService.updatePMSOfflineConfig(data, id);
            Notification("success", "UPDATED", "PMS offline config updated successfully");
            setUpdateLoading(false);
            return true;
        } catch (error) {
            setUpdateLoading(false);
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            Notification("error", "ERROR", `${_error}`);
            return false;
        }

    }

    return (
        <PMSOfflineConfigContext.Provider
            value={{
                errorMsg,
                loading,
                updateLoading,
                totalElements,
                configs,
                config,
                getPMSOfflineConfigById,
                createPMSOfflineConfig,
                getAllPMSOfflineConfigs,
                updatePMSOfflineConfig,
                generatePMSOConfigToken,
            }}
        >
            {children}
        </PMSOfflineConfigContext.Provider>
    );
}

export default PMSOfflineConfigContextProvider;
