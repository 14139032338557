import * as URL from "../helpers/Constants";
import axios from "axios";

export default class HomepageProductService {
    static getAllHomepageProducts = params => {
        return axios.get(URL.GET_HOMEPAGE_PRODUCT_PRODUCTS,
            {...HomepageProductService.getAuthHeader(), params});
    }

    static getHomepageProductById = id => {
        return axios.get(`${URL.GET_HOMEPAGE_PRODUCT_BY_ID}/${id}`,
            HomepageProductService.getAuthHeader());
    }

    static addHomepageProduct = data => {
        return axios.post(URL.CREATE_HOMEPAGE_PRODUCT,
            data, HomepageProductService.getAuthHeader());
    }

    static updateHomepageProduct = (id, data) => {
        return axios.put(`${URL.UPDATE_HOMEPAGE_PRODUCT}/${id}`,
            data, HomepageProductService.getAuthHeader());
    }

    static getAuthHeader = () => {
        const accessToken = localStorage.getItem("oushodsheba_access_token");
        const options = {headers: {"Authorization": `Bearer ${accessToken}`}}
        return options;
    }
}
