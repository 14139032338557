import React, {createContext, useState} from 'react';
import UserService from "../services/UserService";
import confirmationModal from "../components/common/ConfirmationModal";
import Notification from "../components/common/Notification";

export const UserContext = createContext("UserContext");

const UserContextProvider = ({children}) => {

    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [user, setUser] = useState(null);
    const [anotherUser, setAnotherUser] = useState(null);
    const [profileLoading, setProfileLoading] = useState(false);
    const [totalElements, setTotalElements] = useState(0);

    async function getAllUsers({page, size, id, name, email, type}) {

        try {
            setLoading(true);
            const res = await UserService.getAllUsers(page, size, id, name, email, type);
            setUsers(res.data.content);
            setTotalElements(res.data.totalElements);
            setLoading(false);
            return res.data.content;
        } catch (error) {
            setLoading(false);
            console.log("error", console.error())
            return false;
        }

    }


    async function addUser(data) {

        try {
            const res = await UserService.addUser(data)
            setAnotherUser(res.data);
            confirmationModal(`User Id - ${res.data.id} save successfully.`, "success");
            return res.data;
        } catch (err) {
            const message = err.response ? err.response.data.message : err.message;
            console.log("post user error", message);
            confirmationModal("Can not save User. " + message, "error");
            return null;
        }

    }

    async function getUserById(id) {

        try {
            setLoading(true);

            setErrorMsg('');
            const res = await UserService.getUserById(id);
            setAnotherUser(res.data);
            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);
            setErrorMsg("Can not load Users. Please check the console.");
            console.log("error", error)
            return false;
        }

    }

    async function getUserProfile() {
        try {
            setProfileLoading(true);
            const res = await UserService.getProfile();
            setUser(res.data);
            setProfileLoading(false);
            return 200;
        } catch (error) {
            setProfileLoading(false);
            const message = error.response ? error.response.data.message : error.message;
            console.log("Get profile error", message);
            // if (error.response && error.response.status === 401) authContext.logoutRequest();
            return error.response ? error.response.status : 500;
        }
    }

    async function updateUser(data, id) {
        try {
            const res = await UserService.updateUser(data, id)
            confirmationModal(`User Id - ${res.data.id} save successfully.`, "success");
            return res.data;
        } catch (error) {
            const message = error.response ? error.response.data.message : error.message;
            confirmationModal("Can not update User. " + message, "error");
            return null;
        }

    }

    const updateProfile = async data => {
        try {
            await UserService.updateProfile(data);
            Notification("success", "Profile Updated", "Profile updated successfully");
            return true;
        } catch (error) {
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }

    async function deleteUser(id) {

        if (!window.confirm("Are you sure you want to delete this User ?")) return;

        try {
            await UserService.deleteUser(id);
            let updatedUsers = users;
            updatedUsers = users.filter(e => e.id !== id)
            setUsers(updatedUsers);
            return true;
        } catch {
            return false;
        }

    }

    const changeUserStatus = async (email, status) => {

        if (!window.confirm(`Are you sure you want to change this User status to ${status} ?`)) return;

        try {
            await UserService.changeUserStatus(email, status);
            let updatedUsers = users;
            updatedUsers = users.map(e => {
                if (e.email === email) e.status = status;
                return e;
            })
            setUsers(updatedUsers);
            return true;
        } catch {
            return false;
        }

    }

    const userImageUpload = async (data) => {
        try {
            setLoading(true);
            await UserService.addUserImages(data);
            setLoading(false);
            return true;
        } catch (err) {
            setErrorMsg("Can't Load data. Please try the console.")
            return false;
        }
    }

    const changeLoggedUserPassword = async (data) => {
        try {
            await UserService.changeLoggedUserPassword(data);
            Notification("success", "Password Changed", "Password changed successfully");
            return true;
        } catch (error) {
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            Notification("error", "ERROR", `${_error}`);
            return false;
        }

    }

    const changeAnotherUserPasswordById = async (data, id) => {
        try {
            await UserService.changeAnotherUserPasswordById(data, id);
            Notification("success", "Password Changed", "Password changed successfully");
            return true;
        } catch (error) {
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            Notification("error", "ERROR", `${_error}`);
            return false;
        }

    }


    return (
        <UserContext.Provider
            value={{
                loading,
                errorMsg,
                users,
                user,
                anotherUser,
                userImageUpload,
                getAllUsers,
                getUserById,
                profileLoading,
                updateUser,
                deleteUser,
                addUser,
                getUserProfile,
                changeUserStatus,
                totalElements,
                changeLoggedUserPassword,
                changeAnotherUserPasswordById,
                updateProfile
            }}
        >
            {children}
        </UserContext.Provider>
    );
}

export default UserContextProvider;
