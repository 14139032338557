import React, {createContext, useState} from 'react';

export const AppContext = createContext("AppContext");

const AppContextProvider = ({children}) => {

    const [loading] = useState(false);

    return (
        <AppContext.Provider
            value={{
                loading
            }}
        >
            {children}
        </AppContext.Provider>
    );
}

export default AppContextProvider;
