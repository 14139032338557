import * as URL from "../helpers/Constants";
import axios from "axios";

export default class AuthService {
    static login(data, isPharmacist) {

        let url = URL.SIGN_IN;

        if (isPharmacist) {
            url = URL.PHARMACIST_SIGN_IN;
        }

        return axios.post(url, data);
    }

    static adminLogin(data) {

        return axios.post(URL.ADMIN_SIGN_IN, data);

    }

    static signUp(data) {
        return axios.post(URL.SIGN_IN, data);
    }
}