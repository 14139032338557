import * as URL from "../helpers/Constants";
import axios from "axios";

export default class RoleService {

    static getAllRoles(page, size, id, alias) {
        return axios.get(URL.GET_ALL_ROLES, {...RoleService.getAuthHeader(), params: {page, size, id, alias}});
    }

    static addRole(data, isPharmacy, pharmacyId) {
        return axios.post(URL.POST_ROLE, data, {...RoleService.getAuthHeader(), params: {isPharmacy, pharmacyId}});
    }

    static updateRole(data, id) {
        return axios.put(`${URL.UPDATE_ROLE}/${id}`, data, RoleService.getAuthHeader());
    }

    static changeRole(roleId, userId) {
        return axios.patch(`${URL.CHANGE_ROLE}/${userId}`, null, {params: {roleId}, ...RoleService.getAuthHeader()});
    }

    static deleteRole(id) {
        return axios.delete(`${URL.DELETE_ROLE}/${id}`, RoleService.getAuthHeader());
    }

    static getRoleById(id) {
        return axios.get(`${URL.GET_ROLE_BY_ID}/${id}`, RoleService.getAuthHeader());
    }


    static getAuthHeader() {
        const accessToken = localStorage.getItem("oushodsheba_access_token");
        const options = {headers: {"Authorization": `Bearer ${accessToken}`}}
        return options;
    }
}