import * as URL from "../helpers/Constants";
import axios from "axios";

export default class productCategoryService {
    static getAllProductCategories(page, size, id, title) {
        return axios.get(URL.GET_ALL_PRODUCT_CATEGORIES, {
            ...productCategoryService.getAuthHeader(),
            params: {page, size, id, title}
        });
    }

    static getAllPMSMedicineType(params) {
        return axios.get(`${URL.GET_ALL_PMS_MEDICINE_TYPE}`, {
            ...productCategoryService.getAuthHeader(),
            params
        });
    }

    static getProductCategoryById(id) {
        return axios.get(`${URL.GET_PRODUCT_CATEGORY_BY_ID}/${id}`, productCategoryService.getAuthHeader());
    }

    static addProductCategory(data) {
        return axios.post(URL.POST_PRODUCT_CATEGORY, data, productCategoryService.getAuthHeader());
    }

    static updateProductCategory(data, id) {
        return axios.put(`${URL.UPDATE_PRODUCT_CATEGORY}/${id}`, data, productCategoryService.getAuthHeader());
    }

    static setAsHomepage(categoryId, homePage) {
        return axios.patch(`${URL.SET_AS_HOMEPAGE}/${categoryId}`, null,{
            ...productCategoryService.getAuthHeader(),
            params: {homePage}
        });
    }

    static setAsHot(categoryId, hot) {
        return axios.patch(`${URL.SET_AS_HOT}/${categoryId}`, null,{
            ...productCategoryService.getAuthHeader(),
            params: {hot}
        });
    }

    static deleteProductCategory(id) {
        return axios.delete(`${URL.DELETE_PRODUCT_CATEGORY}/${id}`, productCategoryService.getAuthHeader());
    }

    static getAuthHeader() {
        const accessToken = localStorage.getItem("oushodsheba_access_token");
        const options = {headers: {"Authorization": `Bearer ${accessToken}`}}
        return options;
    }

    // static changeProductCategoryStatus(email, status) {
    //     return axios.post(`${URL.UPDATE_PRODUCT_CATEGORY_STATUS}/${email}?status=${status}`)
    // }
}
