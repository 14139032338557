import React, {createContext, useState} from 'react';
import HospitalService from "../services/HospitalService";
// import confirmationModal from "../components/common/ConfirmationModal";

export const HospitalContext = createContext("HospitalContext");


const HospitalContextProvider = ({children}) => {

    const [hospitals, setHospitals] = useState(null);
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [totalElements, setTotalElements] = useState('');

    async function getAllHospitals(name, page, size) {

        try {
            setLoading(true);
            setErrorMsg('');
            const res = await HospitalService.getAllHospitals(name, page, size);
            setHospitals(res.data.content)
            setTotalElements(res.data.totalElements);
            setLoading(false);
            return res.data.content;
        } catch (error) {
            setErrorMsg("Can not load Hospitals. Please check the console.");
            console.log("error", console.error());
            return false;
        }

    }

    return (
        <HospitalContext.Provider
            value={{
                hospitals,
                loading,
                errorMsg,
                totalElements,
                getAllHospitals
            }}
        >
            {children}
        </HospitalContext.Provider>
    );
}

export default HospitalContextProvider;