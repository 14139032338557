import React, { createContext, useState } from "react";
import PMSExpiredItemsServices from "../../services/pms_services/PMSExpiredItemsServices";
import Notification from "../../components/common/Notification";

export const PMSExpiredItemsContext = createContext("PMSExpiredItemsContextProvider");

const PMSExpiredItemsContextProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const [totalElements, setTotalElements] = useState(0);
  const [expiredItems, setExpiredItems] = useState([]);

  const getExpiredItemsByPharmacyId = async (params) => {
    try {
      setLoading(true);
      setErrorMsg("");
      const res = await PMSExpiredItemsServices.getExpiredItemsByPharmacyId(params);
      setExpiredItems(res.data.content);
      setTotalElements(res.data.totalElements);
      setLoading(false);
      return true;
    } catch (error) {
      setLoading(false);

      let _error = "Something went wrong.";

      if (error.message === "Network Error") {
        _error = error.message;
      }

      if (error.response && error.response.data) {
        _error = error.response.data.message;
      }
      setErrorMsg(_error);
      Notification("error", "ERROR", `${_error}`);
      return false;
    }
  };

  const generatePMSExpiredItemReportByPharmacyId = async (pharmacyId, params) => {
    try {
      setLoading(true);
      setErrorMsg("");
      const res = await PMSExpiredItemsServices.generatePMSExpiredItemReportByPharmacyId(pharmacyId, params);
      if (res) {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", res.headers["content-disposition"].split("filename=")[1]);
        document.body.appendChild(link);
        link.click();
      }
      setLoading(false);
      return res.data;
    } catch (error) {
      setLoading(false);

      let _error = "Something went wrong.";

      if (error.message === "Network Error") {
        _error = error.message;
      }

      if (error.response && error.response.data) {
        _error = error.response.data.message;
      }

      Notification("error", "ERROR", `${_error}`);
      return false;
    }
  };

  return (
    <PMSExpiredItemsContext.Provider
      value={{
        loading,
        errorMsg,
        totalElements,
        expiredItems,
        getExpiredItemsByPharmacyId,
        generatePMSExpiredItemReportByPharmacyId,
      }}
    >
      {children}
    </PMSExpiredItemsContext.Provider>
  );
};

export default PMSExpiredItemsContextProvider;
