import React, {createContext, useState} from "react";
import PMSPharmacistServices from "../../services/pms_services/PMSPharmacistServices";
import ConfirmationModal from "../../components/common/ConfirmationModal";

import Notification from "../../components/common/Notification";

export const PMSPharmacistContext = createContext("PMSPharmacistContext");

const PMSPharmacistContextProvider = ({children}) => {
    const [loading, setLoading] = useState(true);
    const [loadingCreatePharmacist, setLoadingCreatePharmacist] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [pagination, setPagination] = useState({
        total: 0,
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ["10", "30", "50", "100"],
    });

    const [loadingRoles, setLoadingRoles] = useState(false);
    const [roles, setRoles] = useState([]);

    const [totalElements, setTotalElements] = useState(0);
    const [pharmacists, setPharmacists] = useState([]);
    const [pharmacist, setPharmacist] = useState(null);

    const getAllPharmacistsByPharmacyId = async (params) => {
        try {
            setLoading(true);
            setErrorMsg("");

            const res = await PMSPharmacistServices.getAllPharmacistsByPharmacyId(
                params
            );

            setPagination({
                ...pagination,
                total: res.data.totalElements,
                current: params.page,
                pageSize: params.size,
            });

            setPharmacists(res.data.content);
            setTotalElements(res.data.totalElements);
            setLoading(false);

            return true;
        } catch (error) {
            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }

            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    };

    const getPharmacistById = async (id) => {
        try {
            setLoading(true);
            setErrorMsg("");

            const res = await PMSPharmacistServices.getPharmacistById(id);

            setPharmacist(res.data);
            setLoading(false);

            return true;
        } catch (error) {
            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }

            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    };

    const getPharmacistRole = async () => {
        try {
            setLoadingRoles(true);

            const res = await PMSPharmacistServices.getPharmacistRole();

            setRoles(res.data);
            setLoadingRoles(false);
        } catch (error) {
            setLoadingRoles(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }

            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    };

    const createPharmacistByPharmacyId = async (params) => {
        try {
            setLoadingCreatePharmacist(true);
            setErrorMsg("");
            await PMSPharmacistServices.createPharmacistByPharmacyId(
                params
            );
            ConfirmationModal(
                `Pharmacist created successfully!`,
                "success"
            );
            setLoadingCreatePharmacist(false);
            return true;
        } catch (error) {
            setLoadingCreatePharmacist(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }

            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    };

    const updatePharmacistByPharmacistId = async (id, data) => {
        try {
            setLoadingCreatePharmacist(true);
            setErrorMsg("");
            await PMSPharmacistServices.updatePharmacistByPharmacistId(
                id,
                data
            );
            ConfirmationModal(
                `Pharmacist updated successfully!`,
                "success"
            );
            setLoadingCreatePharmacist(false);
            return true;
        } catch (error) {
            setLoadingCreatePharmacist(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }

            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    };

    const deletePharmacistById = async (id) => {
        try {
            setLoading(true);
            setErrorMsg("");

            await PMSPharmacistServices.deletePharmacistById(id);
            setPharmacists(pharmacists.filter((pharmacist) => pharmacist.id !== id));

            Notification("success", "DELETED", "Pharmacist has been deleted successfully");

            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }

            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    };

    return (
        <PMSPharmacistContext.Provider
            value={{
                loading,
                errorMsg,
                pagination,
                setPagination,
                totalElements,
                pharmacists,
                pharmacist,
                loadingRoles,
                loadingCreatePharmacist,
                roles,
                getAllPharmacistsByPharmacyId,
                createPharmacistByPharmacyId,
                updatePharmacistByPharmacistId,
                deletePharmacistById,
                getPharmacistById,
                getPharmacistRole,
            }}
        >
            {children}
        </PMSPharmacistContext.Provider>
    );
};

export default PMSPharmacistContextProvider;
