import * as URL from "../helpers/Constants";
import axios from "axios";

export default class OfferDetailsService {
    static getAllOfferDetails(params) {
        return axios.get(URL.GET_ALL_OFFER_DETAILS, {...OfferDetailsService.getAuthHeader(), params});
    }

    static getOfferDetailsById = id => {
        return axios.get(`${URL.GET_OFFER_DETAILS_BY_ID}/${id}`, OfferDetailsService.getAuthHeader());
    }

    static addOfferDetails = (data) => {
        return axios.post(URL.ADD_OFFER_DETAILS, data, OfferDetailsService.getAuthHeader());
    }

    static updateOfferDetails = (data, offerDescriptionId) => {
        return axios.put(`${URL.UPDATE_OFFER_DETAILS}/${offerDescriptionId}`, data, OfferDetailsService.getAuthHeader());
    }

    static deleteOfferDetails = id => {
        return axios.delete(`${URL.DELETE_OFFER_DETAILS}/${id}`, OfferDetailsService.getAuthHeader());
    }

    static getAuthHeader = () => {
        const accessToken = localStorage.getItem("oushodsheba_access_token");
        const options = {headers: {"Authorization": `Bearer ${accessToken}`}}
        return options;
    }

    // static changeAreaStatus(email, status) {
    //     return axios.post(`${URL.UPDATE_GENERIC_STATUS}/${email}?status=${status}`)
    // }
}