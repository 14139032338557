import * as URL from "../helpers/Constants";
import axios from "axios";

export default class ReferralHistoryService {

    static getAllReferralHistory(params) {
        return axios.get(URL.REFERRAL_HISTORY_LIST, {...ReferralHistoryService.getAuthHeader(), params});
    }

    static getAuthHeader = () => {
        const accessToken = localStorage.getItem("oushodsheba_access_token");
        const options = {headers: {"Authorization": `Bearer ${accessToken}`}}
        return options;
    }
}