import React, {createContext, useState} from "react";
import Notification from "../components/common/Notification";
import HomepageProductService from "../services/HomepageProductService";

export const HomepageProductContext = createContext("HomepageProductContext");

const HomepageProductContextProvider = ({children}) => {

    const [homepageProducts, setHomepageProducts] = useState([]);
    const [homepageProduct, setHomepageProduct] = useState(null);
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [totalElements, setTotalElements] = useState(0);

    const getAllHomepageProducts = async params => {

        try {
            setLoading(true);
            setErrorMsg('');

            const res = await HomepageProductService.getAllHomepageProducts(params);

            setHomepageProducts(res.data.content);
            setTotalElements(res.data.totalElements);
            setLoading(false);

            return res.data.content;
        } catch (error) {
            setLoading(false);
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }

    }


    const addHomepageProduct = async data => {

        try {
            setLoading(true);
            const res = await HomepageProductService.addHomepageProduct(data)
            setLoading(false);

            Notification("success", "Created", "Home Page Product Created Successfully");

            return res.data;
        } catch (error) {
            setLoading(false);
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }

    }

    const updateHomepageProduct = async (id, data) => {

        try {
            setLoading(true);
            await HomepageProductService.updateHomepageProduct(id, data);
            setLoading(false);

            Notification("success", "Updated", "Home Page Product Updated Successfully");

            return true;
        } catch (error) {
            setLoading(false);
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }

    }

    const getHomepageProductById = async id => {

        try {
            setLoading(true);
            setErrorMsg('');

            const res = await HomepageProductService.getHomepageProductById(id);

            setHomepageProduct(res.data);
            setLoading(false);

            return res.data;
        } catch (error) {
            setLoading(false);
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }

    }


    return (
        <HomepageProductContext.Provider
            value={{
                loading,
                errorMsg,
                totalElements,
                homepageProducts,
                homepageProduct,
                getAllHomepageProducts,
                getHomepageProductById,
                addHomepageProduct,
                updateHomepageProduct,
            }}
        >
            {children}
        </HomepageProductContext.Provider>
    );
}

export default HomepageProductContextProvider;
