import * as URL from "../helpers/Constants";
import axios from "axios";

export default class PharmacyService {


    static getAllPharmacies(page, size, id, name, pharmacyOwnerName, pharmacyOwnerPhone) {
        return axios.get(URL.GET_ALL_PHARMACIES, {
            ...PharmacyService.getAuthHeader(),
            params: {page, size, id, name, pharmacyOwnerName, pharmacyOwnerPhone}
        });
    }

    static getPharmacyById(id) {
        return axios.get(`${URL.GET_PHARMACY_BY_ID}/${id}`, PharmacyService.getAuthHeader());
    }

    static getSubscriptionPlanForPharmacy() {
        return axios.get(`${URL.GET_SUBSCRIPTION_PLAN_FOR_PHARMACY}`, PharmacyService.getAuthHeader());
    }

    static addPharmacy(data, pharmacyOwnerId) {

        return axios.post(URL.POST_PHARMACY, data, {
            ...PharmacyService.getAuthHeader(),
            params: {pharmacyOwnerId}
        });
    }

    static updatePharmacy(data, id) {
        return axios.put(`${URL.UPDATE_PHARMACY}/${id}`, data, PharmacyService.getAuthHeader());
    }

    static initPharmacyDefaultProduct(id) {
        return axios.post(`${URL.INIT_PHARMACY_DEFAULT_PRODUCT}/${id}`, null, PharmacyService.getAuthHeader());
    }

    static initPharmacyInventoryFile(id, data) {
        return axios.post(`${URL.INIT_PHARMACY_INVENTORY_FILE}/${id}`, data,
            {headers: {"Authorization": `Bearer ${localStorage.getItem("oushodsheba_access_token")}`, 'content-type' :'multipart/form-data'}});
    }

    static deletePharmacy(id) {
        return axios.delete(`${URL.DELETE_PHARMACY}/${id}`, PharmacyService.getAuthHeader());
    }

    static getPharmacyRolesById(id) {
        return axios.get(`${URL.GET_PHARMACY_ROLES_BY_ID}/${id}`, PharmacyService.getAuthHeader());
    }

    static getPharmacyStatistics(id) {
        return axios.get(`${URL.GET_PHARMACY_STATISTICS}/${id}`, PharmacyService.getAuthHeader());
    }

    static checkPharmacyAsOnline(id, status) {
        return axios.patch(`${URL.CHECK_PHARMACY_AS_ONLINE}/${id}`, null, {
            ...PharmacyService.getAuthHeader(),
            params: {status}
        });
    }

    static getAuthHeader() {
        const accessToken = localStorage.getItem("oushodsheba_access_token");
        const options = {headers: {"Authorization": `Bearer ${accessToken}`}}
        return options;
    }

    // static getAuthHeader() {
    //     const accessToken = localStorage.getItem("oushodsheba_access_token");
    //     return {headers: {"Authorization": `Bearer ${accessToken}`, 'content-type' :'multipart/form-data'}};
    // }

    // static changePharmacyStatus(email, status) {
    //     return axios.post(`${URL.UPDATE_PHARMACY_STATUS}/${email}?status=${status}`)
    // }
}