import React, {createContext, useState} from "react";
import OfferDetailsService from "../services/OfferDetailsService";
import Notification from "../components/common/Notification";

export const OfferDetailsContext = createContext("OfferDetailsContext");

const OfferDetailsContextProvider = ({children}) => {

    const [offerDetailsList, setOfferDetailsList] = useState([]);
    const [offerDetails, setOfferDetails] = useState(null);
    const [loading, setLoading] = useState(false);
    const [offerDetailsAddLoading, setOfferDetailsAddLoading] = useState(false);
    const [offerDetailsEditLoading, setOfferDetailsEditLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [totalElements, setTotalElements] = useState(0);

    const getAllOfferDetails = async params => {

        try {
            setLoading(true);
            setErrorMsg('');

            const res = await OfferDetailsService.getAllOfferDetails(params);

            setOfferDetailsList(res.data.content);
            setTotalElements(res.data.totalElements);
            setLoading(false);

            return res.data.content;
        } catch (error) {
            setLoading(false);
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }

    }


    const addOfferDetails = async data => {

        try {
            setOfferDetailsAddLoading(true);
            const res = await OfferDetailsService.addOfferDetails(data)
            setOfferDetails(res.data);
            setOfferDetailsAddLoading(false);
            Notification("success", "Created", "Offer Details Created Successfully");

            return res.data;
        } catch (error) {
            setOfferDetailsAddLoading(false);
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }

    }

    const updateOfferDetails = async (data, id) => {

        try {
            setOfferDetailsEditLoading(true);
            const res = await OfferDetailsService.updateOfferDetails(data, id);
            setOfferDetails(res.data);
            setOfferDetailsEditLoading(false);

            Notification("success", "Updated", "Offer Details Updated Successfully");

            return res.data;
        } catch (error) {
            setOfferDetailsEditLoading(false);
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }

    }

    const deleteOfferDetails = async id => {

        try {
            const res = await OfferDetailsService.deleteOfferDetails(id);

            setOfferDetailsList(offerDetailsList.filter(e => e.id !== id));

            Notification("success", "Deleted", `Offer Details ${id} Deleted Successfully`);

            return res.data;
        } catch (error) {
            setLoading(false);
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }

    }

    const getOfferDetailsById = async id => {

        try {
            setLoading(true);
            setErrorMsg('');

            const res = await OfferDetailsService.getOfferDetailsById(id);

            setOfferDetails(res.data);
            setLoading(false);

            return res.data;
        } catch (error) {
            setLoading(false);
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }

    }


    return (
        <OfferDetailsContext.Provider
            value={{
                loading,
                offerDetailsAddLoading,
                offerDetailsEditLoading,
                errorMsg,
                totalElements,
                offerDetailsList,
                offerDetails,
                getAllOfferDetails,
                getOfferDetailsById,
                addOfferDetails,
                updateOfferDetails,
                deleteOfferDetails
            }}
        >
            {children}
        </OfferDetailsContext.Provider>
    );
}

export default OfferDetailsContextProvider;
