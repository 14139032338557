import React, {createContext, useState} from 'react';
import PharmacyService from "../services/PharmacyService";
import confirmationModal from "../components/common/ConfirmationModal";

export const PharmacyContext = createContext("PharmacyContext");

const PharmacyContextProvider = ({children}) => {

    const [pharmacies, setPharmacies] = useState([]);
    const [pharmacy, setPharmacy] = useState(null);
    const [pharmacySubscriptionPlan, setPharmacySubscriptionPlan] = useState(null);
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [totalElements, setTotalElements] = useState(0);
    const [pharmacyRoles, setPharmacyRoles] = useState(null);
    const [initProductLoading, setInitProductLoading] = useState(false);
    const [initPharmacyFileLoading, setInitPharmacyFileLoading] = useState(false);

    const [statisticsLoading, setStatisticsLoading] = useState(false);
    const [statistics, setStatistics] = useState(null);

    async function getAllPharmacies({page, size, id, name, pharmacyOwnerName, pharmacyOwnerPhone}) {

        try {
            setLoading(true);
            const res = await PharmacyService.getAllPharmacies(page, size, id, name, pharmacyOwnerName, pharmacyOwnerPhone);
            setPharmacies(res.data.content);
            setTotalElements(res.data.totalElements);
            setLoading(false);
            return res.data.content;
        } catch (error) {
            console.log("error", console.error())
            return false;
        }

    }

    async function getPharmacyById(id) {

        try {
            setLoading(true);
            setErrorMsg('');
            const res = await PharmacyService.getPharmacyById(id);
            setPharmacy(res.data);
            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);
            setErrorMsg("Can not load Pharmacys. Please check the console.");
            console.log("error", error)
            return false;
        }

    }

    async function getSubscriptionPlanForPharmacy() {

        try {
            setLoading(true);

            setErrorMsg('');
            const res = await PharmacyService.getSubscriptionPlanForPharmacy();
            setPharmacySubscriptionPlan(res.data);
            setLoading(false);
            return res.data;
        } catch (error) {
            setLoading(false);
            setErrorMsg("Can not load Pharmacys. Please check the console.");
            console.log("error", error)
            return false;
        }

    }

    async function addPharmacy(data, pharmacyOwnerId) {

        try {
            setLoading(true);
            setErrorMsg("")
            const res = await PharmacyService.addPharmacy(data, pharmacyOwnerId)
            setPharmacy(res.data);
            confirmationModal(`Pharmacy Id - ${res.data.id} create successfully!`, "success");
            setLoading(false)
            return true;
        } catch (err) {
            setLoading(false);
            const message = err.response ? err.response.data.message : err.message;
            console.log("post pharmacy error", message);
            confirmationModal("Can not save pharmacy. " + message, "error");
            return false;
        }
    }

    async function initPharmacyDefaultProduct(pharmacyId) {
        try {
            setInitProductLoading(true);
            setErrorMsg("");
            const res = await PharmacyService.initPharmacyDefaultProduct(pharmacyId);
            confirmationModal(res.data, "success");
            setInitProductLoading(false)
            return true;
        } catch (err) {
            setInitProductLoading(false);
            const message = err.response ? err.response.data.message : err.message;
            confirmationModal("Can not save pharmacy initial product. " + message, "error");
            return false;
        }
    }

    async function initPharmacyInventoryFile(pharmacyId, data) {
        try {
            setInitPharmacyFileLoading(true);
            setErrorMsg("");
            const res = await PharmacyService.initPharmacyInventoryFile(pharmacyId, data);
            confirmationModal(`Successfully request sent. Total File: ${res.data.Files}, Success: ${res.data.Created},  Failed: ${res.data.Failed}`, "success");
            setInitPharmacyFileLoading(false)
            return true;
        } catch (err) {
            setInitPharmacyFileLoading(false);
            const message = err.response ? err.response.data.message : err.message;
            confirmationModal("Can not upload pharmacy inventory file. " + message, "error");
            return false;
        }
    }

    async function updatePharmacy(data, id) {
        try {
            setLoading(true);
            await PharmacyService.updatePharmacy(data, id)
            setLoading(false);
            confirmationModal("pharmacy updated properly. ", "success");
            return true;
        } catch (error) {
            setLoading(false);
            const message = error.response ? error.response.data.message : error.message
            confirmationModal("Can not update pharmacy." + message, "error");
            return false;
        }

    }

    async function deletePharmacy(id) {

        try {
            setLoading(true);
            await PharmacyService.deletePharmacy(id);
            let updatedPharmacies = pharmacies;
            updatedPharmacies = pharmacies.filter(e => e.id !== id)
            setPharmacies(updatedPharmacies);
            setLoading(false);
            return true;
        } catch {
            setLoading(false);
            return false;
        }

    }

    const changePharmacyStatus = async (email, status) => {


        try {
            await PharmacyService.changePharmacyStatus(email, status);
            let updatedPharmacies = pharmacies;
            updatedPharmacies = pharmacies.map(e => {
                if (e.email === email) e.status = status;
                return e;
            })
            setPharmacies(updatedPharmacies);
            return true;
        } catch {
            return false;
        }

    }

    const getPharmacyRolesById = async (id) => {
        try {
            setLoading(true);
            const res = await PharmacyService.getPharmacyRolesById(id);
            setPharmacyRoles(res.data);
            setLoading(false);
            return res.data
        } catch (e) {
            setLoading(false);
            setErrorMsg("Can't load data, Please Check the console.");
            return null;
        }
    }

    const checkPharmacyAsOnline = async (id, status) => {
        try {
            await PharmacyService.checkPharmacyAsOnline(id, status);
            return true;
        } catch (e) {
            console.log(e);
            return false;
        }
    }

    const getPharmacyStatistics = async (id) => {
        try {
            setStatisticsLoading(true);
            const res = await PharmacyService.getPharmacyStatistics(id);
            setStatistics(res.data);
            setStatisticsLoading(false);
            return res.data
        } catch (e) {
            setStatisticsLoading(false);
            setErrorMsg("Can't load data, Please Check the console.");
            return null;
        }
    }

    return (
        <PharmacyContext.Provider
            value={{
                pharmacies,
                pharmacy,
                totalElements,
                getSubscriptionPlanForPharmacy,
                pharmacySubscriptionPlan,
                loading,
                initProductLoading,
                initPharmacyFileLoading,
                getPharmacyById,
                errorMsg,
                getAllPharmacies,
                updatePharmacy,
                deletePharmacy,
                addPharmacy,
                changePharmacyStatus,
                pharmacyRoles,
                getPharmacyRolesById,
                checkPharmacyAsOnline,
                initPharmacyDefaultProduct,
                initPharmacyInventoryFile,
                statisticsLoading,
                statistics,
                getPharmacyStatistics,
            }}
        >
            {children}
        </PharmacyContext.Provider>
    );
}

export default PharmacyContextProvider;
