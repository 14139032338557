import React, {createContext, useState} from "react";
import PMSCashReceivedServices from "../../services/pms_services/PMSCashReceiveServices";
import Notification from "../../components/common/Notification";

export const PMSCashReceivedContext = createContext("PMSCashReceivedContext");

const PMSCashReceivedContextProvider = ({ children }) => {
    const [loading, setLoading] = useState(false);
    const [loadingCreateCashReceived, setLoadingCreateCashReceived] = useState(false);
    const [refreshLoading, setRefreshLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    const [cashNotReceivedSale, setCashNotReceivedSale] = useState(null);
    const [totalElements, setTotalElements] = useState(0);
    const [salesThatCashNotReceived, setSalesThatCashNotReceived] = useState([]);

    const getSalesWhichCashNotReceived = async (params) => {
        try {
            setLoading(true);
            setErrorMsg("");
            const res = await PMSCashReceivedServices.getSalesWhichCashNotReceived(params);
            setSalesThatCashNotReceived(res.data.content);
            if (res.data.content.length > 0) {
                setCashNotReceivedSale({...res.data.content[0], rowIndex: 0});
            }
            setTotalElements(res.data.totalElements);
            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    };


    const postSaleWhichCashHasReceived = async (id, amount, isCashierChecked) => {
        try {
            setLoadingCreateCashReceived(true);
            setErrorMsg("");
            await PMSCashReceivedServices.postSaleWhichCashHasReceived(id, amount, isCashierChecked);
            Notification("success", "Received", "Cash Received successfully!");
            setLoadingCreateCashReceived(false);
            return true;
        } catch (error) {
            setLoadingCreateCashReceived(false);
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    };

    const pmsCashReceivedRefresh = async (pharmacyId) => {
        try {
            setRefreshLoading(true);
            setErrorMsg("");

            await PMSCashReceivedServices.pmsCashReceivedRefresh(pharmacyId);

            Notification("success", "Refreshed", "Cash Received Refreshed successfully!");

            setRefreshLoading(false);
            return true;
        } catch (error) {
            setRefreshLoading(false);
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    };


    return (
        <PMSCashReceivedContext.Provider
            value={{
                loading,
                refreshLoading,
                errorMsg,
                totalElements,
                loadingCreateCashReceived,
                salesThatCashNotReceived,
                cashNotReceivedSale,
                setCashNotReceivedSale,
                getSalesWhichCashNotReceived,
                postSaleWhichCashHasReceived,
                pmsCashReceivedRefresh
            }}
        >
            {children}
        </PMSCashReceivedContext.Provider>
    );
};

export default PMSCashReceivedContextProvider;
