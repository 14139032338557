import React, {createContext, useState} from "react";
import PMSCustomerServices from "../../services/pms_services/PMSCustomerServices";
import Notification from "../../components/common/Notification";

export const PMSCustomerContext = createContext("PMSCustomerContext");

const PMSCustomerContextProvider = ({children}) => {

    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    const [totalElements, setTotalElements] = useState(0);
    const [customers, setCustomers] = useState(null);
    const [customer, setCustomer] = useState(null);
    const [customerDueReport, setCustomerDueReport] = useState({
        totalDueCustomer: 0,
        totalDue: 0
    });

    const getAllCustomersByPharmacyId = async (id, params) => {

        try {
            setLoading(true);
            setErrorMsg("");

            const res = await PMSCustomerServices.getAllCustomersByPharmacyId(id, params);

            setCustomerDueReport({
                totalDueCustomer: res.headers.count,
                totalDue: res.headers.due
            });

            setCustomers(res.data.content);
            setTotalElements(res.data.totalElements);
            setLoading(false);

            return true;
        } catch (error) {
            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }

            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }

    const getAllLiteCustomersByPharmacyId = async ({page, size, id}) => {

        try {
            setLoading(true);
            setErrorMsg("");

            const res = await PMSCustomerServices.getAllLiteCustomersByPharmacyId(page, size, id);

            setCustomers(res.data.content);
            setTotalElements(res.data.totalElements);
            setLoading(false);

            return true;
        } catch (error) {
            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }

            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }

    const getCustomerByPharmacyIdById = async (pharmacyId, id) => {
        try {
            setLoading(true);
            setErrorMsg("");

            const res = await PMSCustomerServices.getCustomerByPharmacyIdById(pharmacyId, id);

            setCustomer(res.data);
            setLoading(false);

            return true;
        } catch (error) {
            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }

            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }

    const getCustomerByPharmacyIdByLocalId = async (pharmacyId, id) => {
        try {
            setLoading(true);
            setErrorMsg("");

            const res = await PMSCustomerServices.getCustomerByPharmacyIdByLocalId(pharmacyId, id);

            setCustomer(res.data);
            setLoading(false);

            return true;
        } catch (error) {
            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }

            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }

    const clearCustomer = () => {
        setCustomer(null);
    }

    const createCustomerByPharmacyId = async (id, data) => {

        try {
            setLoading(true);
            setErrorMsg("");

            await PMSCustomerServices.createCustomerByPharmacyId(id, data);

            Notification("success", "Created", "Customer created successfully!");

            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }

    const updateCustomerByIdByPharmacyId = async (id, pharmacyId, data) => {

        try {
            setLoading(true);
            setErrorMsg("");

            await PMSCustomerServices.updateCustomerByIdByPharmacyId(id, pharmacyId, data);

            Notification("success", "Updated", "Customer updated successfully!");

            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }

    const deleteCustomerById = async (id) => {

        try {
            setLoading(true);
            setErrorMsg("");

            await PMSCustomerServices.deleteCustomerById(id);

            setCustomers(customers.filter(customer => customer.id !== id));

            Notification("success", "Deleted", "Customer has been deleted successfully!");

            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }

    return (
        <PMSCustomerContext.Provider
            value={{
                loading,
                errorMsg,
                totalElements,
                customers,
                customer,
                customerDueReport,
                getAllCustomersByPharmacyId,
                getAllLiteCustomersByPharmacyId,
                getCustomerByPharmacyIdById,
                createCustomerByPharmacyId,
                updateCustomerByIdByPharmacyId,
                deleteCustomerById,
                getCustomerByPharmacyIdByLocalId,
                clearCustomer
            }}
        >
            {children}
        </PMSCustomerContext.Provider>
    )
}

export default PMSCustomerContextProvider;