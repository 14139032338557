import * as URL from "../helpers/Constants";
import axios from "axios";

export default class PharmacyOrderAccountService {
    static getAllPharmacyOrderAccounts(page, size, id, pharmacyName, subscriptionPlan, orderId) {
        return axios.get(URL.GET_ALL_PHARMACY_ORDER_ACCOUNTS, {
            ...PharmacyOrderAccountService.getAuthHeader(),
            params: {page, size, id, pharmacyName, subscriptionPlan, orderId}
        });
    }

    static getPharmacyOrderAccountById(id) {
        return axios.get(`${URL.GET_PHARMACY_ORDER_ACCOUNT_BY_ID}/${id}`, PharmacyOrderAccountService.getAuthHeader());
    }

    static getPharmacyOrderAccountByPharmacyId(id) {
        return axios.get(`${URL.GET_ALL_PHARMACY_ORDER_ACCOUNTS_BY_PHARMACY_ID}/${id}`, PharmacyOrderAccountService.getAuthHeader());
    }


    static getAuthHeader() {
        const accessToken = localStorage.getItem("oushodsheba_access_token");
        const options = {headers: {"Authorization": `Bearer ${accessToken}`}}
        return options;
    }

    // static changePharmacyOrderAccountStatus(email, status) {
    //     return axios.post(`${URL.UPDATE_DELIVERY_CHARGE_STATUS}/${email}?status=${status}`)
    // }
}