import axios from "axios";
import * as URL from "../../helpers/Constants";

export default class PMSProductRequisitionService {

    static getAllRequisition(pharmacyId, params) {
        return axios.get(
            `${URL.PMS_GET_ALL_REQUISITION_BY_PHARMACY_ID}/${pharmacyId}`, {
                params, ...PMSProductRequisitionService.getAuthHeader()
            }
        );
    }

    static getRequisition(requisitionId, pharmacyId) {
        return axios.get(
            `${URL.PMS_GET_REQUISITION_BY_PHARMACY_ID_BY_ID}/${requisitionId}/${pharmacyId}`, PMSProductRequisitionService.getAuthHeader()
        );
    }

    static createRequisition(pharmacyId, data) {
        return axios.post(`${URL.PMS_CREATE_REQUISITION}/${pharmacyId}`, data,
            PMSProductRequisitionService.getAuthHeader());
    }

    static updateRequisition(requisitionId, pharmacyId, params, data) {
        return axios.put(`${URL.PMS_UPDATE_REQUISITION_BY_PHARMACY_ID_BY_ID}/${requisitionId}/${pharmacyId}`, data,
            {params, ...PMSProductRequisitionService.getAuthHeader()})
    }

    static cancelRequisition(requisitionId, pharmacyId) {
        return axios.put(`${URL.PMS_CANCEL_REQUISITION_BY_PHARMACY_ID_BY_ID}/${requisitionId}/${pharmacyId}`, null, PMSProductRequisitionService.getAuthHeader())
    }

    static removeFromLessStock(pharmacyId, requisitionId) {
        return axios.put(`${URL.PMS_REMOVE_FROM_LESS_STOCK}/${pharmacyId}/${requisitionId}`, null, PMSProductRequisitionService.getAuthHeader())
    }

    static generateRequisitionPdf(pharmacyId, requisitionId) {

    }

    static getAuthHeader() {
        const accessToken = localStorage.getItem("oushodsheba_access_token");
        return {headers: {"Authorization": `Bearer ${accessToken}`}};
    }

}