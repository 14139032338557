import * as URL from "../helpers/Constants";
import axios from "axios";

export default class PMSOfflineConfigService {
    static getAllPMSOfflineConfigs = params => {
        return axios.get(URL.GET_PMS_OFFLINE_CONFIG_LIST, {
            ...PMSOfflineConfigService.getAuthHeader(),
            params
        });
    }

    static getPMSOfflineConfigById = id => {
        return axios.get(`${URL.GET_PMS_OFFLINE_CONFIG_BY_ID}/${id}`, PMSOfflineConfigService.getAuthHeader());
    }

    static generatePMSOConfigToken = pharmacyId => {
        return axios.get(`${URL.GENERATE_PMS_OFFLINE_CONFIG_TOKEN_BY_PHARMACY_ID}/${pharmacyId}`, PMSOfflineConfigService.getAuthHeader());
    }

    static createPMSOfflineConfig = data => {
        return axios.post(URL.CREATE_PMS_OFFLINE_CONFIG, data, PMSOfflineConfigService.getAuthHeader());
    }

    static updatePMSOfflineConfig = (data, id) => {
        return axios.put(`${URL.UPDATE_PMS_OFFLINE_CONFIG}/${id}`, data, PMSOfflineConfigService.getAuthHeader());
    }

    static getAuthHeader = () => {
        const accessToken = localStorage.getItem("oushodsheba_access_token");
        const options = {headers: {"Authorization": `Bearer ${accessToken}`}}
        return options;
    }
}
