
import React, {createContext, useState} from "react";
import Notification from "../../components/common/Notification";
import ReconciliationService from "../../services/pms_services/PMSReconciliationService";
import {generateLocalId} from "../../helpers/Utils";

export const PMSReconciliationContext = createContext("PMSReconciliationContext");

const PMSReconciliationContextProvider = ({children}) => {
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    const [totalElements, setTotalElements] = useState(0);
    const [reconciliations, setReconciliations] = useState([]);

    //sync
    const[reconciliationSyncLoading, setReconciliationSyncLoading] = useState(false);
    const [createdSyncCountLoading, setCreatedSyncCountLoading] = useState(false);
    const [updatedSyncCountLoading, setUpdatedSyncCountLoading] = useState(false);

    const [createdSynCount, setCreatedSyncCount] = useState(0);
    const [updatedSynCount, setUpdatedSyncCount] = useState(0);

    const getAllReconciliations = async (pharmacyId, params) => {
        try {
            setLoading(true);
            setErrorMsg("");
            const res = await ReconciliationService.getAllReconciliationsByPharmacyId(pharmacyId, params);
            setReconciliations(res.data.content);
            setTotalElements(res.data.totalElements);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }

    const addReconciliation = async (pharmacyId, data, loggedInUserId) => {
        try {
            setLoading(true);
            data.localId = generateLocalId(pharmacyId, loggedInUserId);
            await ReconciliationService.createReconciliation(pharmacyId, data);
            Notification("success", "CREATED", "Successfully reconciliation created");
            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }

    }

    const cancelReconciliation = async (id, pharmacyId) => {
        try {

            setLoading(true);
            await ReconciliationService.cancelReconciliation(id, pharmacyId);
            Notification("success", "CANCELLED", "Successfully reconciliation cancelled");
            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }

    }

    //sync
    const getCreatedSyncCount = async (pharmacyId, params) => {
        try {
            setCreatedSyncCountLoading(true);
            const res = await ReconciliationService.getCreatedReconciliationSyncCount(pharmacyId, params);
            setCreatedSyncCount(res.data);
            setCreatedSyncCountLoading(false);
            return true;
        } catch (error) {
            setCreatedSyncCountLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }

    const getUpdatedSyncCount = async (pharmacyId, params) => {
        try {
            setUpdatedSyncCountLoading(true);
            const res = await ReconciliationService.getUpdatedReconciliationSyncCount(pharmacyId, params);
            setUpdatedSyncCount(res.data);
            setUpdatedSyncCountLoading(false);
            return true;
        } catch (error) {
            setUpdatedSyncCountLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }

    const createReconciliationSync = async (pharmacyId, params) => {
        try {
            setReconciliationSyncLoading(true);
            await ReconciliationService.createReconciliationSync(pharmacyId, params);
            // setReconciliationSyncLoading(false);
            return true;
        } catch (error) {
            setReconciliationSyncLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }

    const updateReconciliationSync = async (pharmacyId, params) => {
        try {
            setReconciliationSyncLoading(true);
            await ReconciliationService.updateReconciliationSync(pharmacyId, params);
            Notification("success", "SUCCESS", "Reconciliation Synced Successfully");
            setReconciliationSyncLoading(false);
            return true;
        } catch (error) {
            setReconciliationSyncLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }

    return (
        <PMSReconciliationContext.Provider
            value={{
                loading,
                errorMsg,
                totalElements,
                reconciliations,
                createdSyncCountLoading,
                updatedSyncCountLoading,
                reconciliationSyncLoading,
                createdSynCount,
                updatedSynCount,
                getAllReconciliations,
                cancelReconciliation,
                addReconciliation,
                getCreatedSyncCount,
                getUpdatedSyncCount,
                createReconciliationSync,
                updateReconciliationSync,
            }}
        >
            {children}
        </PMSReconciliationContext.Provider>
    );
};

export default PMSReconciliationContextProvider;
