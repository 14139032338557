export const ROOT_PATH = "/";
export const DASHBOARD_PATH = `${ROOT_PATH}dashboard`;

export const LOGIN_PATH = `${ROOT_PATH}login`;
export const LOGOUT_PATH = `${ROOT_PATH}logout`;

//user
export const USER_PATH = `${ROOT_PATH}user`;
export const USER_INFO_PATH = `${ROOT_PATH}user-info`;
export const ADD_USER_PATH = `${ROOT_PATH}add-user`;
export const USER_ROLE_UPDATE_PATH = `${ROOT_PATH}update-role`;
export const EDIT_USER_PATH = `${ROOT_PATH}edit-user`;

//user Profile
export const USER_PROFILE_PATH = `${ROOT_PATH}user/profile`;

//role
export const ROLE_EDIT_PATH = `${ROOT_PATH}edit-role`;

export const ROLE_PATH = `${ROOT_PATH}role`;
export const ADD_ROLE_PATH = `${ROOT_PATH}add-role`;
export const ADD_ROLE_FOR_PHARMACY_PATH = `${ROOT_PATH}add-role-for-pharmacy`;
export const EDIT_ROLE_FOR_PHARMACY_PATH = `${ROOT_PATH}edit-role-for-pharmacy`;

//Customer
export const CUSTOMER_VIEW_PATH = `${ROOT_PATH}customer`;
export const CUSTOMER_INFO_PATH = `${ROOT_PATH}customer-info`;
export const EDIT_CUSTOMER_PATH = `${ROOT_PATH}edit-customer`;
export const ADD_CUSTOMER_PATH = `${ROOT_PATH}add-customer`;

//Customer group
export const CUSTOMER_GROUP_PATH = `${ROOT_PATH}customer-group`;
export const CUSTOMER_GROUP_INFO_PATH = `${ROOT_PATH}customer-group-info`;
export const EDIT_CUSTOMER_GROUP_PATH = `${ROOT_PATH}edit-customer-group`;
export const ADD_CUSTOMER_GROUP_PATH = `${ROOT_PATH}add-customer-group`;

//pharmacy owner
export const PHARMACY_OWNER = `${ROOT_PATH}pharmacy-owner`;
export const ADD_PHARMACY_OWNER = `${ROOT_PATH}add-pharmacy-owner`;
export const EDIT_PHARMACY_OWNER = `${ROOT_PATH}edit-pharmacy-owner`;

//errors
export const PAGE_403_PATH = `${ROOT_PATH}403`;
export const PAGE_404_PATH = `${ROOT_PATH}404`;
export const PAGE_500_PATH = `${ROOT_PATH}500`;

//products
export const PRODUCT_VIEW_PATH = `${ROOT_PATH}product`;
export const PENDING_PRODUCT_PATH = `${ROOT_PATH}pending-product`;
export const ADD_PRODUCT_PATH = `${ROOT_PATH}add-product`;
export const PRODUCT_INFO_PATH = `${ROOT_PATH}product-info`;
export const EDIT_PRODUCT_PATH = `${ROOT_PATH}edit-product`;
export const FEATURE_PRODUCT_PATH = `${ROOT_PATH}feature-product`;
export const VERIFY_PRODUCT_PATH = `${ROOT_PATH}verify-product`;

//product category
export const PRODUCT_CATEGORY_VIEW_PATH = `${ROOT_PATH}product-category`;
export const PRODUCT_CATEGORY_INFO_PATH = `${ROOT_PATH}product-category-info`;
export const EDIT_PRODUCT_CATEGORY_PATH = `${ROOT_PATH}edit-product-category`;
export const ADD_PRODUCT_CATEGORY_PATH = `${ROOT_PATH}add-product-category`;

//country
export const COUNTRY_VIEW_PATH = `${ROOT_PATH}country`;
export const EDIT_COUNTRY_PATH = `${ROOT_PATH}edit-country`;
export const ADD_COUNTRY_PATH = `${ROOT_PATH}add-country`;

//thana
export const THANA_VIEW_PATH = `${ROOT_PATH}thana`;
export const EDIT_THANA_PATH = `${ROOT_PATH}edit-thana`;
export const ADD_THANA_PATH = `${ROOT_PATH}add-thana`;

//delivery charge
export const DELIVERY_CHARGE_VIEW_PATH = `${ROOT_PATH}delivery-charge`;
export const EDIT_DELIVERY_CHARGE_PATH = `${ROOT_PATH}edit-delivery-charge`;
export const ADD_DELIVERY_CHARGE_PATH = `${ROOT_PATH}add-delivery-charge`;
export const DELIVERY_CHARGE_INFO_PATH = `${ROOT_PATH}delivery-charge-info`;

//district
export const DISTRIC_VIEW_PATH = `${ROOT_PATH}district`;
export const EDIT_DISTRIC_PATH = `${ROOT_PATH}edit-district`;
export const ADD_DISTRIC_PATH = `${ROOT_PATH}add-district`;

//drug
export const DRUG_VIEW_PATH = `${ROOT_PATH}drug`;
export const EDIT_DRUG_PATH = `${ROOT_PATH}edit-drug`;
export const ADD_DRUG_PATH = `${ROOT_PATH}add-drug`;

//edit
export const EDIT_PATH = `${ROOT_PATH}edit`;

//orders
export const ORDER_VIEW_PATH = `${ROOT_PATH}orders`;
export const ORDER_INFO_PATH = `${ROOT_PATH}order-info`;
export const ADD_ORDER_PATH = `${ROOT_PATH}add-order`;
export const EDIT_ORDER_PATH = `${ROOT_PATH}edit-order`;
export const STACKED_ORDER_PATH = `${ROOT_PATH}stacked-orders`;
export const PROCESS_STACKED_ORDER_PATH = `${ROOT_PATH}process-stacked-order`;

//pharmacy
export const PHARMACY_VIEW_PATH = `${ROOT_PATH}pharmacy`;
export const ADD_PHARMACY_PATH = `${ROOT_PATH}add-pharmacy`;
export const EDIT_PHARMACY_PATH = `${ROOT_PATH}edit-pharmacy`;
export const PHARMACY_STATISTICS_PATH = `${ROOT_PATH}pharmacy-statistics`;
export const PHARMACY_INFO_PATH = `${ROOT_PATH}pharmacy-info`;

//pharmacy order accounts
export const PHARMACY_ORDER_ACCOUNTS_VIEW_PATH = `${ROOT_PATH}pharmacy-order-accounts`;
export const PHARMACY_ORDER_ACCOUNTS_INFO_PATH = `${ROOT_PATH}pharmacy-order-accounts-info`;

//profit sharing plans
export const PROFIT_SHARING_PLAN_VIEW_PATH = `${ROOT_PATH}profit-sharing-plan`;
export const ADD_PROFIT_SHARING_PLAN_PATH = `${ROOT_PATH}add-profit-sharing-plan`;
export const EDIT_PROFIT_SHARING_PLAN_PATH = `${ROOT_PATH}edit-profit-sharing-plan`;

//item
export const ITEM_VIEW_PATH = `${ROOT_PATH}items`;
export const ADD_ITEM_PATH = `${ROOT_PATH}add-item`;
export const EDIT_ITEM_PATH = `${ROOT_PATH}edit-item`;

//company
export const COMPANY_VIEW_PATH = `${ROOT_PATH}companies`;
export const ADD_COMPANY_PATH = `${ROOT_PATH}add-company`;
export const EDIT_COMPANY_PATH = `${ROOT_PATH}edit-company`;

//generic
export const EDIT_GENERIC_PATH = `${ROOT_PATH}edit-generic`;
export const GENERIC_VIEW_PATH = `${ROOT_PATH}generic`;
export const ADD_GENERIC_PATH = `${ROOT_PATH}add-generic`;
export const GENERIC_INFO_PATH = `${ROOT_PATH}generic-info`;

//Product Type
export const EDIT_PRODUCT_TYPE_PATH = `${ROOT_PATH}edit-product-type`;
export const PRODUCT_TYPE_VIEW_PATH = `${ROOT_PATH}product-type`;
export const ADD_PRODUCT_TYPE_PATH = `${ROOT_PATH}add-product-type`;
export const PRODUCT_TYPE_INFO_PATH = `${ROOT_PATH}product-type-info`;

// Area
export const EDIT_AREA_PATH = `${ROOT_PATH}edit-area`;
export const AREA_VIEW_PATH = `${ROOT_PATH}area`;
export const ADD_AREA_PATH = `${ROOT_PATH}add-area`;
export const AREA_INFO_PATH = `${ROOT_PATH}area-info`;

//prescription
export const PRESCRIPTION_VIEW_PATH = `${ROOT_PATH}prescription`;
export const PRESCRIPTION_IMAGE_VIEW_PATH = `${ROOT_PATH}prescription-image`;
export const ADD_PRESCRIPTION_PATH = `${ROOT_PATH}add-prescription`;
export const EDIT_PRESCRIPTION_PATH = `${ROOT_PATH}edit-prescription`;
export const PRESCRIPTION_INFO_PATH = `${ROOT_PATH}prescription-info`;

//pharmacy owner
export const PHARMACY_OWNER_VIEW_PATH = `${ROOT_PATH}prescription`;
export const PHARMACY_OWNER_IMAGE_VIEW_PATH = `${ROOT_PATH}prescription-image`;
export const PHARMACY_OWNER_PATH = `${ROOT_PATH}add-prescription`;
export const EDIT_PHARMACY_OWNER_PATH = `${ROOT_PATH}edit-prescription`;

//pharmacy agent
export const PHARMACIST_VIEW_PATH = `${ROOT_PATH}pharmacist`;
export const PHARMACIST_IMAGE_VIEW_PATH = `${ROOT_PATH}pharmacist-image`;
export const EDIT_PHARMACIST_PATH = `${ROOT_PATH}edit-pharmacist`;
export const ADD_PHARMACIST_PATH = `${ROOT_PATH}add-pharmacist`;
export const PHARMACIST_INFO_PATH = `${ROOT_PATH}pharmacist-info`;

//Delivery person
export const DELIVERY_PERSON_VIEW_PATH = `${ROOT_PATH}delivery-person`;
export const DELIVERY_PERSON_IMAGE_VIEW_PATH = `${ROOT_PATH}delivery-person-image`;
export const EDIT_DELIVERY_PERSON_PATH = `${ROOT_PATH}edit-delivery-person`;
export const ADD_DELIVERY_PERSON_PATH = `${ROOT_PATH}add-delivery-person`;
export const DELIVERY_PERSON_INFO_PATH = `${ROOT_PATH}delivery-person-info`;

//promo
export const PROMO_VIEW_PATH = `${ROOT_PATH}promo`;
export const EDIT_PROMO_PATH = `${ROOT_PATH}edit-promo`;
export const ADD_PROMO_PATH = `${ROOT_PATH}add-promo`;
export const PROMO_INFO_PATH = `${ROOT_PATH}promo-info`;

//subscription plan
export const SUBSCRIPTION_PLAN_VIEW_PATH = `${ROOT_PATH}subscription-plan`;
export const EDIT_SUBSCRIPTION_PLAN_PATH = `${ROOT_PATH}edit-subscription-plan`;
export const ADD_SUBSCRIPTION_PLAN_PATH = `${ROOT_PATH}add-subscription-plan`;
export const SUBSCRIPTION_PLAN_INFO_PATH = `${ROOT_PATH}subscription-plan-info`;

//notification
export const NOTIFICATION_VIEW_PATH = `${ROOT_PATH}notification`;
export const EDIT_NOTIFICATION_PATH = `${ROOT_PATH}edit-notification`;
export const ADD_NOTIFICATION_PATH = `${ROOT_PATH}add-notification`;
export const NOTIFICATION_INFO_PATH = `${ROOT_PATH}notification-info`;

//table config
export const TABLE_CONFIG_PATH = `${ROOT_PATH}table-config`;

//event
export const EVENT_VIEW_PATH = `${ROOT_PATH}event`;
export const EVENT_INFO_PATH = `${ROOT_PATH}event-info`;

//offer
export const OFFER_VIEW_PATH = `${ROOT_PATH}offer`;
export const ADD_OFFER_PATH = `${ROOT_PATH}add-offer`;
export const OFFER_INFO_PATH = `${ROOT_PATH}offer-info`;

// offer details
export const OFFER_DETAILS_PATH = `${ROOT_PATH}offer-details`;
export const OFFER_DETAILS_CREATE_PATH = `${ROOT_PATH}create-offer-details`;
export const OFFER_DETAILS_UPDATE_PATH = `${ROOT_PATH}update-offer-details`;

//referral history
export const REFERRAL_HISTORY_VIEW_PATH = `${ROOT_PATH}referral-history`;

//home page product
export const HOMEPAGE_PRODUCT_VIEW_PATH = `${ROOT_PATH}homepage-products`;
export const ADD_HOMEPAGE_PRODUCT_PATH = `${ROOT_PATH}add-homepage-product`;
export const EDIT_HOMEPAGE_PRODUCT_PATH = `${ROOT_PATH}edit-homepage-product`;

//banner
export const BANNER_LIST_VIEW_PATH = `${ROOT_PATH}banners`;
export const BANNER_ADD_VIEW_PATH = `${ROOT_PATH}add-banner`;
export const BANNER_EDIT_VIEW_PATH = `${ROOT_PATH}edit-banner`;

//invoice
export const INVOICE_VIEW_PATH = `${ROOT_PATH}invoice`;
export const INVOICE_INFO_PATH = `${ROOT_PATH}invoice-info`;

//bill
export const BILL_VIEW_PATH = `${ROOT_PATH}bill`;
export const BILL_INFO_PATH = `${ROOT_PATH}bill-info`;
export const MAKE_BILL = `${ROOT_PATH}make-bill`;

//review
export const REVIEW_VIEW_PATH = `${ROOT_PATH}review`;
export const REVIEW_INFO_PATH = `${ROOT_PATH}review-info`;

//assign delivery man
export const ASSIGN_DELIVERY_MAN_PATH = `${ROOT_PATH}assign-delivery-man`;
export const DELIVERY_PERSON_ORDER_STACK_INFO_PATH = `${ROOT_PATH}delivery-person-order-stack-info`;
export const PHARMACY_ORDER_STACK_INFO_PATH = `${ROOT_PATH}pharmacy-order-stack-info`;

//global config
export const GLOBAL_CONFIG_VIEW_PATH = `${ROOT_PATH}global-config`;
export const GLOBAL_CONFIG_INFO_PATH = `${ROOT_PATH}global-config-info`;
export const ADD_GLOBAL_CONFIG_PATH = `${ROOT_PATH}add-global-config`;
export const EDIT_GLOBAL_CONFIG_PATH = `${ROOT_PATH}edit-global-config`;

//TEMPORARY ORDER
export const TEMPORARY_ORDER_VIEW_PATH = `${ROOT_PATH}temporary-order`;
export const TEMPORARY_ORDER_INFO_PATH = `${ROOT_PATH}temporary-order-info`;
export const ADD_TEMPORARY_ORDER_PATH = `${ROOT_PATH}add-temporary-order`;
export const EDIT_TEMPORARY_ORDER_PATH = `${ROOT_PATH}edit-temporary-order`;

// Order Routing Schedule
export const ORDER_ROUTING_SCHEDULE_VIEW_PATH = `${ROOT_PATH}order-routing-schedule`;

//Deliver Plan
export const DELIVERY_PLAN_VIEW_PATH = `${ROOT_PATH}delivery-plan`;
export const DELIVERY_PLAN_INFO_PATH = `${ROOT_PATH}delivery-plan-info`;
export const ADD_DELIVERY_PLAN_PATH = `${ROOT_PATH}add-delivery-plan`;
export const EDIT_DELIVERY_PLAN_PATH = `${ROOT_PATH}edit-delivery-plan`;

//-------------------Lab Test------------
export const LAB_TEST_VIEW_PATH = `${ROOT_PATH}lab-test`;
export const CUSTOMER_LAB_TEST_VIEW_PATH = `${ROOT_PATH}customer-lab-test`;
export const ADD_CUSTOMER_LAB_TEST_VIEW_PATH = `${ROOT_PATH}add-customer-lab-test`;

//-------------------Upload Product Files------------
export const UPLOAD_PRODUCT_FILE_VIEW_PATH = `${ROOT_PATH}upload-product`;

//-------------------pms report------------
export const PHARMACY_REPORT_PATH = `${ROOT_PATH}pharmacy-report`;

// ---------------------- pms ----------------------------------------

export const PMS_LOGIN_PATH = `${ROOT_PATH}pms-login`;
export const PMS_LOGOUT_PATH = `${ROOT_PATH}pms-logout`;
export const PMS_PHARMACY_VIEW_PATH = `${ROOT_PATH}pms-pharmacy`;

//----------------------- pms CUSTOMER -------------------------------
export const PMS_CUSTOMER_VIEW_PATH = `${ROOT_PATH}pms-customers`;
export const PMS_CUSTOMER_CREATE_PATH = `${ROOT_PATH}pms-customer-create`;
export const PMS_CUSTOMER_EDIT_PATH = `${ROOT_PATH}pms-customer-update`;
export const PMS_CUSTOMER_INFO_PATH = `${ROOT_PATH}pms-customer-info`;

//----------------------- pms EXPENSE -------------------------------
export const PMS_EXPENSE_VIEW_PATH = `${ROOT_PATH}pms-expenses`;
export const PMS_EXPENSE_CREATE_PATH = `${ROOT_PATH}pms-expense-create`;
export const PMS_EXPENSE_EDIT_PATH = `${ROOT_PATH}pms-expense-update`;
export const PMS_EXPENSE_INFO_PATH = `${ROOT_PATH}pms-expense-info`;

//----------------------- pms INVENTORY -------------------------------
export const PMS_INVENTORY_VIEW_PATH = `${ROOT_PATH}pms-inventories`;
export const PMS_INVENTORY_DETAILS_VIEW_PATH = `${ROOT_PATH}pms-inventory-details`;
export const PMS_INVENTORY_EDIT_PATH = `${ROOT_PATH}pms-inventory-update`;
export const PMS_PURCHASE_EDIT_PATH = `${ROOT_PATH}pms-purchase-update`;

//----------------------- pms PAYMENT -------------------------------
//----------------------- pms PRODUCT -------------------------------
export const PMS_PRODUCT_INFO_PATH = `${ROOT_PATH}pms-product-info`;
export const PMS_PURCHASE_PRODUCT_CREATE_PATH = `${ROOT_PATH}pms-purchase-product-create`;
export const PMS_PRODUCT_ADD_PATH = `${ROOT_PATH}pms-product-add`;
export const PMS_PRODUCT_EDIT_PATH = `${ROOT_PATH}pms-product-edit`;
export const PMS_PRODUCT_LIST_PATH = `${ROOT_PATH}pms-product`;

//----------------------- pms purchase-old -------------------------------
export const PMS_PURCHASE_PATH_OLD = `${ROOT_PATH}pms-purchase-old`;
export const PMS_PURCHASE_PATH = `${ROOT_PATH}pms-purchase`;
export const PMS_PURCHASE2_PATH = `${ROOT_PATH}pms-purchase-2`;
export const PMS_PURCHASE_PRODUCT2_CREATE_PATH = `${ROOT_PATH}pms-purchase-product-create-2`;
export const PMS_PURCHASE3_PATH = `${ROOT_PATH}pms-purchase-3`;
export const PMS_PURCHASE4_PATH = `${ROOT_PATH}pms-purchase-4`;
export const PMS_PURCHASE_PRODUCT3_CREATE_PATH = `${ROOT_PATH}pms-purchase-product-create-3`;
export const PMS_PURCHASE_PRODUCT4_CREATE_PATH = `${ROOT_PATH}pms-purchase-product-create-4`;
export const PMS_CUSTOMER_RETURN_PATH = `${ROOT_PATH}customer-return`;

//----------------------- pms requisition-new -------------------------------
export const PMS_REQUISITION_PATH = `${ROOT_PATH}pms-requisition`;
export const PMS_REQUISITION_RECORD_PATH = `${ROOT_PATH}pms-requisition-record`;
export const PMS_REQUISITION_RECORD_EDIT_PATH = `${ROOT_PATH}pms-requisition-record-edit`;
export const PMS_REQUISITION_RECORD_VIEW = `${ROOT_PATH}pms-requisition-record-view`;

//----------------------- pms REPORT -------------------------------
//----------------------- pms SALE -------------------------------
export const PMS_SALE_HISTORY_VIEW_PATH = `${ROOT_PATH}pms-sales`;
export const PMS_SALE_HISTORY_DETAILS_VIEW_PATH = `${ROOT_PATH}pms-sale-details`;
export const PMS_SALE_HISTORY_EDIT_PATH = `${ROOT_PATH}pms-sale-update-old`;
export const PMS_SALE_RECORD_EDIT_PATH = `${ROOT_PATH}pms-sale-update`;

export const PMS_SALE_OLD_PATH = `${ROOT_PATH}pms-sale-old`;
export const PMS_SALE_PATH = `${ROOT_PATH}pms-sale`;
//----------------------- pms SUPPLIER -------------------------------
export const PMS_VENDOR_VIEW_PATH = `${ROOT_PATH}pms-vendors`;
export const PMS_VENDOR_CREATE_PATH = `${ROOT_PATH}pms-vendor-create`;
export const PMS_VENDOR_EDIT_PATH = `${ROOT_PATH}pms-vendor-update`;
export const PMS_VENDOR_INFO_PATH = `${ROOT_PATH}pms-vendor-info`;

//----------------------- pms CASH RECEIVED -------------------------------
export const PMS_CASH_RECEIVED_VIEW_PATH = `${ROOT_PATH}pms-cash-received`;

//----------------------- pms CUSTOMER -------------------------------
export const PMS_PHARMACIST_VIEW_PATH = `${ROOT_PATH}pms-pharmacists`;
export const PMS_PHARMACIST_CREATE_PATH = `${ROOT_PATH}pms-pharmacist-create`;
export const PMS_PHARMACIST_EDIT_PATH = `${ROOT_PATH}pms-pharmacist-update`;
export const PMS_PHARMACIST_INFO_PATH = `${ROOT_PATH}pms-pharmacist-info`;

// --------------------- pms Inventory Expired ------------------------
export const PMS_EXPIRED_ITEMS_VIEW_PATH = `${ROOT_PATH}pms-expired-items`;

// ---------------------- pms Transaction Account ---------------------
export const PMS_TRANSACTION_ACCOUNTS_VIEW_PATH = `${ROOT_PATH}pms-transaction-accounts`;
export const PMS_TRANSACTION_ACCOUNT_CREATE_PATH = `${ROOT_PATH}pms-transaction-account-create`;
export const PMS_TRANSACTION_ACCOUNT_EDIT_PATH = `${ROOT_PATH}pms-transaction-account-update`;

// ---------------------- pms Transactions ---------------------
export const PMS_TRANSACTIONS_VIEW_PATH = `${ROOT_PATH}pms-transactions`;
export const PMS_TRANSACTION_CREATE_PATH = `${ROOT_PATH}pms-transaction-create`;
export const PMS_TRANSACTION_INFO_PATH = `${ROOT_PATH}pms-transaction-info`;

// =================== pms Customer Payments==================
export const PMS_CUSTOMER_PAYMENTS_VIEW_PATH = `${PMS_CUSTOMER_VIEW_PATH}/payments`;
export const PMS_CUSTOMER_PAYMENTS_CREATE_PATH = `${PMS_CUSTOMER_VIEW_PATH}/payments/create`;

// =================== pms Supplier Payments==================
export const PMS_VENDOR_PAYMENTS_VIEW_PATH = `${PMS_VENDOR_VIEW_PATH}/payments`;
export const PMS_VENDOR_PAYMENTS_CREATE_PATH = `${PMS_VENDOR_VIEW_PATH}/payments/create`;

// =================== reconciliation ==================
export const RECONCILIATION_LIST_VIEW_PATH = `${ROOT_PATH}reconciliation-list`;
export const ADD_RECONCILIATION_PATH = `${ROOT_PATH}reconciliation-add`;

// =================== pms offline configuration ==================
export const PMS_OFFLINE_CONFIG_LIST_VIEW_PATH = `${ROOT_PATH}pms-offline-config-list`;
export const PMS_OFFLINE_CONFIG_CREATE_PATH = `${ROOT_PATH}pms-offline-config-create`;
export const PMS_OFFLINE_CONFIG_UPDATE_PATH = `${ROOT_PATH}pms-offline-config-update`;
export const PMS_OFFLINE_CONFIG_VIEW_PATH = `${ROOT_PATH}pms-offline-config`;

// pms configure
export const PMS_RETAIL_CONFIG = `${ROOT_PATH}pms-retail-invoice-config`;
export const PMS_SALE_CONFIG = `${ROOT_PATH}pms-retail-sale-config`;

// =================== pms terms and condition ==================
export const PMS_TERMS_CONDITION_PATH = `${ROOT_PATH}pms-terms-conditions`;
export const PMS_TERMS_CONDITION_APP_PATH = `${ROOT_PATH}terms-conditions`;

export const ORDER_INVOICE_VIEW_PATH = `${ROOT_PATH}order-invoice`;

// =================== pms help ==================
export const PMS_HELP_PATH = `${ROOT_PATH}help`;

export const PMS_PRODUCT_BARCODE_GENERATE = `${ROOT_PATH}generate-barcode`;

//----------------------- ACCOUNTING LEDGER -------------------------------
export const POS_ACCOUNTING_LEDGER_PATH = `${ROOT_PATH}pos-accounting-ledger`;
export const POS_ACCOUNTING_LEDGER_PRINT_PATH = `${ROOT_PATH}pos-accounting-ledger-print`;
