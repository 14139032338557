import React, {createContext, useState} from "react";
import PMSExpenseServices from "../../services/pms_services/PMSExpenseServices";
import Notification from "../../components/common/Notification";

export const PMSExpenseContext = createContext("PMSExpenseContext");

const PMSExpenseContextProvider = ({children}) => {

    const [loading, setLoading] = useState(false);
    const [leadingCreateExpense, setLoadingCreateExpense] = useState(false)
    const [errorMsg, setErrorMsg] = useState("");

    const [totalElements, setTotalElements] = useState(0);
    const [expenses, setExpenses] = useState(null);
    const [expense, setExpense] = useState(null);
    const [expenseReport, setExpenseReport] = useState({
        totalCount: 0,
        totalExpense: 0
    });


    const getAllExpensesByPharmacyId = async (params) => {

        try {
            setLoading(true);
            setErrorMsg("");

            const res = await PMSExpenseServices.getAllExpensesByPharmacyId(params);

            setExpenseReport({
                totalCount: res.headers.count,
                totalExpense: res.headers.total
            });

            setExpenses(res.data.content);
            setTotalElements(res.data.totalElements);
            setLoading(false);

            return true;
        } catch (error) {
            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }

            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }

    const getExpenseByPharmacyIdById = async (pharmacyId, id) => {
        try {
            setLoading(true);
            setErrorMsg("");

            const res = await PMSExpenseServices.getExpenseByPharmacyIdById(pharmacyId, id);
            setExpense(res.data);
            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }

    const createExpenseByPharmacyId = async (id, data) => {

        try {
            setLoading(true);
            setLoadingCreateExpense(true);
            setErrorMsg("");

            await PMSExpenseServices.createExpenseByPharmacyId(id, data);

            Notification("success", "Added", "Expense has been added successfully!");

            setLoading(false);
            setLoadingCreateExpense(false)
            return true;
        } catch (error) {
            setLoading(false);
            setLoadingCreateExpense(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }

            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }

    const updateExpenseByIdByPharmacyId = async (id, pharmacyId, data) => {

        try {
            setLoading(true);
            setErrorMsg("");

            await PMSExpenseServices.updateExpenseByIdByPharmacyId(id, pharmacyId, data);
            Notification("success", "Updated", "Expense updated successfully!");

            setLoading(false);
            return true;
        } catch (error) {

            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }

            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }


    return (
        <PMSExpenseContext.Provider
            value={{
                loading,
                leadingCreateExpense,
                errorMsg,
                totalElements,
                expenses,
                expense,
                expenseReport,
                getAllExpensesByPharmacyId,
                getExpenseByPharmacyIdById,
                createExpenseByPharmacyId,
                updateExpenseByIdByPharmacyId
            }}
        >
            {children}
        </PMSExpenseContext.Provider>
    )
}

export default PMSExpenseContextProvider;