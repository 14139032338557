import React, {createContext, useState} from 'react';
import PharmacyOwnerService from "../services/PharmacyOwnerService";
import confirmationModal from "../components/common/ConfirmationModal";

export const PharmacyOwnerContext = createContext("PharmacyOwnerContext");

const PharmacyOwnerContextProvider = ({children}) => {

    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [pharmacyOwners, setPharmacyOwners] = useState(null);
    const [pharmacyOwner, setPharmacyOwner] = useState(null);
    const [profileLoading, setProfileLoading] = useState(false);
    const [products, setProducts] = useState([]);
    const [totalElements, setTotalElements] = useState(0);
    const [pharmacies, setPharmacies] = useState(null);

    async function getAllPharmacyOwners({page, size, name}) {

        try {
            const res = await PharmacyOwnerService.getAllPharmacyOwners(page, size, name);
            setPharmacyOwners(res.data.content)
            return res.data.content;
        } catch (error) {
            console.log("error", console.error())
            return false;
        }

    }

    async function getProductsForPharmacyOwner(page, size) {

        try {
            setLoading(true);
            const res = await PharmacyOwnerService.getProductsForPharmacyOwner(page, size);
            setProducts(res.data.content);
            console.log("=======> ", res.data.content);
            setTotalElements(res.data.totalElements);
            setLoading(false);
            return res.data.content;
        } catch (error) {
            setLoading(false);
            setErrorMsg("Can not load Featured products, please check the console");
            console.log('Can not load pharmacy products', error);
            return error.status;
        }

    }


    async function addPharmacyOwner(data) {

        try {
            const res = await PharmacyOwnerService.addPharmacyOwner(data)
            setPharmacyOwners(pharmacyOwners ? [...pharmacyOwners, res.data] : [res.data]);
            return true;
        } catch (err) {
            const message = err.response ? err.response.data.message : err.message;
            console.log("post pharmacyOwner error", message);
            confirmationModal("Can not save PharmacyOwner. " + message, "error");
            return false;
        }

    }

    async function getPharmacyOwnerProfile() {
        try {
            setProfileLoading(true);
            const res = await PharmacyOwnerService.getProfile();
            setPharmacyOwner(res.data);
            setProfileLoading(false);
            return true;
        } catch (error) {
            setProfileLoading(false);
            const message = error.response ? error.response.data.message : error.message;
            console.log("Get profile error", message);
            return false;
        }
    }

    async function updatePharmacyOwner(data, id) {
        try {
            const res = await PharmacyOwnerService.updatePharmacyOwner(data, id)
            let updatedPharmacyOwners = pharmacyOwners;
            updatedPharmacyOwners = pharmacyOwners.map(e => {
                if (e.id === id) e = res.data;
                return e;
            })

            setPharmacyOwners(updatedPharmacyOwners);
            confirmationModal("PharmacyOwner updated successfully!", "success");
            return true;
        } catch (error) {
            const message = error.response ? error.response.data.message : error.message;
            console.log("Update pharmacyOwner error", message);
            confirmationModal("Can not update PharmacyOwner. " + message, "error");
            return false;
        }

    }

    async function deletePharmacyOwner(id) {

        if (!window.confirm("Are you sure you want to delete this PharmacyOwner ?")) return;

        try {
            await PharmacyOwnerService.deletePharmacyOwner(id);
            let updatedPharmacyOwners = pharmacyOwners;
            updatedPharmacyOwners = pharmacyOwners.filter(e => e.id !== id)
            setPharmacyOwners(updatedPharmacyOwners);
            return true;
        } catch {
            return false;
        }

    }

    const changePharmacyOwnerStatus = async (email, status) => {

        if (!window.confirm(`Are you sure you want to change this PharmacyOwner status to ${status} ?`)) return;

        try {
            await PharmacyOwnerService.changePharmacyOwnerStatus(email, status);
            let updatedPharmacyOwners = pharmacyOwners;
            updatedPharmacyOwners = pharmacyOwners.map(e => {
                if (e.email === email) e.status = status;
                return e;
            })
            setPharmacyOwners(updatedPharmacyOwners);
            return true;
        } catch {
            return false;
        }

    }


    const getAllPharmaciesForPharmacyOwner = async ({page, size}) => {

        try {
            setLoading(true);
            setErrorMsg("");
            const res = await PharmacyOwnerService.getAllPharmaciesForPharmacyOwner(page, size);
            setPharmacies(res.data.content);
            setTotalElements(res.data.totalElements);
            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);
            setErrorMsg("Can't load data, please check the console.");
            return false;
        }
    }

    const pharmacyClear = () => {
        setPharmacies(null);
    }


    return (
        <PharmacyOwnerContext.Provider
            value={{
                loading,
                errorMsg,
                pharmacyOwners,
                pharmacyOwner,
                getAllPharmacyOwners,
                getProductsForPharmacyOwner,
                products,
                pharmacies,
                totalElements,
                profileLoading,
                updatePharmacyOwner,
                deletePharmacyOwner,
                addPharmacyOwner,
                getPharmacyOwnerProfile,
                changePharmacyOwnerStatus,
                getAllPharmaciesForPharmacyOwner,
                pharmacyClear
            }}
        >
            {children}
        </PharmacyOwnerContext.Provider>
    );
}

export default PharmacyOwnerContextProvider;