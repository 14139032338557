import React, {createContext, useState} from "react";
import LabTestService from "../services/LabTestService";
import Notification from "../components/common/Notification";

export const LabTestContext = createContext("LabTestContext");

const LabTestContextProvider = ({children}) => {

    const [labTestList, setLabTestList] = useState([]);
    const [totalElements, setTotalElements] = useState(0);
    const [loading, setLoading] = useState(false);

    const [customerLabTestList, setCustomerLabTestList] = useState([]);
    const [customerLabTestLoading, setCustomerLabTestLoading] = useState(false);
    const [customerLabTestTotalElements, setCustomerLabTestTotalElements] = useState(0);

    const getAllLabTest = async (page, size, params) => {
        try {
            setLoading(true);

            const res = await LabTestService.getAllLabTest(page, size, params);

            setLabTestList(res.data.content);
            setTotalElements(res.data.totalElements);

            setLoading(false);
            return res.data.content;
        } catch (error) {

            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }

            Notification("error", "ERROR", `${_error}`)
            return false;
        }
    }

    const createLabTest = async (data) => {
        try {
            setLoading(true);

            await LabTestService.createLabTest(data);

            Notification("success", "Added", "Successfully lab test added");

            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }

    const updateLabTest = async (testId, data) => {
        try {
            setLoading(true);

            await LabTestService.updateLabTest(testId, data);

            Notification("success", "Updated", "Successfully lab test updated");

            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }

    const createLabTestParameter = async (data) => {
        try {
            setLoading(true);

            await LabTestService.createLabTestParameter(data);

            Notification("success", "Added", "Successfully lab test parameter added");

            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }

    const updateLabTestParameter = async (testParameterId, data) => {
        try {
            setLoading(true);

            await LabTestService.updateLabTestParameter(testParameterId, data);

            Notification("success", "Updated", "Successfully lab test parameter updated");

            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }


    const getAllCustomerLabTest = async (page, size, params) => {
        try {
            setCustomerLabTestLoading(true);

            const res = await LabTestService.getAllCustomerLabTest(page, size, params);

            setCustomerLabTestList(res.data.content);
            setCustomerLabTestTotalElements(res.data.totalElements);

            setCustomerLabTestLoading(false);
            return res.data.content;
        } catch (error) {

            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }

            Notification("error", "ERROR", `${_error}`)
            return false;
        }
    }

    const createCustomerLabTest = async (data, customerId) => {
        try {
            setLoading(true);

            await LabTestService.createCustomerLabTest(data, customerId);

            Notification("success", "Added", "Successfully lab test parameter added");

            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }


    const updateCustomerLabTest = async (data, customerLabTestId) => {
        try {
            setLoading(true);

            await LabTestService.updateCustomerLabTest(data, customerLabTestId);

            Notification("success", "Updated", "Successfully lab test updated");

            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }

    return (
        <LabTestContext.Provider
            value={{
                loading,
                totalElements,
                labTestList,
                customerLabTestList,
                customerLabTestLoading,
                customerLabTestTotalElements,
                getAllLabTest,
                createLabTest,
                updateLabTest,
                createLabTestParameter,
                updateLabTestParameter,
                getAllCustomerLabTest,
                createCustomerLabTest,
                updateCustomerLabTest
            }}
        >
            {children}
        </LabTestContext.Provider>
    )
}

export default LabTestContextProvider;
