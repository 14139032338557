import React, {createContext, useState} from 'react';
import DeliveryPlanServices from "../services/DeliveryPlanServices";

export const DeliveryPlanContext = createContext("DeliveryPlanContext");

const DeliveryPlanContextProvider = ({children}) => {

    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [deliveryPlans, setDeliveryPlans] = useState([]);

    const [deliveryPlan, setDeliveryPlan] = useState(null)
    const [pagination, setPagination] = useState({
        total: 0,
        current: 1,
        pageSize: 10,
        position: "top",
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ["10", "30", "50", "100"]
    });


    const getAllDeliveryPlans = async ({page, size, id, planName}) => {
        try {
            setLoading(true);
            const res = await DeliveryPlanServices.getAllDeliveryPlans(page - 1, size, id, planName);
            setDeliveryPlans(res.data.content);
            setPagination({...pagination, total: res.data.totalElements, current: page, pageSize: size})
            setLoading(false);
            return res.data.content;
        } catch (error) {
            setLoading(false);
            setErrorMsg("Can't load Data, Please check the console.")
            console.log(error);
            return false;
        }
    }

    const createDeliveryPlan = async (data, page, size) => {
        try {
            setLoading(true);
            const res = await DeliveryPlanServices.createDeliveryPlan(data, page, size);
            console.log(res.data);
            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);
            setErrorMsg("Can't load Data, Please check the console.")
            console.log(error);
            return false;
        }
    }

    const updateDeliveryPlanById = async (data, id) => {
        try {
            setLoading(true);
            const res = await DeliveryPlanServices.updateDeliveryPlanById(data, id);
            setDeliveryPlans(res.data)
            console.log(res.data);
            return true;
        } catch (error) {
            setLoading(false);
            setErrorMsg("Can't load Data, Please check the console.")
            console.log(error);
            return false;
        }
    }

    const deleteDeliveryPlanById = async (id) => {
        try {
            setLoading(true);
            await DeliveryPlanServices.deleteDeliveryPlanById(id);
            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);
            setErrorMsg("Can't load Data, Please check the console.")
            console.log(error);
            return false;
        }
    }

    const getDeliveryPlanById = async (id) => {
        try {
            setLoading(true);
            const res = await DeliveryPlanServices.getDeliveryPlanById(id);
            setDeliveryPlan(res.data);
            setLoading(false);
            return res.data;
        } catch (error) {
            setLoading(false);
            setErrorMsg("Can't load Data, Please check the console.")
            console.log(error);
            return null;
        }
    }


    return (
        <DeliveryPlanContext.Provider
            value={{
                loading,
                errorMsg,
                pagination,
                deliveryPlans,
                deliveryPlan,
                getAllDeliveryPlans,
                getDeliveryPlanById,
                createDeliveryPlan,
                updateDeliveryPlanById,
                deleteDeliveryPlanById

            }}

        >
            {children}
        </DeliveryPlanContext.Provider>
    );
};

export default DeliveryPlanContextProvider;
