import React, {lazy, Suspense, useContext} from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import LoadingSuspense from './components/common/LoadingSuspense';
import {
    LOGIN_PATH,
    PAGE_403_PATH,
    PAGE_404_PATH,
    PAGE_500_PATH,
    PMS_LOGIN_PATH,
    PMS_PHARMACY_VIEW_PATH,
    PMS_TERMS_CONDITION_APP_PATH,
    ROOT_PATH
} from './routes/Slugs';
import PrivateRoute from './components/common/PrivateRoute';
import {AuthContext} from './contexts/AuthContextProvider';
import Acl from './Acl';
import * as ReactGA from "react-ga";

const DefaultLayout = lazy(() => import('./components/layout/DefaultLayout'));
const WrappedLogin = lazy(() => import('./components/pages/os/login/Login'));
const PMSLoginPage = lazy(() => import("./components/pages/pms/login/PMSLoginPage"));
const PMSPharmacy = lazy(() => import("./components/pages/pms/pharmacy/PMSPharmacy"));
const Page403 = lazy(() => import('./components/pages/os/error_pages/Page403'));
const Page404 = lazy(() => import('./components/pages/os/error_pages/Page404'));
const Page500 = lazy(() => import('./components/pages/os/error_pages/Page500'));

const PMSTermsAndConditions = lazy(() => import("./components/pages/terms_conditions/PMSTermsConditions"));

const App = () => {

    const authContext = useContext(AuthContext);

    ReactGA.initialize('G-23F7CG8KX5');
    ReactGA.pageview(window.location.pathname + window.location.search);

    return (
        <div className="app_wrapper">
            <Suspense fallback={<LoadingSuspense height={"100vh"}/>}>
                <BrowserRouter>
                    <Switch>
                        <Route exact path={LOGIN_PATH} component={WrappedLogin}/>
                        <Route exact path={PMS_LOGIN_PATH} component={PMSLoginPage}/>
                        <Route exact path={PMS_TERMS_CONDITION_APP_PATH} component={PMSTermsAndConditions}/>
                        <PrivateRoute isLogin={authContext.isLogin} exact path={PAGE_404_PATH} component={Page404}/>
                        <PrivateRoute isLogin={authContext.isLogin} exact path={PAGE_403_PATH} component={Page403}/>
                        <PrivateRoute isLogin={authContext.isLogin} exact path={PAGE_500_PATH} component={Page500}/>
                        <PrivateRoute isLogin={authContext.isLogin} path={PMS_PHARMACY_VIEW_PATH}
                                      component={PMSPharmacy}/>
                        <PrivateRoute isLogin={authContext.isLogin} path={ROOT_PATH} component={DefaultLayout}/>
                    </Switch>
                    <Acl/>
                </BrowserRouter>

            </Suspense>
        </div>
    );

}

export default App;
