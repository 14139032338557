import React, {createContext, useState} from 'react';
import CustomerService from "../services/CustomerService";
import confirmationModal from "../components/common/ConfirmationModal";

export const CustomerContext = createContext("CustomerContext");

const CustomerContextProvider = ({children}) => {

    const [customers, setCustomers] = useState(null);
    const [customer, setCustomer] = useState(null);
    const [profileLoading, setProfileLoading] = useState(false);
    const [totalElements, setTotalElements] = useState(0);
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');

    async function getAllCustomers(params) {

        try {
            setLoading(true);
            setErrorMsg('')
            const res = await CustomerService.getAllCustomers(params);
            setCustomers(res.data.content);
            setTotalElements(res.data.totalElements);
            setLoading(false);
            return res.data.content;
        } catch (error) {
            setLoading(false);
            setErrorMsg("Can not load Customers. Please check the console.");
            console.log("error", console.error())
            return false;
        }

    }

    async function getCustomerById(id) {

        try {
            setLoading(true);

            setErrorMsg('');
            const res = await CustomerService.getCustomerById(id);
            setCustomer(res.data);
            setLoading(false);
            return res.data;
        } catch (error) {
            setLoading(false);
            setErrorMsg("Can not load Customers. Please check the console.");
            console.log("error", error)
            return error.response ? error.response.status : 500;
        }

    }


    async function addCustomer(data) {

        try {
            const res = await CustomerService.addCustomer(data)
            setCustomer(res.data);
            confirmationModal(`Customer Id - ${res.data.id} save successfully.`, "success");
            return res.data;
        } catch (err) {
            const message = err.response ? err.response.data.message : err.message;
            console.log("post customer error", message);
            confirmationModal("Can not save Customer. " + message, "error");
            return null;
        }

    }

    async function getCustomerProfile() {
        try {
            setProfileLoading(true);
            const res = await CustomerService.getProfile();
            setCustomer(res.data);
            setProfileLoading(false);
            return true;
        } catch (error) {
            setProfileLoading(false);
            const message = error.response ? error.response.data.message : error.message;
            console.log("Get profile error", message);
            return false;
        }
    }

    async function updateCustomer(data, id) {
        try {
            const res = await CustomerService.updateCustomer(data, id);
            confirmationModal(`Customer updated Id - ${res.data.id} save successfully.`, "success");
            return res.data;
        } catch (error) {
            const message = error.response ? error.response.data.message : error.message;
            console.log("Update customer error", message);
            confirmationModal("Can not update Customer. " + message, "error");
            return null;
        }

    }

    async function deleteCustomer(id) {

        if (!window.confirm("Are you sure you want to delete this Customer ?")) return;

        try {
            await CustomerService.deleteCustomer(id);
            let updatedCustomers = customers;
            updatedCustomers = customers.filter(e => e.id !== id)
            setCustomers(updatedCustomers);
            return true;
        } catch {
            return false;
        }

    }

    const changeCustomerStatus = async (email, status) => {

        // if (!window.confirm(`Are you sure you want to change this Customer status to ${status} ?`)) return;

        try {
            console.log(email, status);
            await CustomerService.changeCustomerStatus(email, status);
            let updatedCustomers = customers;
            updatedCustomers = customers.map(e => {
                if (e.email === email) e.status = status;
                return e;
            })
            setCustomers(updatedCustomers);
            return true;
        } catch {
            return false;
        }

    }


    return (
        <CustomerContext.Provider
            value={{
                customers,
                customer,
                getCustomerById,
                getAllCustomers,
                profileLoading,
                updateCustomer,
                deleteCustomer,
                addCustomer,
                getCustomerProfile,
                changeCustomerStatus,
                loading,
                totalElements,
                errorMsg
            }}
        >
            {children}
        </CustomerContext.Provider>
    );
}

export default CustomerContextProvider;