import * as URL from "../helpers/Constants";
import axios from "axios";

export default class PharmacyOwnerService {
    static getAllPharmacyOwners(page, size, name) {
        return axios.get(URL.GET_ALL_PHARMACY_OWNERS, {
            ...PharmacyOwnerService.getAuthHeader(),
            params: {page, size, name}
        });
    }

    static getProductsForPharmacyOwner(page, size) {
        return axios.get(URL.GET_ALL_PRODUCTS_FOR_PHARMACY_OWNER, {
            ...PharmacyOwnerService.getAuthHeader(),
            params: {page, size}
        });
    }

    static addPharmacyOwner(data) {
        return axios.post(URL.POST_PHARMACY_OWNER, data, PharmacyOwnerService.getAuthHeader());
    }

    static updatePharmacyOwner(data, id) {
        return axios.put(`${URL.UPDATE_PHARMACY_OWNER}/${id}`, data, PharmacyOwnerService.getAuthHeader());
    }

    static deletePharmacyOwner(id) {
        return axios.delete(`${URL.DELETE_PHARMACY_OWNER}/${id}`, PharmacyOwnerService.getAuthHeader());
    }

    static getAllPharmaciesForPharmacyOwner(page, size) {
        return axios.get(URL.GET_ALL_PHARMACY_FOR_PHARMACY_OWNER, {
            ...PharmacyOwnerService.getAuthHeader(),
            params: {page, size}
        });
    }

    static getAuthHeader() {
        const accessToken = localStorage.getItem("oushodsheba_access_token");
        const options = {headers: {"Authorization": `Bearer ${accessToken}`}}
        return options;
    }
}