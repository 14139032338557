import * as URL from "../helpers/Constants";
import axios from "axios";

export default class LabTestService {
    static getAllLabTest(page, size, params) {
        return axios.get(URL.GET_ALL_LAB_TEST, {
            ...LabTestService.getAuthHeader(),
            params
        });
    }

    static createLabTest(data) {
        return axios.post(URL.CREATE_LAB_TEST, data, LabTestService.getAuthHeader());
    }

    static updateLabTest(testId, data) {
        return axios.put(`${URL.UPDATE_LAB_TEST_BY_TEST_ID}/${testId}`, data, LabTestService.getAuthHeader());
    }

    static createLabTestParameter(data) {
        return axios.post(`${URL.CREATE_LAB_TEST_PARAMETER}`, data, LabTestService.getAuthHeader());
    }

    static updateLabTestParameter(testParameterId, data) {
        return axios.put(`${URL.UPDATE_LAB_TEST_PARAMETER_PARAMETER_ID}/${testParameterId}`, data, LabTestService.getAuthHeader());
    }

    static createCustomerLabTest(data, customerId) {
        return axios.post(`${URL.CREATE_CUSTOMER_LAB_TEST}/${customerId}`, data, LabTestService.getAuthHeader());
    }

    static updateCustomerLabTest(data, customerLabTestId) {
        return axios.put(`${URL.UPDATE_CUSTOMER_LAB_TEST}/${customerLabTestId}`, data, LabTestService.getAuthHeader());
    }

    static getAllCustomerLabTest(page, size, params) {
        return axios.get(`${URL.GET_ALL_CUSTOMER_LAB_TEST}`, {
            ...LabTestService.getAuthHeader(),
            params
        });
    }

    static getAuthHeader() {
        const accessToken = localStorage.getItem("oushodsheba_access_token");
        return {headers: {Authorization: `Bearer ${accessToken}`}};
    }

}