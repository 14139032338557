import React from 'react';
import {Spin} from 'antd';

const LoadingSuspense = ({height, width}) => {

    const style = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height,
        width
    }

    // const antIcon = <Icon type="loading" style={{ fontSize: 50 }} spin />;

    return (
        <div style={style}>
            <Spin size='large' tip="Loading..."/>
            {/*<Spin indicator={antIcon} />*/}
        </div>
    );
}

LoadingSuspense.defaultProps = {
    width: "100%",
    height: "100%"
}

export default LoadingSuspense;