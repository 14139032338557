import axios from "axios";
import * as URL from "../../helpers/Constants";

export default class PMSExpenseServices {


    static getAllExpensesByPharmacyId(params) {
        return axios.get(`${URL.PMS_GET_ALL_EXPENSE_BY_PHARMACY_ID}/${params.id}`, {
            params, ...PMSExpenseServices.getAuthHeader()})
    }

    static getExpenseByPharmacyIdById(pharmacyId, id) {
        return axios.get(`${URL.PMS_GET_EXPENSE_BY_PHARMACY_ID_BY_ID}/${pharmacyId}/${id}`, PMSExpenseServices.getAuthHeader())
    }

    static createExpenseByPharmacyId(id, data) {
        return axios.post(`${URL.PMS_CREATE_EXPENSE_BY_PHARMACY_ID}/${id}`, data, PMSExpenseServices.getAuthHeader())
    }

    static updateExpenseByIdByPharmacyId(id, pharmacyId, data) {
        return axios.put(`${URL.PMS_UPDATE_EXPENSE_BY_ID_PHARMACY_ID}/${id}/${pharmacyId}`, data, PMSExpenseServices.getAuthHeader())
    }


    static getAuthHeader() {
        const accessToken = localStorage.getItem("oushodsheba_access_token");
        return {headers: {"Authorization": `Bearer ${accessToken}`}};
    }

}