import axios from "axios";
import * as URL from "../../helpers/Constants";
import {getPharmacyId} from "../../helpers/Utils";

export default class PMSInventoryServices {

    static getInventories(params) {
        return axios.get(
            `${URL.PMS_GET_ALL_INVENTORIES_BY_PHARMACY_ID}/${getPharmacyId()}`,
            {
                ...PMSInventoryServices.getAuthHeader(),
                params,
            }
        );
    }

    static getInventoryById(id) {
        return axios.get(
            `${URL.PMS_GET_INVENTORY_BY_ID}/${getPharmacyId()}/${id}`,
            PMSInventoryServices.getAuthHeader()
        );
    }

    static createInventory(pharmacyId, data) {
        return axios.post(`${URL.PMS_CREATE_INVENTORY_BY_PHARMACY_ID}/${pharmacyId}`, data, PMSInventoryServices.getAuthHeader());
    }

    // static createSale(pharmacyId, data) {
    //     return axios.post(`${URL.PMS_CREATE_SALES_BY_PHARMACY_ID}/${pharmacyId}`, data, PMSInventoryServices.getAuthHeader());
    // }

    static editInventory(id, pharmacyId, data, params) {
        return axios.put(`${URL.PMS_UPDATE_INVENTORY_BY_ID_PHARMACY_ID}/${id}/${pharmacyId}`, data, {
            ...PMSInventoryServices.getAuthHeader(), params
        });
    }

    static getAuthHeader() {
        const accessToken = localStorage.getItem("oushodsheba_access_token");
        return {headers: {"Authorization": `Bearer ${accessToken}`}};
    }

}
