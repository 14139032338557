import * as URL from "../helpers/Constants";
import axios from "axios";

export default class CartService {
    static getAllCarts() {
        return axios.get(URL.GET_ALL_CARTS_URL);
    }

    static addCart(data) {
        return axios.post(URL.POST_CART_URL, data);
    }

    static getCartById(id) {
        return axios.get(URL.GET_CART_BY_ID_URL + '/' + id);
    }

    static updateCart(data, id) {
        return axios.put(`${URL.UPDATE_CART_URL}/${id}`, data);
    }

}