import React, {createContext, useState} from 'react';
import InvoiceService from "../services/InvoiceService";

export const InvoiceContext = createContext("InvoiceContext");


const InvoiceContextProvider = ({children}) => {

    const [invoices, setInvoices] = useState(null);
    const [invoice, setInvoice] = useState(null);
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [totalElements, setTotalElements] = useState(0);

    async function getAllInvoices({page, size, id}) {

        try {
            setLoading(true);
            setErrorMsg('');
            const res = await InvoiceService.getAllInvoices(page, size, id);
            setInvoices(res.data.content);
            setTotalElements(res.data.totalElements);
            setLoading(false);
            return res.data.content;
        } catch (error) {
            setLoading(false);
            setErrorMsg('Can not load invoices. Please check the console.');
            console.log("error", console.error())
            return false;
        }

    }

    async function getInvoiceById(id) {

        try {
            setLoading(true);
            setErrorMsg('');
            const res = await InvoiceService.getInvoiceById(id);
            setInvoice(res.data)
            setLoading(false);
            return res.data;
        } catch (error) {
            setLoading(false);
            setErrorMsg('Can not load invoice. Please check the console.');
            console.log("error", console.error())
            return false;
        }

    }


    return (
        <InvoiceContext.Provider
            value={{
                invoices,
                invoice,
                getAllInvoices,
                getInvoiceById,
                totalElements,
                loading,
                errorMsg

            }}
        >
            {children}
        </InvoiceContext.Provider>
    );
}

export default InvoiceContextProvider;