import React, {createContext, useContext, useState} from 'react';
import ProductService from "../services/ProductService";
import {UserContext} from './UserContextProvider';
import Notification from "../components/common/Notification";

export const ProductContext = createContext("ProductContext");

const ProductContextProvider = ({children}) => {

    const [generics, setGenerics] = useState(null);
    const [products, setProducts] = useState(null);
    const [featuredProducts, setFeaturedProducts] = useState([]);
    const [pendingProducts, setPendingProducts] = useState(null);
    const [product, setProduct] = useState(null);
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [totalElements, setTotalElements] = useState(0);
    const userContext = useContext(UserContext);

    async function getAllProducts({page, size, id, productName, form, companyName, disabled, featured, verified}) {
        try {
            setLoading(true);
            setErrorMsg('');
            const res = await ProductService.getAllProducts(page, size, id, productName, form, companyName, disabled, featured, verified);
            setProducts(res.data.content);
            setTotalElements(res.data.totalElements);
            setLoading(false);
            return res.data.content;
        } catch (error) {
            setLoading(false);
            setErrorMsg("Can not load Products. Please check the console.");
            console.log("error", error)
            return false;
        }

    }

    async function getAllGenerics(params) {
        try {
            setLoading(true);
            setErrorMsg('');
            const res = await ProductService.getAllgenerics(params);
            setGenerics(res.data.content);
            setTotalElements(res.data.totalElements);
            setLoading(false);
            return res.data.content;
        } catch (error) {
            setLoading(false);
            setErrorMsg("Can not load generics. Please check the console.");
            console.log("error", error)
            return false;
        }

    }

    const getAllFeaturedProducts = async ({page, size, id, productName, form, companyName, featured}) => {

        try {
            setLoading(true);

            setErrorMsg('');
            const res = await ProductService.getAllFeaturedProducts(page, size, id, productName, form, companyName, featured);
            setFeaturedProducts(res.data.content);
            setTotalElements(res.data.totalElements);
            setLoading(false);
            return res.data.content;
        } catch (error) {
            setLoading(false);
            setErrorMsg("Can not load Products. Please check the console.");
            console.log("error", error)
            return false;
        }

    }

    async function getAllPendingProducts({page, size, id, productName, form, companyName}) {

        try {
            setLoading(true);

            setErrorMsg('');
            const res = await ProductService.getAllPendingProducts(page, size, id, productName, form, companyName);
            setPendingProducts(res.data.content);
            setTotalElements(res.data.totalElements);
            setLoading(false);
            return res.data.content;
        } catch (error) {
            setLoading(false);
            setErrorMsg("Can not load Products. Please check the console.");
            console.log("error", error)
            return false;
        }

    }

    const getProductById = async (id) => {

        try {
            setLoading(true);
            setErrorMsg('');
            const res = await ProductService.getProductById(id);
            setProduct(res.data);
            setLoading(false);
            return res.data;
        } catch (error) {
            setLoading(false);
            setErrorMsg("Can not load Products. Please check the console.");
            console.log("error", error)
            return error.response ? error.response.status : 500;
        }

    }

    const addProduct = async (data) => {

        try {
            setErrorMsg('');
            setLoading(true);
            const res = userContext.user.userType === 'PHARMACY_OWNER' ? await ProductService.addProductForPharmacyOwner(data) :
                await ProductService.addProduct(data);
            setProduct(res.data);
            setLoading(false);
            Notification("success", "Saved", `Product Id-${res.data.id} saved successfully.`);
            return true;
        } catch (error) {
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            Notification("error", "ERROR", `${_error}`);
            return false;
        }

    }

    const updateProduct = async (DATA, id) => {
        try {

            setLoading(true);
            const res = await ProductService.updateProduct(DATA, id);
            setProduct(res.data);
            setLoading(false);
            Notification("success", "Updated", `Product Id-${res.data.id} updated successfully!`);
            return res.data;
        } catch (error) {
            setLoading(false);
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }

    async function verifyProduct(DATA, id) {
        try {

            setLoading(true);
            setErrorMsg('');
            let data = await ProductService.updateProduct(DATA, id);
            const res = await ProductService.verifyProduct(true, id);

            const updatedProducts = [...products.map(e => {
                if (e.id === res.data.id) {
                    e = res.data;
                }
                return e;
            })]

            setProducts(updatedProducts);


            setLoading(false);
            // confirmationModal(`Product Id-${res.data.id} verified successfully!`, "success");
            Notification("success", "Verified", `Product Id-${res.data.id} verified successfully!`);
            return data;
        } catch (error) {
            /*const message = error.response ? error.response.data.message : error.message;
            setErrorMsg("Can not update Product. Please check the console.");
            confirmationModal("Can not verify Product. " + message, "error");
            console.log("Product update error.", error);*/
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }

    async function updateProductAsPopular(popular, id) {
        try {

            setLoading(true);
            setErrorMsg('');
            const data = await ProductService.updateProductAsPopular(popular, id);
            setProducts(products.map(e => {
                if (e.id === id) {
                    e.popular = popular;
                }
                return e;
            }))
            setLoading(false);
            // confirmationModal("Product updated successfully.", "success");
            Notification("success", "Updated", "Product updated successfully.");
            return data;
        } catch (error) {
            /*const message = error.response ? error.response.data.message : error.message;
            setErrorMsg("Can not update Product. Please check the console.");
            confirmationModal("Can not update Product. " + message, "error");
            console.log("Product update error.", error);*/
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }

    async function deleteProduct(id) {

        // if (!window.confirm("Are you sure you want to delete this Product ?")) return;

        try {
            setLoading(true);
            setErrorMsg('');
            await ProductService.deleteProduct(id);
            let updatedProducts = products;
            updatedProducts = products.filter(e => e.id !== id);
            setProducts(updatedProducts);
            setLoading(false);
            return true;
        } catch (error) {
            /*setErrorMsg("Can not delete Products. Please check the console.");
            console.log("Product delete error.", error);*/
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }

    return (
        <ProductContext.Provider
            value={{
                products,
                featuredProducts,
                pendingProducts,
                product,
                totalElements,
                loading,
                errorMsg,
                getAllProducts,
                getAllPendingProducts,
                getAllFeaturedProducts,
                addProduct,
                updateProduct,
                updateProductAsPopular,
                deleteProduct,
                getProductById,
                verifyProduct,
                getAllGenerics,
                generics
            }}
        >
            {children}
        </ProductContext.Provider>
    );
}

export default ProductContextProvider;
