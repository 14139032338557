import * as URL from "../helpers/Constants";
import axios from "axios";

export default class OrderService {
    static getAllOrders(page, size, from, to, id, customer, status) {
        return axios.get(URL.GET_ALL_ORDERS, {
            ...OrderService.getAuthHeader(),
            params: {page, size, from, to, id, customer, status}
        });
    }

    static getAllStackedOrders(from, to, page, size, id, customerId, customerName, status) {
        return axios.get(URL.GET_ALL_STACKED_ORDERS, {
            ...OrderService.getAuthHeader(),
            params: {from, to, page, size, id, customerId, customerName, status}
        });
    }

    static getAllPendingOrdersForDeliveryPerson(page, size) {
        return axios.get(URL.GET_ALL_PENDING_ORDERS_FOR_DELIVERY_PERSONS, OrderService.getAuthHeader());
    }

    static processOrder(data, id, cancel) {
        return axios.post(`${URL.PROCESS_ORDER}/${id}`, data, {...OrderService.getAuthHeader(), params: {cancel}})
    }

    static getOrderById(id) {
        return axios.get(`${URL.GET_ORDER_BY_ID}/${id}`, OrderService.getAuthHeader());
    }

    static getOrderTrackingById(id) {
        return axios.get(`${URL.GET_ORDER_TRACKING_BY_ID}/${id}`, OrderService.getAuthHeader());
    }

    static addOrder(data) {
        return axios.post(URL.POST_ORDER, data, OrderService.getAuthHeader());
    }

    static updateOrder(data, id, params) {
        return axios.put(`${URL.UPDATE_ORDER}/${id}`, data, {
            ...OrderService.getAuthHeader(), params
        });
    }

    static processOrderCalculation(data, id) {
        return axios.post(`${URL.PROCESS_ORDER_CALCULATION}/${id}`, data, OrderService.getAuthHeader());
    }

    static deleteOrder(id) {
        return axios.delete(`${URL.DELETE_ORDER}/${id}`, OrderService.getAuthHeader());
    }

    static assignOrdersToDeliveryPerson(data, id) {
        return axios.post(`${URL.ASSIGN_ORDER_TO_DELIVERY_PERSON}/${id}`, data, OrderService.getAuthHeader());
    }

    static cancelOrder(id) {
        return axios.post(`${URL.CANCEL_ORDER}/${id}`, null, OrderService.getAuthHeader());
    }

    static getAllDefaultDeliveryPersonOrderStack(page, size, id, areaName, expressDelivery) {
        return axios.get(URL.DEFAULT_DELIVERY_PERSON_ORDER_STACK, {
            ...OrderService.getAuthHeader(),
            params: {page, size, id, areaName, expressDelivery}
        });
    }

    static getAllDefaultPharmacyOrderStack(page, size, id, areaName, expressDelivery) {
        return axios.get(URL.DEFAULT_PHARMACY_ORDER_STACK, {
            ...OrderService.getAuthHeader(),
            params: {page, size, id, areaName, expressDelivery}
        });
    }

    static getAuthHeader() {
        const accessToken = localStorage.getItem("oushodsheba_access_token");
        const options = {headers: {"Authorization": `Bearer ${accessToken}`}}
        return options;
    }

}