import * as URL from "../helpers/Constants";
import axios from "axios";

export default class DistrictService {
    static getAllDistricts(page, size, id, districtName) {
        return axios.get(URL.GET_ALL_DISTRICS, {
            ...DistrictService.getAuthHeader(),
            params: {page, size, id, districtName}
        });
    }

    static getDistrictById(id) {
        return axios.get(`${URL.GET_DISTRIC_BY_ID}/${id}`, DistrictService.getAuthHeader());
    }

    static addDistrict(data) {
        return axios.post(URL.POST_DISTRIC, data, DistrictService.getAuthHeader());
    }

    static updateDistrict(data, id) {
        return axios.put(`${URL.UPDATE_DISTRIC}/${id}`, data, DistrictService.getAuthHeader());
    }

    static deleteDistrict(id) {
        return axios.delete(`${URL.DELETE_DISTRIC}/${id}`, DistrictService.getAuthHeader());
    }

    static getAuthHeader() {
        const accessToken = localStorage.getItem("oushodsheba_access_token");
        const options = {headers: {"Authorization": `Bearer ${accessToken}`}}
        return options;
    }

    // static changeDistricStatus(email, status) {
    //     return axios.post(`${URL.UPDATE_DISTRIC_STATUS}/${email}?status=${status}`)
    // }
}