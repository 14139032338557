import * as URL from "../helpers/Constants";
import axios from "axios";

export default class PromoService {
    static getAllPromos(page, size, id, promoCode) {
        return axios.get(URL.GET_ALL_PROMOS, {...PromoService.getAuthHeader(), params: {page, size, id, promoCode}});
    }

    static getPromoById(id) {
        return axios.get(`${URL.GET_PROMO_BY_ID}/${id}`, PromoService.getAuthHeader());
    }

    static addPromo(data) {
        return axios.post(URL.POST_PROMO, data, PromoService.getAuthHeader());
    }

    static updatePromo(data, id) {
        return axios.put(`${URL.UPDATE_PROMO}/${id}`, data, PromoService.getAuthHeader());
    }

    static deletePromo(id) {
        return axios.delete(`${URL.DELETE_PROMO}/${id}`, PromoService.getAuthHeader());
    }

    static getAuthHeader() {
        const accessToken = localStorage.getItem("oushodsheba_access_token");
        const options = {headers: {"Authorization": `Bearer ${accessToken}`}}
        return options;
    }

    // static changePromoStatus(email, status) {
    //     return axios.post(`${URL.UPDATE_PROMO_STATUS}/${email}?status=${status}`)
    // }
}