import * as URL from "../helpers/Constants";
import axios from "axios";

export default class DeliveryChargeService {
    static getAllDeliveryCharges(page, size, id) {
        return axios.get(URL.GET_ALL_DELIVERY_CHARGES, {
            ...DeliveryChargeService.getAuthHeader(),
            params: {page, size, id}
        });
    }

    static getDeliveryChargeById(id) {
        return axios.get(`${URL.GET_DELIVERY_CHARGE_BY_ID}/${id}`, DeliveryChargeService.getAuthHeader());
    }

    static addDeliveryCharge(data) {
        return axios.post(URL.POST_DELIVERY_CHARGE, data, DeliveryChargeService.getAuthHeader());
    }

    static updateDeliveryCharge(data, id) {
        return axios.put(`${URL.UPDATE_DELIVERY_CHARGE}/${id}`, data, DeliveryChargeService.getAuthHeader());
    }

    static deleteDeliveryCharge(id) {
        return axios.delete(`${URL.DELETE_DELIVERY_CHARGE}/${id}`, DeliveryChargeService.getAuthHeader());
    }

    static getAuthHeader() {
        const accessToken = localStorage.getItem("oushodsheba_access_token");
        const options = {headers: {"Authorization": `Bearer ${accessToken}`}}
        return options;
    }

    // static changeDeliveryChargeStatus(email, status) {
    //     return axios.post(`${URL.UPDATE_DELIVERY_CHARGE_STATUS}/${email}?status=${status}`)
    // }
}