import React, {createContext, useContext, useState} from 'react';
import TableService from "../../services/ui_configuration_service/TableService";
import confirmationModal from "../../components/common/ConfirmationModal";
import {ProductContext} from '../ProductContextProvider';
import {UserContext} from '../UserContextProvider';
import {ProductCategoryContext} from '../ProductCategoryContextProvider';
import {CompanyContext} from '../CompanyContextProvider';
import {CountryContext} from '../CountryContextProvider';
import {CustomerContext} from '../CustomerContextProvider';
import {CustomerGroupContext} from '../CustomerGroupContextProvider';
import {DistrictContext} from '../DistrictContextProvider';
import {GenericContext} from '../GenericContextProvider';
import {OrderContext} from '../OrderContextProvider';
import {PharmacyContext} from '../PharmacyContextProvider';
import {PrescriptionContext} from '../PrescriptionContextProvider';
import {ProfitSharingPlanContext} from '../ProfitSharingPlanContextProvider';
import {PromoContext} from '../PromoContextProvider';
import {NotificationContext} from '../NotificationContextProvider';
import {ThanaContext} from '../ThanaContextProvider';
import {DeliveryPersonContext} from '../DeliveryPersonContextProvider';

export const TableContext = createContext("TableContext");

const TableContextProvider = ({children}) => {

    const [tables, setTables] = useState(null);
    const [table, setTable] = useState();
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [totalElements, setTotalElements] = useState('');

    const productContext = useContext(ProductContext);
    const userContext = useContext(UserContext);
    const categoryContext = useContext(ProductCategoryContext);
    const companyContext = useContext(CompanyContext);
    const countryContext = useContext(CountryContext);
    const customerContext = useContext(CustomerContext);
    const customerGroupContext = useContext(CustomerGroupContext);
    const deliveryPersonContext = useContext(DeliveryPersonContext);
    const districtContext = useContext(DistrictContext);
    const genericContext = useContext(GenericContext);
    const orderContext = useContext(OrderContext);
    const pharmacyContext = useContext(PharmacyContext);
    const prescriptionContext = useContext(PrescriptionContext);
    const profitSharingPlanContext = useContext(ProfitSharingPlanContext);
    const promoContext = useContext(PromoContext);
    const notificationContext = useContext(NotificationContext);
    const thanaContext = useContext(ThanaContext);


    const contextList = [
        {
            context: productContext,
            objectName: 'Product'
        },
        {
            context: userContext,
            objectName: 'User'
        },
        {
            context: categoryContext,
            objectName: 'Category'
        },
        {
            context: companyContext,
            objectName: 'Company'
        },
        {
            context: countryContext,
            objectName: 'Country'
        },
        {
            context: customerContext,
            objectName: 'Customer'
        },
        {
            context: customerGroupContext,
            objectName: 'CustomerGroup'
        },
        {
            context: districtContext,
            objectName: 'Distric'
        },
        {
            context: deliveryPersonContext,
            objectName: 'DeliveryPerson'
        },
        {
            context: genericContext,
            objectName: 'Generic'
        },
        // {
        //     context: hospitalContext,
        //     objectName: 'Hospital'
        // },
        {
            context: orderContext,
            objectName: 'Order'
        },
        // {
        //     context: pharmacyAgentContext,
        //     objectName: 'Pharmacist'
        // },
        {
            context: pharmacyContext,
            objectName: 'Pharmacy'
        },
        {
            context: prescriptionContext,
            objectName: 'Prescription'
        },
        {
            context: profitSharingPlanContext,
            objectName: 'ProfitSharingPlan'
        },
        {
            context: promoContext,
            objectName: 'Promo'
        },
        {
            context: notificationContext,
            objectName: 'Notification'
        },
        {
            context: thanaContext,
            objectName: 'Thana'
        }
    ]


    async function getAllTables(page, size) {

        try {
            setLoading(true);
            setErrorMsg('');
            const res = await TableService.getAllTables(page, size);
            setTables(res.data.content);
            setTotalElements(res.data.totalElements);
            setLoading(false);
            return res.data.content;
        } catch (error) {
            setErrorMsg("Can not load Tables. Please check the console.");
            console.log("error", console.error());
            return false;
        }

    }

    async function getTableById(id) {

        try {
            setLoading(true);
            setErrorMsg('');
            const res = await TableService.getTableById(id);
            setTable(res.data);
            setLoading(false);
            return 200;
        } catch (error) {
            setLoading(false);
            setErrorMsg("Can not load Tables. Please check the console.");
            console.log("error", error)
            return error.response ? error.response.status : 500;
        }

    }

    // async function getColumnConfig(tableId) {
    //
    //     try {
    //         setLoading(true);
    //         setErrorMsg('');
    //         const res = await TableService.getColumnConfig(tableId);
    //         setTable(res.data);
    //         setLoading(false);
    //         return res.data;
    //     } catch (error) {
    //         setLoading(false);
    //         setErrorMsg("Can not load Tables. Please check the console.");
    //         console.log("error", error)
    //         return error.response ? error.response.status : 500;
    //     }
    //
    // }

    async function addTable(data) {

        try {
            setErrorMsg('');
            setLoading(true);
            const res = await TableService.addTable(data)
            setTables(tables ? [...tables, res.data] : [res.data]);
            setLoading(false);
            confirmationModal("Table saved successfully. ", "success");
            return res.data;
        } catch (err) {
            const message = err.response ? err.response.data.message : err.message;
            setErrorMsg("Can not add Tables. Please check the console.");
            console.log("post table error", message);
            confirmationModal("Can not save Table. " + message, "error");
            return false;
        }

    }

    async function addColumn(data, tableId) {

        try {
            // setErrorMsg('');
            // setLoading(true);
            const res = await TableService.addColumn(data, tableId);
            // setLoading(false);
            return res.data;

        } catch (err) {
            const message = err.response ? err.response.data.message : err.message;
            console.log(message);
        }

    }

    async function updateTable(data, id) {
        try {
            setLoading(true);
            setErrorMsg('');
            const res = await TableService.updateTable(data, id);
            let updatedTables = tables;
            updatedTables = tables.map(e => {
                if (e.id === id) e = res.data;
                return e;
            })

            setTables(updatedTables);
            setLoading(false);
            return true;
        } catch (error) {
            setErrorMsg("Can not update Table. Please check the console.");
            console.log("Table update error.", error);
            return false;
        }

    }

    async function updateTableColumn(data, tableId) {
        try {
            // setLoading(true);
            setErrorMsg('');
            const res = await TableService.updateTableColumn(data, tableId);
            let updatedTables = tables;
            updatedTables = tables.map(e => {
                if (e.id === tableId) e.columnConfig = res.data;
                return e;
            })

            setTables(updatedTables);
            // setLoading(false);
            return true;
        } catch (error) {
            setErrorMsg("Can not update Table. Please check the console.");
            console.log("Table update error.", error);
            return false;
        }

    }

    async function updateAllTableColumns(data, tableId) {
        try {
            // setLoading(true);
            setErrorMsg('');
            const res = await TableService.updateAllTableColumns(data, tableId);
            let updatedTables = tables;
            updatedTables = tables.map(e => {
                if (e.id === tableId) e.columnConfig = res.data;
                return e;
            })

            setTables(updatedTables);
            // setLoading(false);
            return true;
        } catch (error) {
            setErrorMsg("Can not update Table. Please check the console.");
            console.log("Table update error.", error);
            return false;
        }

    }

    async function deleteTable(id) {

        if (!window.confirm("Are you sure you want to delete this Table ?")) return;

        try {
            setLoading(true);
            setErrorMsg('');
            await TableService.deleteTable(id);
            let updatedTables = tables;
            updatedTables = tables.filter(e => e.id !== id);
            setTables(updatedTables);
            setLoading(false);
            return true;
        } catch (error) {
            setErrorMsg("Can not delete Tables. Please check the console.");
            console.log("Table delete error.", error);
            return false;
        }
    }

    async function loadTables() {

        // for (let i = 1; i < 36; i++) {
        //     const res = await TableService.deleteTable(i);
        //     console.log(res.data);
        // }

        contextList.forEach(async context => {
            // const objectName = context.objectName;
            // const table = await addTable({ tableName: objectName });

            // id++;
            // const res = await TableService.deleteTable(id);

            // console.log(res.data);
            // const id = table.id;

            console.log(context);


            // const functionName = `get${objectName}ById`;
            // id++;

            // const obj = await context.context[functionName](1);

            // let i = 0;
            // const flatObj = flatten(obj);

            // for (let e in flatObj) {

            //     const data = {
            //         color: 'black',
            //         columnName: e,
            //         elementType: 'text',
            //         position: i,
            //         style: 'none',
            //         visible: true
            //     }
            //     i++;

            //     const res = await addColumn(data, id);

            //     console.log(res.data);
            // }

        })


        // const res = await TableService.getColumnConfig(id);
        // console.log(res.data);


    }


    return (
        <TableContext.Provider
            value={{
                tables,
                table,
                getTableById,
                loading,
                errorMsg,
                totalElements,
                updateTableColumn,
                updateAllTableColumns,
                addColumn,
                getAllTables,
                updateTable,
                deleteTable,
                addTable,
                loadTables
            }}
        >
            {children}
        </TableContext.Provider>
    );
}

export default TableContextProvider;
