import * as URL from "../../helpers/Constants";
import axios from "axios";
import { getPharmacyId } from "../../helpers/Utils";

export default class PMSPharmacistServices {
  static getAllPharmacistsByPharmacyId(params) {
    return axios.get(`${URL.PMS_GET_ALL_PHARMACIST}/${getPharmacyId()}`, {
      ...PMSPharmacistServices.getAuthHeader(),
      params,
    });
  }

  static getPharmacistById(id) {
    return axios.get(
      `${URL.PMS_GET_PHARMACIST_BY_ID}/${id}`,
      PMSPharmacistServices.getAuthHeader()
    );
  }

  static getPharmacistRole() {
    return axios.get(
      `${URL.PMS_GET_ALL_ROLES}/${getPharmacyId()}`,
      PMSPharmacistServices.getAuthHeader()
    );
  }

  static createPharmacistByPharmacyId(params) {
    console.log(params);
    return axios.post(
      `${URL.PMS_CREATE_PHARMACIST}/${getPharmacyId()}`,
      params,
      PMSPharmacistServices.getAuthHeader()
    );
  }

  static updatePharmacistByPharmacistId(id, data) {
    return axios.put(
      `${URL.PMS_UPDATE_PHARMACIST}/${id}`,
      data,
      PMSPharmacistServices.getAuthHeader()
    );
  }

  static deletePharmacistById(id) {
    return axios.delete(
      `${URL.PMS_DELETE_PHARMACIST}/${id}`,
      PMSPharmacistServices.getAuthHeader()
    );
  }

  static getAuthHeader() {
    const accessToken = localStorage.getItem("oushodsheba_access_token");
    //console.log("accessToken: ", accessToken)
    return {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    };
  }
}
