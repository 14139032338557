import * as URL from "../../helpers/Constants";
import axios from "axios";
import { getPharmacyId } from "../../helpers/Utils";

export default class PMSTransactionServices {
  static getTransactions(accId, params) {
    return axios.get(
      `${URL.PMS_GET_PHARMACY_TRANSACTIONS}/${accId}/${getPharmacyId()}`,
      {
        ...PMSTransactionServices.getAuthHeader(),
        params,
      }
    );
  }

  static getTransactionById(id) {
    return axios.get(
      `${URL.PMS_GET_PHARMACY_TRANSACTIONS}/${id}/${getPharmacyId()}`,
      PMSTransactionServices.getAuthHeader()
    );
  }

  static createTransaction(data) {
    return axios.post(
      `${URL.PMS_CREATE_PHARMACY_TRANSACTION}/${getPharmacyId()}`,
      data,
      PMSTransactionServices.getAuthHeader()
    );
  }

  static cancelTransaction(id) {
    return axios.post(
      `${URL.PMS_CANCEL_PHARMACY_TRANSACTION}/${id}/${getPharmacyId()}`,
      null,
      PMSTransactionServices.getAuthHeader()
    );
  }

  static getAuthHeader() {
    const accessToken = localStorage.getItem("oushodsheba_access_token");
    return {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    };
  }
}
