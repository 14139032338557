import * as URL from "../helpers/Constants";
import axios from "axios";

export default class GenericService {
    static getAllGenerics(page, size, id, genericName) {
        return axios.get(URL.GET_ALL_GENERICS, {
            ...GenericService.getAuthHeader(),
            params: {page, size, id, genericName}
        });
    }

    static getGenericById(id) {
        return axios.get(`${URL.GET_GENERIC_BY_ID}/${id}`, GenericService.getAuthHeader());
    }

    static addGeneric(data) {
        return axios.post(URL.POST_GENERIC, data, GenericService.getAuthHeader());
    }

    static updateGeneric(data, id) {
        return axios.put(`${URL.UPDATE_GENERIC}/${id}`, data, GenericService.getAuthHeader());
    }

    static deleteGeneric(id) {
        return axios.delete(`${URL.DELETE_GENERIC}/${id}`, GenericService.getAuthHeader());
    }

    static getAuthHeader() {
        const accessToken = localStorage.getItem("oushodsheba_access_token");
        const options = {headers: {"Authorization": `Bearer ${accessToken}`}}
        return options;
    }

    // static changeGenericStatus(email, status) {
    //     return axios.post(`${URL.UPDATE_GENERIC_STATUS}/${email}?status=${status}`)
    // }
}