import * as URL from "../helpers/Constants";
import axios from "axios";

export default class BillService {
    static getAllBills(page, size, id, billType) {
        return axios.get(URL.GET_ALL_BILLS, {...BillService.getAuthHeader(), params: {page, size, id, billType}});
    }

    static getBillById(id) {
        return axios.get(`${URL.GET_BILL_BY_ID}/${id}`, BillService.getAuthHeader());
    }

    static getAuthHeader() {
        const accessToken = localStorage.getItem("oushodsheba_access_token");
        const options = {headers: {"Authorization": `Bearer ${accessToken}`}}
        return options;
    }
}