import * as URL from "../helpers/Constants";
import axios from "axios";

export default class PrescriptionService {
    static getAllPrescriptions(digitalized, page, size, id, alias, patientName, doctorName) {
        return axios.get(URL.GET_ALL_PRESCRIPTIONS, {
            ...PrescriptionService.getAuthHeader(),
            params: {digitalized, page, size, id, alias, patientName, doctorName}
        });
    }

    static getPrescriptionById(id) {
        return axios.get(`${URL.GET_PRESCRIPTION_BY_ID}/${id}`, PrescriptionService.getAuthHeader());
    }

    static addPrescription(data) {
        return axios.post(URL.POST_PRESCRIPTION, data, PrescriptionService.getAuthHeader());
    }

    static updatePrescription(data, id) {
        return axios.put(`${URL.UPDATE_PRESCRIPTION}/${id}`, data, PrescriptionService.getAuthHeader());
    }

    static deletePrescription(id) {
        return axios.delete(`${URL.DELETE_PRESCRIPTION}/${id}`, PrescriptionService.getAuthHeader());
    }

    static getAuthHeader() {
        const accessToken = localStorage.getItem("oushodsheba_access_token");
        const options = {headers: {"Authorization": `Bearer ${accessToken}`}}
        return options;
    }

    // static changePrescriptionStatus(email, status) {
    //     return axios.post(`${URL.UPDATE_PRESCRIPTION_STATUS}/${email}?status=${status}`)
    // }
}