import React, {createContext, useState} from 'react';
import RoleService from "../services/RoleService";
import confirmationModal from "../components/common/ConfirmationModal";

export const RoleContext = createContext("RoleContext");

const RoleContextProvider = ({children}) => {

    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [roles, setRoles] = useState([]);
    const [role, setRole] = useState(null);
    const [totalElements, setTotalElements] = useState(0);

    async function getAllRoles({page, size, id, alias}) {

        try {
            setLoading(true);
            setErrorMsg('');
            const res = await RoleService.getAllRoles(page, size, id, alias);
            setRoles(res.data.content);
            setTotalElements(res.data.totalElements);
            setLoading(false);
            return res.data.content;
        } catch (error) {
            setErrorMsg("Can not load Roles. Please check the console.");
            console.log("error", console.error())
            return false;
        }

    }

    async function addRole(data, isPharmacy, pharmacyId) {

        try {
            setErrorMsg('');
            setLoading(true);
            const res = await RoleService.addRole(data, isPharmacy, pharmacyId)
            setRole(res.data);
            setLoading(false);
            confirmationModal(`Role Id - ${res.data.id} created successfully!`, "success");
            return true;
        } catch (err) {
            const message = err.response ? err.response.data.message : err.message;
            setErrorMsg("Can not add Roles. Please check the console.");
            console.log("post role error", message);
            confirmationModal("Can not save Role. " + message, "error");
            return false;
        }

    }

    async function updateRole(data, id) {
        try {
            setLoading(true);
            setErrorMsg('');
            const res = await RoleService.updateRole(data, id);
            setRole(res.data);
            setLoading(false);
            confirmationModal(`Role Id - ${res.data.id} updated successfully!`, "success");
            return true;
        } catch (error) {
            setErrorMsg("Can not update Role. Please check the console.");
            console.log("Role update error.", error);
            return false;
        }

    }

    async function deleteRole(id) {

        if (!window.confirm("Are you sure you want to delete this Role ?")) return;

        try {
            setLoading(true);
            setErrorMsg('');
            await RoleService.deleteRole(id);
            let updatedRoles = roles;
            updatedRoles = roles.filter(e => e.id !== id);
            setRoles(updatedRoles);
            setLoading(false);
            return true;
        } catch (error) {
            setErrorMsg("Can not delete Roles. Please check the console.");
            console.log("Role delete error.", error);
            return false;
        }
    }

    async function changeUserRole(roleId, userId) {
        try {
            await RoleService.changeRole(roleId, userId);
            confirmationModal("Role Changed Successfully !", "success");
            return true;
        } catch (error) {
            console.log("Role change error !", error);
            confirmationModal(error.response ? error.response.data : error.message, "error");
            return false;
        }

    }

    const getRoleById = async (id) => {
        try {
            setLoading(true);
            const res = await RoleService.getRoleById(id);
            setRole(res.data);
            setLoading(false);
            return res.data;
        } catch (error) {
            setLoading(false);
            confirmationModal(error.response ? error.response.data : error.message, "error");
            console.log("Can not get Role by Id.");
            return false;
        }
    }


    return (
        <RoleContext.Provider
            value={{
                loading,
                errorMsg,
                role,
                roles,
                totalElements,
                getAllRoles,
                updateRole,
                deleteRole,
                getRoleById,
                changeUserRole,
                addRole
            }}
        >
            {children}
        </RoleContext.Provider>
    );
}

export default RoleContextProvider;