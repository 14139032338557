import React, {createContext, useState} from 'react';
import PharmacyOrderAccountService from "../services/PharmacyOrderAccountService";

export const PharmacyOrderAccountContext = createContext("PharmacyOrderAccountContext");

const PharmacyOrderAccountContextProvider = ({children}) => {

    const [pharmacyOrderAccounts, setPharmacyOrderAccounts] = useState(null);
    const [pharmacyOrderAccount, setPharmacyOrderAccount] = useState(null);
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [totalElements, setTotalElements] = useState(0);

    async function getAllPharmacyOrderAccounts({page, size, id, pharmacyName, subscriptionPlan, orderId}) {

        console.log("clicked jkafklj sdfa")
        try {
            setLoading(true);
            setErrorMsg('');
            const res = await PharmacyOrderAccountService.getAllPharmacyOrderAccounts(page, size, id, pharmacyName, subscriptionPlan, orderId);
            setPharmacyOrderAccounts(res.data.content);

            setTotalElements(res.data.totalElements);
            setLoading(false);
            return res.data.content;
        } catch (error) {
            setLoading(false);
            setErrorMsg("Can not load PharmacyOrderAccounts. Please check the console.");
            console.log("error", error)
            return false;
        }

    }

    const getPharmacyOrderAccountById = async (id) => {

        try {
            setLoading(true);

            setErrorMsg('');
            const res = await PharmacyOrderAccountService.getPharmacyOrderAccountById(id);
            setPharmacyOrderAccount(res.data);
            setLoading(false);
            return 200;
        } catch (error) {
            setLoading(false);
            setErrorMsg("Can not load PharmacyOrderAccounts. Please check the console.");
            console.log("error", error)
            return error.response ? error.response.status : 500;
        }

    }

    async function getPharmacyOrderAccountByPharmacyId(id) {

        try {
            setLoading(true);

            setErrorMsg('');
            const res = await PharmacyOrderAccountService.getPharmacyOrderAccountByPharmacyId(id);
            setPharmacyOrderAccount(res.data);
            setLoading(false);
            return 200;
        } catch (error) {
            setLoading(false);
            setErrorMsg("Can not load PharmacyOrderAccounts. Please check the console.");
            console.log("error", error)
            return error.response ? error.response.status : 500;
        }

    }


    return (
        <PharmacyOrderAccountContext.Provider
            value={{
                pharmacyOrderAccounts,
                pharmacyOrderAccount,
                totalElements,
                loading,
                errorMsg,
                getAllPharmacyOrderAccounts,
                getPharmacyOrderAccountById,
                getPharmacyOrderAccountByPharmacyId
            }}
        >
            {children}
        </PharmacyOrderAccountContext.Provider>
    );
}

export default PharmacyOrderAccountContextProvider;