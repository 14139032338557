import axios from "axios";
import * as URL from "../../helpers/Constants";

export default class ReconciliationService {

    static getAllReconciliationsByPharmacyId(pharmacyId, params) {
        return axios.get(`${URL.PMS_GET_ALL_RECONCILIATION}/${pharmacyId}`, {
            ...ReconciliationService.getAuthHeader(),
            params
        });
    }

    static createReconciliation(pharmacyId, data) {
        return axios.post(`${URL.PMS_CREATE_RECONCILIATION}/${pharmacyId}`, data, ReconciliationService.getAuthHeader());
    }

    static cancelReconciliation(id, pharmacyId) {
        return axios.put(`${URL.PMS_CANCEL_RECONCILIATION}/${id}/${pharmacyId}`, null, ReconciliationService.getAuthHeader());
    }

    static getAuthHeader() {
        const accessToken = localStorage.getItem("oushodsheba_access_token");
        return {headers: {Authorization: `Bearer ${accessToken}`}};
    }
}
