import React, {createContext, useState} from 'react';
import confirmationModal from "../components/common/ConfirmationModal";
import ProductTypeService from "../services/ProductTypeService";

export const ProductTypeContext = createContext("ProductTypeContext");

const ProductTypeContextProvider = ({children}) => {

    const [productTypes, setProductTypes] = useState(null);
    const [productType, setProductType] = useState();
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [totalElements, setTotalElements] = useState('');

    const getAllProductTypes = async (data) => {

        try {
            setLoading(true);
            setErrorMsg('');
            const res = await ProductTypeService.getAllProductTypes(data);
            setProductTypes(res.data.content)
            setTotalElements(res.data.totalElements);
            setLoading(false);
            return res.data.content;
        } catch (error) {
            setErrorMsg("Can not load ProductTypes. Please check the console.");
            console.log("error", console.error());
            return false;
        }

    }

    const getProductTypeById = async (id) => {

        try {
            setLoading(true);
            setErrorMsg('');
            const res = await ProductTypeService.getProductTypeById(id);
            setProductType(res.data);
            setLoading(false);
            return 200;
        } catch (error) {
            setLoading(false);
            setErrorMsg("Can not load ProductTypes. Please check the console.");
            console.log("error", error)
            return error.response ? error.response.status : 500;
        }

    }

    const addProductType = async (data) => {

        try {
            setErrorMsg('');
            setLoading(true);
            const res = await ProductTypeService.addProductType(data)
            setProductType(res.data);
            setLoading(false);
            confirmationModal(`ProductType Id - ${res.data.id} saved successfully. `, "success");
            return true;
        } catch (err) {
            const message = err.response ? err.response.data.message : err.message;
            setErrorMsg("Can not add ProductTypes. Please check the console.");
            console.log("post productType error", message);
            confirmationModal("Can not save ProductType. " + message, "error");
            return false;
        }

    }

    const updateProductType = async (data, id) => {
        try {
            setLoading(true);
            setErrorMsg('');
            const res = await ProductTypeService.updateProductType(data, id);
            setProductType(res.data);
            setLoading(false);
            confirmationModal(`ProductType Id - ${res.data.id} updated successfully!`, "success");
            return true;
        } catch (error) {
            setErrorMsg("Can not update ProductType. Please check the console.");
            console.log("ProductType update error.", error);
            confirmationModal("ProductType updated failed. ", "error");
            return false;
        }

    }

    const deleteProductType = async (id) => {

        try {
            setLoading(true);
            setErrorMsg('');
            await ProductTypeService.deleteProductType(id);
            let updatedProductTypes = productTypes;
            updatedProductTypes = productTypes.filter(e => e.id !== id);
            setProductTypes(updatedProductTypes);
            setLoading(false);
            return true;
        } catch (error) {
            setErrorMsg("Can not delete ProductTypes. Please check the console.");
            console.log("ProductType delete error.", error);
            return false;
        }
    }


    return (
        <ProductTypeContext.Provider
            value={{
                productTypes,
                productType,
                getProductTypeById,
                loading,
                errorMsg,
                totalElements,
                getAllProductTypes,
                updateProductType,
                deleteProductType,
                addProductType
            }}
        >
            {children}
        </ProductTypeContext.Provider>
    );
}

export default ProductTypeContextProvider;