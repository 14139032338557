import React, {createContext, useState} from 'react';
import OfferService from "../services/OfferService";
import confirmationModal from "../components/common/ConfirmationModal";

export const OfferContext = createContext("OfferContext");


const OfferContextProvider = ({children}) => {

    const [offers, setOffers] = useState(null);
    const [offer, setOffer] = useState(null);
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [totalElements, setTotalElements] = useState(0);

    async function getAllOffers({page, size, id, offerType}) {

        try {
            setLoading(true);
            setErrorMsg('');
            const res = await OfferService.getAllOffers(page, size, id, offerType);
            setOffers(res.data.content);
            setTotalElements(res.data.totalElements);
            setLoading(false);
            return res.data.content;
        } catch (error) {
            setLoading(false);
            setErrorMsg('Can not load offers. Please check the console.');
            console.log("error", console.error())
            return false;
        }

    }


    async function addOffer(data) {

        try {
            const res = await OfferService.addOffer(data)
            setOffer(res.data);
            confirmationModal("Offer saved successfully.", "success");
            return res.data;
        } catch (err) {
            const message = err.response ? err.response.data.message : err.message;
            console.log("post offer error", message);
            confirmationModal("Can not save Offer. " + message, "error");
            return false;
        }

    }

    async function getOfferById(id) {

        try {
            setLoading(true);
            setErrorMsg('');
            const res = await OfferService.getOfferById(id);
            setOffer(res.data)
            setLoading(false);
            return res.data;
        } catch (error) {
            setLoading(false);
            setErrorMsg('Can not load offer. Please check the console.');
            console.log("error", console.error())
            return false;
        }

    }


    return (
        <OfferContext.Provider
            value={{
                offers,
                offer,
                getAllOffers,
                getOfferById,
                addOffer,
                totalElements,
                loading,
                errorMsg

            }}
        >
            {children}
        </OfferContext.Provider>
    );
}

export default OfferContextProvider;