import React, {createContext, useState} from "react";
import Notification from "../components/common/Notification";
import UploadProductFilesService from "../services/UploadProductFilesService";

export const UploadProductFilesContext = createContext("UploadProductFilesContext");

const UploadProductFilesContextProvider = ({children}) => {

    const [loading, setLoading] = useState(false);

    const updateProductByFiles = async (data) => {

        try {
            setLoading(true);

            const res = await UploadProductFilesService.updateProductByFiles(data);

            Notification("success", "File Uploaded", "Product file uploaded successfully");

            setLoading(false);
            return res.data;
        } catch (error) {

            setLoading(false);

            let _error = "Something went wrong.";

            if(error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }

            Notification("error", "ERROR", `${_error}`)
            return null;
        }

    }

    return (
        <UploadProductFilesContext.Provider
            value={{
                loading,
                updateProductByFiles
            }}
        >
            {children}
        </UploadProductFilesContext.Provider>
    );
}

export default UploadProductFilesContextProvider;
