import React, {createContext, useState} from 'react';
import DeliveryPersonService from "../services/DeliveryPersonService";
import confirmationModal from "../components/common/ConfirmationModal";
import Notification from "../components/common/Notification";

export const DeliveryPersonContext = createContext("DeliveryPersonContext");

const DeliveryPersonContextProvider = ({children}) => {

    const [loading, setLoading] = useState(true);
    const [errorMsg, setErrorMsg] = useState('');
    const [deliveryPersons, setDeliveryPersons] = useState([]);
    const [deliveryPerson, setDeliveryPerson] = useState(null);
    const [profileLoading, setProfileLoading] = useState(false);
    const [totalElements, setTotalElements] = useState(0);
    const [pagination, setPagination] = useState({
        total: 0,
        current: 1,
        pageSize: 10,
        position: "top",
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ["10", "30", "50", "100"]
    });

    async function getAllDeliveryPersons({page, size, id, name, email, primaryPhone, areaName}) {

        try {
            setLoading(true);
            const res = await DeliveryPersonService.getAllDeliveryPersons(page - 1, size, id, name, email, primaryPhone, areaName);
            setPagination({...pagination, total: res.data.totalElements, current: page, pageSize: size});
            setDeliveryPersons(res.data.content)
            setTotalElements(res.data.totalElements);
            setLoading(false)
            return res.data.content;
        } catch (error) {
            setLoading(false);
            console.log("error", error)
            return false;
        }

    }


    async function addDeliveryPerson(data) {

        try {
            const res = await DeliveryPersonService.addDeliveryPerson(data)
            setDeliveryPerson(res.data);
            confirmationModal("Delivery Person created successfully!", "success");
            return res.data;
        } catch (err) {
            const message = err.response ? err.response.data.message : err.message;
            console.log("post deliveryPerson error", message);
            confirmationModal("Delivery Person create failed!", "error");
            return null;
        }

    }

    async function getDeliveryPersonById(id) {

        try {
            setLoading(true);

            setErrorMsg('');
            const res = await DeliveryPersonService.getDeliveryPersonById(id);
            setDeliveryPerson(res.data);
            setLoading(false);
            return 200;
        } catch (error) {
            setLoading(false);
            setErrorMsg("Can not load delivery person. Please check the console.");
            console.log("error", error)
            return error.response ? error.response.status : 500;
        }

    }

    async function getDeliveryPersonProfile() {
        try {
            setProfileLoading(true);
            const res = await DeliveryPersonService.getProfile();
            setDeliveryPerson(res.data);
            setProfileLoading(false);
            return true;
        } catch (error) {
            setProfileLoading(false);
            const message = error.response ? error.response.data.message : error.message;
            console.log("Get profile error", message);
            return false;
        }
    }

    async function updateDeliveryPerson(data, id) {
        try {
            const res = await DeliveryPersonService.updateDeliveryPerson(data, id);
            confirmationModal("Delivery Person updated successfully!", "success");
            return res.data;
        } catch (error) {
            // const message = error.response ? error.response.data.message : error.message;
            confirmationModal("Delivery Person update failed!", "error");
            return null;
        }

    }

    async function deleteDeliveryPerson(id) {

        try {
            await DeliveryPersonService.deleteDeliveryPerson(id);
            let updatedDeliveryPersons;
            updatedDeliveryPersons = deliveryPersons.filter(e => e.id !== id)
            setDeliveryPersons(updatedDeliveryPersons);

            Notification("success", "DELETED", `ID: ${id}, Delivery Person Deleted Successfully`);

            return true;
        } catch(error) {
            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            Notification("error", "ERROR", `${_error}`);
            return false;
        }

    }

    const changeDeliveryPersonStatus = async (deliveryPersonId, status) => {

        try {
            await DeliveryPersonService.changeDeliveryPersonStatus(deliveryPersonId, status);
            return true;
        } catch(error) {
            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            Notification("error", "ERROR", `${_error}`);
            return false;
        }

    }


    return (
        <DeliveryPersonContext.Provider
            value={{
                loading,
                errorMsg,
                pagination,
                totalElements,
                deliveryPersons,
                deliveryPerson,
                getAllDeliveryPersons,
                getDeliveryPersonById,
                profileLoading,
                updateDeliveryPerson,
                deleteDeliveryPerson,
                addDeliveryPerson,
                getDeliveryPersonProfile,
                changeDeliveryPersonStatus,
            }}
        >
            {children}
        </DeliveryPersonContext.Provider>
    );
}

export default DeliveryPersonContextProvider;