import * as URL from "../helpers/Constants";
import axios from "axios";

export default class GlobalConfigService {
    static getAllGlobalConfigs(page, size, id) {
        return axios.get(URL.GET_ALL_GLOBAL_CONFIGS, {
            ...GlobalConfigService.getAuthHeader(),
            params: {page, size, id}
        });
    }

    static getAllActiveGlobalConfigs() {
        return axios.get(URL.GET_ALL_ACTIVE_GLOBAL_CONFIGS, GlobalConfigService.getAuthHeader());
    }

    static getGlobalConfigById(id) {
        return axios.get(`${URL.GET_GLOBAL_CONFIG_BY_ID}/${id}`, GlobalConfigService.getAuthHeader());
    }

    static addGlobalConfig(data) {
        return axios.post(URL.POST_GLOBAL_CONFIG, data, GlobalConfigService.getAuthHeader());
    }

    static updateGlobalConfig(data, id) {
        return axios.put(`${URL.UPDATE_GLOBAL_CONFIG}/${id}`, data, GlobalConfigService.getAuthHeader());
    }

    static deleteGlobalConfig(id) {
        return axios.delete(`${URL.DELETE_GLOBAL_CONFIG}/${id}`, GlobalConfigService.getAuthHeader());
    }

    static getAuthHeader() {
        const accessToken = localStorage.getItem("oushodsheba_access_token");
        const options = {headers: {"Authorization": `Bearer ${accessToken}`}}
        return options;
    }

    // static changeGlobalConfigStatus(email, status) {
    //     return axios.post(`${URL.UPDATE_GLOBAL_CONFIG_STATUS}/${email}?status=${status}`)
    // }
}