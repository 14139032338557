import React, {createContext, useState} from 'react';
import NotificationService from "../services/NotificationService";
import confirmationModal from "../components/common/ConfirmationModal";

export const NotificationContext = createContext("NotificationContext");

const NotificationContextProvider = ({children}) => {

    const [notifications, setNotifications] = useState(null);
    const [notification, setNotification] = useState(null);
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [totalElements, setTotalElements] = useState(0);

    async function getAllNotifications({page, size, id, title, notificationType}) {

        try {
            setLoading(true);

            setErrorMsg('');
            const res = await NotificationService.getAllNotifications(page, size, id, title, notificationType);
            setNotifications(res.data.content);
            setTotalElements(res.data.totalElements);
            setLoading(false);
            return res.data.content;
        } catch (error) {
            setLoading(false);
            setErrorMsg("Can not load Notifications. Please check the console.");
            console.log("error", error)
            return false;
        }

    }

    async function getNotificationById(id) {

        try {
            setLoading(true);
            setErrorMsg('');
            const res = await NotificationService.getNotificationById(id);
            setNotification(res.data);
            setLoading(false);
            return res.data;
        } catch (error) {
            setLoading(false);
            setErrorMsg("Can not load Notifications. Please check the console.");
            console.log("error", error)
            return error.response ? error.response.status : 500;
        }

    }

    async function addNotification(data) {

        try {
            setErrorMsg('');
            setLoading(true);
            const res = await NotificationService.addNotification(data)
            setNotification(res.data);
            setLoading(false);
            confirmationModal("Notification saved successfully.", "success");
            return true;
        } catch (error) {
            const message = error.response ? error.response.data.message : error.message;
            setErrorMsg("Can not add Notifications. Please check the console.");
            console.log("post notification error", message);
            confirmationModal("Can not save Notification. " + message, "error");
            return false;
        }

    }

    async function updateNotification(DATA, id) {
        try {
            setLoading(true);
            setErrorMsg('');
            const data = await NotificationService.updateNotification(DATA, id);
            let updatedNotifications = notifications;
            updatedNotifications = notifications.map(e => {
                if (e.id === id) e = data;
                return e;
            })

            setNotifications(updatedNotifications);
            setLoading(false);
            confirmationModal("Notification updated successfully.", "success");
            return true;
        } catch (error) {
            const message = error.response ? error.response.data.message : error.message;
            setErrorMsg("Can not update Notification. Please check the console.");
            confirmationModal("Can not update Notification. " + message, "error");
            console.log("Notification update error.", error);
            return false;
        }

    }

    async function deleteNotification(id) {

        if (!window.confirm("Are you sure you want to delete this Notification ?")) return;

        try {
            setLoading(true);
            setErrorMsg('');
            await NotificationService.deleteNotification(id);
            let updatedNotifications = notifications;
            updatedNotifications = notifications.filter(e => e.id !== id);
            setNotifications(updatedNotifications);
            setLoading(false);
            return true;
        } catch (error) {
            setErrorMsg("Can not delete Notifications. Please check the console.");
            console.log("Notification delete error.", error);
            return false;
        }
    }

    return (
        <NotificationContext.Provider
            value={{
                notifications,
                notification,
                totalElements,
                loading,
                errorMsg,
                getAllNotifications,
                updateNotification,
                deleteNotification,
                getNotificationById,
                addNotification
            }}
        >
            {children}
        </NotificationContext.Provider>
    );
}

export default NotificationContextProvider;