import React, {createContext, useState} from "react";
import PMSSaleService from "../../services/pms_services/PMSSaleService";
import PMSCustomerServices from "../../services/pms_services/PMSCustomerServices";
import Notification from "../../components/common/Notification";
import {calculateDiscount, generateLocalId, generateLocalStorageKey} from "../../helpers/Utils";
import {GREEN, RED} from "../../helpers/Constants";
import moment from "moment";

export const PMSSaleContext = createContext("PMSSaleContext");

const PMSSaleContextProvider = ({children}) => {

    const initSale = {
        saleDetailsList: [],
        paidAmount: 0,
        dueAmount: 0,
        totalPurchasePrice: 0,
        totalPrice: 0,
        customer: null,
        paymentType: "CASH",
        cashReceivedAmount: 0,
        cashReceived: false,
        discountAmount: 0,
        discountPercent: 0,
        discountOperation: "PERCENTAGE",
        vat: 0,
        note: "",
        nettingAmount: 0,
        adjustedProfit: 0,
        discountType: null,
        payments: [],
        customerPayment: 0,
        individualDiscount: false
    }

    const localStoredSale = JSON.parse(localStorage.getItem(generateLocalStorageKey("sale")));
    const _saleParkList = JSON.parse(localStorage.getItem(generateLocalStorageKey("sale_parks")));

    const [saleHistoryList, setSaleHistoryList] = useState([]);
    const [totalElements, setTotalElements] = useState(0);
    const [saleHistoryDetails, setSaleHistoryDetails] = useState({
        saleDetailsList: []
    });
    const [saleHistoryViewDetails, setSaleHistoryViewDetails] = useState({
        saleDetailsList: []
    });

    const [saleParkList, setSaleParkList] = useState(_saleParkList ?? []);

    const [loading, setLoading] = useState(false);

    const [loadingSale, setLoadingSale] = useState(false);

    const [errorMsg, setErrorMsg] = useState("");
    const [sale, setSale] = useState(localStoredSale ? localStoredSale : initSale);
    const [saleReport, setSaleReport] = useState({totalSaleAmount: 0, totalDueAmount: 0, grossProfit: 0});

    const refreshSale = () => {
        const _data = localStorage.getItem(generateLocalStorageKey("sale"));
        setSale(_data ? JSON.parse(_data) : initSale);
    }

    const getPaidAmount = (sum, data) => {
        // const discountPercent = sum * data.discountPercent / 100;
        // const tradeDiscount = data.discountAmount;
        //
        // const discount = Math.round((discountPercent) ? discountPercent : tradeDiscount);
        // return Math.round((Math.round(sum) - discount) + getCardCommission(data));

        return getFinalPrice(data);
    }

    const getFinalPrice = (data) => {

        let totalPrice = !data.totalPrice || isNaN(data.totalPrice) ? 0 : parseFloat(data.totalPrice) + getCardCommission(data);

        return Math.round(totalPrice - Math.round(calculateDiscount(data)));
    }

    const getAdjustAdvance = (data) => {

        if (data.customer && data.customer.balance < 0) {

            let advance = Math.abs(data.customer.balance);
            let finalPrice = getFinalPrice(data);

            return Math.min(advance, finalPrice)

        }

        return 0;
    }

    const getRemainingPayment = (data) => {

        const due = Math.round(getFinalPrice(data) - data.paidAmount);

        if (Math.abs(due) === 0) {
            return {
                "title": "",
                "amount": 0,
                "color": "#000000"
            }
        }

        if (due > 0) {
            return {
                "title": "Due",
                "amount": due,
                "color": RED
            }
        }

        return {
            "title": "Advance",
            "amount": due * -1,
            "color": GREEN
        };
    }

    const getCustomerBalance = (data) => {

        const balance = Math.round(data.customer ? data.customer.balance : 0);

        if (Math.abs(balance) === 0) {
            return {
                "title": "Current Balance",
                "color": "#000000",
                "amount": 0
            }
        }

        if (balance > 0) {
            return {
                "title": "Current Balance (Due)",
                "color": RED,
                "amount": balance
            }
        }

        return {
            "title": "Current Balance (Advance)",
            "color": GREEN,
            "amount": balance * -1
        };
    }

    const getCustomerNewBalance = (data) => {

        // const balance = Math.round(data.customer ? (data.customer.balance + (getFinalPrice(data) - data.paidAmount)) : 0);
        const balance = Math.round(data.customer ? ((data.customer.balance + (getFinalPrice(data) - data.paidAmount)) - data.dueAmount) : 0);

        if (Math.abs(balance) === 0) {
            return {
                "title": "New Balance",
                "color": "#000000",
                "amount": 0
            }
        }

        if (balance > 0) {
            return {
                "title": "New Balance (Due)",
                "color": RED,
                "amount": balance
            }
        }

        return {
            "title": "New Balance (Advance)",
            "color": GREEN,
            "amount": balance * -1
        };
    }

    const getProfit = (data) => {
        const profit = data.totalPrice - data.totalProductPrice - calculateDiscount(data);
        return isNaN(profit) ? "" : profit;
    }

    const getCardCommission = (data) => {

        let discount = calculateDiscount(data);

        let _totalPrice = data.totalPrice - discount;

        return parseFloat(((_totalPrice * data.vat) / 100).toFixed(2));
    }

    const createSale = async (pharmacyId, loggedInUserId, data) => {
        try {
            setLoading(true);

            const localId = generateLocalId(pharmacyId, loggedInUserId);
            data.localId = localId;

            if (data.customer && !data.customer.localId) {
                data.customer.localId = localId;
            }

            if (data.customer && !data.customer.phoneNumber.startsWith('+880')) {
                data.customer.phoneNumber = `+88${data.customer.phoneNumber}`
            }

            const res = await PMSSaleService.createSale(pharmacyId, data);

            localStorage.removeItem(generateLocalStorageKey("sale"));
            setSale(initSale)
            Notification("success", "Sold", "Successfully sale created");

            setLoading(false);
            return res.data;
        } catch (error) {
            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            Notification("error", "ERROR", `${_error}`);
            return null;
        }
    }

    const addSale = (data) => {

        const index = sale.saleDetailsList.findIndex(v => v.product.id === data.product.id);


        if (index < 0) {
            sale.saleDetailsList.push(data);

            if (data.discountPercent > 0) {
                sale.individualDiscount = true;
            }

            let _totalPrice = 0;
            let _totalProductPrice = 0;

            sale.saleDetailsList.forEach(value => {
                if (value.quantity > 0 && value.salePrice > 0) {
                    _totalPrice += value.totalSalePrice;
                    _totalProductPrice += value.totalProductPrice;
                }
            });

            const _sale = {
                ...sale,
                totalPrice: Math.round(_totalPrice),
                totalProductPrice: Math.round(_totalProductPrice),
                // discountAmount: 0,
                // discountPercent: 0
            };

            // _sale.paidAmount = getPaidAmount(_sale.totalPrice, _sale);
            _sale.paidAmount = getFinalPrice(_sale);

            localStorage.setItem(generateLocalStorageKey("sale"), JSON.stringify(_sale));

            setSale(_sale);
        } else {
            sale.saleDetailsList[index] = data;

            let _totalPrice = 0;
            let _totalProductPrice = 0;

            sale.individualDiscount = false;

            sale.saleDetailsList.forEach(value => {
                _totalPrice += value.totalSalePrice;
                _totalProductPrice += value.totalProductPrice;

                if (value.discountPercent > 0) {
                    sale.individualDiscount = true;
                }
            });
            const _sale = {
                ...sale,
                totalPrice: Math.round(_totalPrice),
                totalProductPrice: Math.round(_totalProductPrice)
            };

            _sale.paidAmount = getPaidAmount(_sale.totalPrice, _sale);

            localStorage.setItem(generateLocalStorageKey("sale"), JSON.stringify(_sale));

            setSale(_sale);
        }
    }

    const updateSaleHistoryByKey = (key, value, extra) => {

        let _saleHistoryDetails = {...saleHistoryDetails, [key]: value, ...extra}

        if (key === "customer") {
            if (value.discountPercent && value.discountPercent > 0) {
                _saleHistoryDetails.discountPercent = value.discountPercent;
                _saleHistoryDetails.discountOperation = "PERCENTAGE";
                _saleHistoryDetails.discountType = "PERCENTAGE";
            }
        } else {
            _saleHistoryDetails.discountType = _saleHistoryDetails.discountOperation;

        }

        // _saleHistoryDetails.paidAmount = getPaidAmount(_saleHistoryDetails.totalPrice, _saleHistoryDetails);

        // if (_saleHistoryDetails.payments && _saleHistoryDetails.payments.length > 0) {
        //     const totalPayment = _saleHistoryDetails.payments.reduce((acc, value) => acc + value.amount, 0);
        //     _saleHistoryDetails.paidAmount = totalPayment;
        // }

        if (_saleHistoryDetails.payments && _saleHistoryDetails.payments.length > 0) {
            const totalPayment = _saleHistoryDetails.payments.reduce((acc, value) => acc + value.amount, 0);
            _saleHistoryDetails.paidAmount = totalPayment;
        } else if (key === "discountAmount" || key === "discountPercent" || key === "vat" || key === "customer") {
            _saleHistoryDetails.paidAmount = getFinalPrice(_saleHistoryDetails);
        }

        if (key === "discountOperation") {
            _saleHistoryDetails.discountAmount = 0;
            _saleHistoryDetails.discountPercent = 0;
            _saleHistoryDetails.paidAmount = _saleHistoryDetails.totalPrice;
        }

        setSaleHistoryDetails(_saleHistoryDetails);
    }

    const updateSaleByKey = (key, value, extra) => {

        let _sale = {...sale, [key]: value, ...extra}


        if (key === "customer") {
            if (value.discountPercent && value.discountPercent > 0) {
                _sale.discountPercent = value.discountPercent;
                _sale.discountOperation = "PERCENTAGE";
                _sale.discountType = "PERCENTAGE";
            }
        } else {
            _sale.discountType = _sale.discountOperation;
        }

        if (_sale.payments.length > 0) {
            const totalPayment = _sale.payments.reduce((acc, value) => acc + value.amount, 0);
            _sale.paidAmount = totalPayment;
        } else if (key === "discountAmount" || key === "discountPercent" || key === "vat" || key === "customer") {
            _sale.paidAmount = getFinalPrice(_sale);
        }

        if (key === "discountOperation") {
            _sale.discountAmount = 0;
            _sale.discountPercent = 0;

            _sale.paidAmount = _sale.totalPrice;
        }

        localStorage.setItem(generateLocalStorageKey("sale"), JSON.stringify(_sale));
        setSale({..._sale});
    }

    const deleteSale = id => {

        sale.saleDetailsList = sale.saleDetailsList.filter(saleDetails => saleDetails.product.id !== id);

        let _totalPrice = 0;
        let _totalProductPrice = 0;

        sale.individualDiscount = false;
        sale.saleDetailsList.forEach(value => {
            _totalPrice += value.totalSalePrice;
            _totalProductPrice += value.totalProductPrice;
            if (value.discountPercent > 0) {
                sale.individualDiscount = true;
            }
        });

        if (sale.saleDetailsList.length <= 0) {
            sale.vat = 0;
            sale.paidAmount = 0;
            sale.discountOperation = "PERCENTAGE";
        }

        const _sale = {
            ...sale,
            totalPrice: Math.round(_totalPrice),
            totalProductPrice: Math.round(_totalProductPrice),
        };

        _sale.paidAmount = getPaidAmount(_sale.totalPrice, _sale);
        if (_sale.saleDetailsList.length === 0) {
            _sale.discountPercent = 0;
        }

        localStorage.setItem(generateLocalStorageKey("sale"), JSON.stringify(_sale));
        setSale(_sale);

    }

    const addSaleRecord = (data) => {

        const index = saleHistoryDetails.saleDetailsList.findIndex(v => v.product.id === data.product.id);

        if (index < 0) {
            saleHistoryDetails.saleDetailsList.push(data);

            if (data.discountPercent > 0) {
                saleHistoryDetails.individualDiscount = true;
            }

            let _totalPrice = 0;
            let _totalProductPrice = 0;

            saleHistoryDetails.saleDetailsList.forEach(value => {
                if (value.quantity > 0 && value.salePrice > 0) {
                    _totalPrice += value.totalSalePrice;
                    _totalProductPrice += value.totalProductPrice;
                }
            });

            const _sale = {
                ...saleHistoryDetails,
                totalPrice: Math.round(_totalPrice),
                totalProductPrice: Math.round(_totalProductPrice)
            };

            _sale.paidAmount = getPaidAmount(_sale.totalPrice, _sale);

            setSaleHistoryDetails(_sale);
        } else {
            let _totalPrice = 0;
            let _totalProductPrice = 0;

            saleHistoryDetails.saleDetailsList[index] = data;

            saleHistoryDetails.individualDiscount = false;
            saleHistoryDetails.saleDetailsList.forEach(value => {
                if (value.quantity > 0 && value.salePrice > 0) {
                    _totalPrice += value.totalSalePrice;
                    _totalProductPrice += value.totalProductPrice;
                }
                if (value.discountPercent > 0) {
                    saleHistoryDetails.individualDiscount = true;
                }
            });

            const _editedSaleHistory = {
                ...saleHistoryDetails,
                totalPrice: Math.round(_totalPrice),
                totalProductPrice: Math.round(_totalProductPrice)
            };

            _editedSaleHistory.paidAmount = getPaidAmount(_editedSaleHistory.totalPrice, _editedSaleHistory);

            setSaleHistoryDetails(_editedSaleHistory);
        }
    }


    const deleteSaleHistoryDetails = id => {

        saleHistoryDetails.saleDetailsList = saleHistoryDetails.saleDetailsList.filter(saleDetails => saleDetails.product.id !== id);

        let _totalPrice = 0;
        let _totalProductPrice = 0;

        saleHistoryDetails.saleDetailsList.forEach(value => {
            _totalPrice += value.totalSalePrice;
            _totalProductPrice += value.totalProductPrice;
        });

        if (saleHistoryDetails.saleDetailsList.length <= 0) {
            saleHistoryDetails.vat = 0;
            saleHistoryDetails.paidAmount = 0;
        }

        const _saleHistoryDetails = {
            ...saleHistoryDetails,
            totalPrice: Math.round(_totalPrice),
            totalProductPrice: Math.round(_totalProductPrice)
        };

        _saleHistoryDetails.paidAmount = getPaidAmount(_saleHistoryDetails.totalPrice, _saleHistoryDetails);
        if (_saleHistoryDetails.saleDetailsList.length === 0) {
            _saleHistoryDetails.discountPercent = 0;
        }

        setSaleHistoryDetails(_saleHistoryDetails);
    }

    const getSaleHistoryListByPharmacyId = async (id, params) => {

        try {
            setLoading(true);
            setErrorMsg('');

            const res = await PMSSaleService.getSaleHistoryListByPharmacyId(id, params);

            setSaleReport({
                totalSaleAmount: res.headers.total,
                totalDueAmount: res.headers.due,
                grossProfit: res.headers.profit
            });
            setSaleHistoryList(res.data.content);
            setTotalElements(res.data.totalElements);
            setLoading(false);
            return res.data.content;
        } catch (error) {
            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }

    }

    const getSaleById = async (pharmacyId, id) => {

        try {
            setLoadingSale(true);
            setErrorMsg('');
            const res = await PMSSaleService.getSaleById(pharmacyId, id);

            const _data = res.data;

            _data.paymentType = _data.paymentType.name;

            if (_data.customer) {
                const customer = await PMSCustomerServices.getCustomerByPharmacyIdByLocalId(pharmacyId, _data.customer.localId);
                _data.customer = customer.data;
            }

            _data.discountOperation = _data.discountType;

            let sum = 0;
            _data.individualDiscount = false;
            _data.saleDetailsList.map(details => {

                // const discount = (details.discountPercent || details.autoDiscountPercent) / 100;

                details.totalSalePrice = (details.salePrice * details.quantity);
                // details.totalSalePrice = (details.salePrice - (discount > 0 ? details.salePrice * discount : details.salePrice)) * details.quantity;
                sum += details.totalProductPrice;

                if (!_data.individualDiscount && details.discountPercent) {
                    _data.individualDiscount = true;
                }

                return details;
            })

            if (_data.payments) {
                _data.payments = _data.payments.map(value => {
                    return {...value, paymentType: value.paymentType.name}
                });
            }

            _data.totalProductPrice = Math.round(sum);

            setSaleHistoryDetails(_data);
            setLoadingSale(false);

            return res.data;
        } catch (error) {
            setLoadingSale(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }

    const getSaleHistoryViewById = async (pharmacyId, id) => {

        try {
            setLoading(true);
            setErrorMsg('');
            const res = await PMSSaleService.getSaleById(pharmacyId, id);
            setSaleHistoryViewDetails(res.data);
            setLoading(false);
            return res.data;
        } catch (error) {
            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }

    const editSale = async (id, pharmacyId, data, loggedInUserId) => {

        try {
            setLoading(true);
            setErrorMsg("");

            const localId = generateLocalId(pharmacyId, loggedInUserId);
            data.localId = localId;

            if (data.customer && !data.customer.localId) {
                data.customer.localId = localId;
            }

            await PMSSaleService.editSale(id, pharmacyId, data);
            Notification("success", "Sale Updated", "Sale updated successfully");

            setLoading(false);
            return true;
        } catch (error) {

            console.log(error)

            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }

            Notification("error", "ERROR", `${_error}`)
            return false;
        }

    }

    const cancelSale = async (id, pharmacyId, data) => {

        try {
            setLoading(true);
            setErrorMsg("");

            await PMSSaleService.cancelSale(id, pharmacyId, data);

            Notification("success", "Sale Canceled", "Sale canceled successfully");

            setLoading(false);
            return true;
        } catch (error) {

            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }

            Notification("error", "ERROR", `${_error}`)
            return false;
        }

    }

    const removeSaleHistoryCustomer = () => {
        const _saleHistory = {...saleHistoryDetails, customer: null, customerPayment: 0};
        setSaleHistoryDetails(_saleHistory);
    }

    const removeCustomer = () => {
        const _sale = {...sale, customer: null, customerPayment: 0};
        // const _sale = {
        //     ...sale,
        //     customer: null,
        //     customerPayment: 0,
        //     totalPrice: 0,
        //     discountPercent: 0,
        //     discountAmount: 0,
        //     totalProductPrice: 0,
        //     discountType: "PERCENTAGE"
        // };
        localStorage.setItem(generateLocalStorageKey("sale"), JSON.stringify(_sale));
        setSale(_sale);
    }

    const removeSaleDetails = () => {
        const _sale = {
            ...sale,
            customer: null,
            customerPayment: 0,
            totalPrice: 0,
            discountPercent: 0,
            discountAmount: 0,
            totalProductPrice: 0,
            discountType: "PERCENTAGE"
        };
        localStorage.setItem(generateLocalStorageKey("sale"), JSON.stringify(_sale));
        setSale(_sale);
    }

    const removeSaleDetailsRecord = () => {
        const _saleHistoryDetails = {
            ...saleHistoryDetails,
            customer: null,
            customerPayment: 0,
            totalPrice: 0,
            discountPercent: 0,
            discountAmount: 0,
            totalProductPrice: 0,
            discountType: "PERCENTAGE"
        };
        setSaleHistoryDetails(_saleHistoryDetails);
    }

    const addSaleHistoryToSale = data => {

        let _individualDiscount = false;

        const _saleDetailsList = data.saleDetailsList.map(saleDetails => {

            saleDetails.box = saleDetails.quantity / saleDetails.product.quantityInBox;
            saleDetails.quantityInBox = saleDetails.product.quantityInBox;
            saleDetails.saleBoxPrice = saleDetails.product.saleBoxPrice;
            if (!_individualDiscount && saleDetails.discountPercent) {
                _individualDiscount = true;
            }

            return saleDetails;
        });

        const _data = {
            saleDetailsList: _saleDetailsList,
            adjustedProfit: data.adjustedProfit,
            cashReceived: data.cashReceived,
            cashReceivedAmount: data.cashReceivedAmount,
            customer: data.customer,
            customerPayment: data.customer,
            discountAmount: data.discountAmount,
            discountPercent: data.discountPercent,
            discountType: data.discountType,
            discountOperation: data.discountType,
            dueAmount: data.dueAmount,
            individualDiscount: _individualDiscount,
            nettingAmount: data.nettingAmount,
            note: data.note,
            paidAmount: data.paidAmount,
            paymentType: data.paymentType,
            payments: data.payments,
            totalPrice: data.totalPrice,
            totalProductPrice: data.totalProductPrice,
            totalPurchasePrice: data.totalPurchasePrice,
            vat: data.vat,
        }

        setSale(_data);
        localStorage.setItem(generateLocalStorageKey("sale"), JSON.stringify(_data));

    }

    const addToSalePark = () => {

        if (sale.saleDetailsList.length < 1) return;

        const _sale = sale;
        const parkTime = moment();
        const _data = [...saleParkList, {..._sale, parkTime}];

        setSaleParkList(_data);
        localStorage.setItem(generateLocalStorageKey("sale_parks"), JSON.stringify(_data));
        setSale(initSale);
        localStorage.removeItem(generateLocalStorageKey("sale"));
    }

    const parkToSale = index => {

        let parkAbleData = null;
        if (sale.saleDetailsList.length > 0) {
            parkAbleData = sale;
        }

        const _data = saleParkList[index];
        const _list = saleParkList.filter((value, index1) => index1 !== index);
        const parkTime = moment();

        setSaleParkList(parkAbleData ? [..._list, {...parkAbleData, parkTime}] : _list);
        localStorage.setItem(generateLocalStorageKey("sale_parks"), JSON.stringify(_list));
        setSale(_data);
        localStorage.setItem(generateLocalStorageKey("sale"), JSON.stringify(_data));
    }

    const removePark = removeIndex => {
        const parkList = saleParkList.filter((v, index) => index !== removeIndex);
        localStorage.setItem(generateLocalStorageKey("sale_parks"), JSON.stringify(parkList));
        setSaleParkList(parkList);
    }

    return (
        <PMSSaleContext.Provider
            value={{
                loading,
                loadingSale,
                errorMsg,
                saleHistoryList,
                saleHistoryDetails,
                totalElements,
                sale,
                saleHistoryViewDetails,
                saleReport,
                saleParkList,
                createSale,
                addSale,
                addSaleRecord,
                addToSalePark,
                parkToSale,
                getSaleHistoryListByPharmacyId,
                getSaleById,
                getFinalPrice,
                getAdjustAdvance,
                getRemainingPayment,
                getCustomerBalance,
                getCustomerNewBalance,
                getProfit,
                getCardCommission,
                getSaleHistoryViewById,
                editSale,
                updateSaleByKey,
                updateSaleHistoryByKey,
                deleteSale,
                deleteSaleHistoryDetails,
                removeSaleHistoryCustomer,
                removeCustomer,
                cancelSale,
                removePark,
                refreshSale,
                addSaleHistoryToSale,
                removeSaleDetails,
                removeSaleDetailsRecord
            }}
        >
            {children}
        </PMSSaleContext.Provider>
    )
}

export default PMSSaleContextProvider;
