import React, {createContext, useState} from "react";
import PMSInventoryServices from "../../services/pms_services/PMSInventoryServices";
import Notification from "../../components/common/Notification";
import {generateLocalStorageKey} from "../../helpers/Utils";
import moment from "moment";
import * as _ from "lodash";
import {INVENTORY_KEY_3, PURCHASE_PARK_KEY_3} from "../../helpers/Constants";

export const PMSInventoryContext3 = createContext("PMSInventoryContext3");

const PMSInventoryContextProvider3 = ({children}) => {

    const initInventoryData = {
        actionType: "INVENTORY_PURCHASED",
        inventoryDetailsList: [],
        paidAmount: 0,
        vendor: null,
        totalProductPrice: 0,
        discountType: null,
        discountPercent: 0,
        tradeDiscount: 0,
        voucherNumber: ""
    };

    const localStoredInventory = JSON.parse(localStorage.getItem(generateLocalStorageKey(INVENTORY_KEY_3)));
    const _purchaseParkList = JSON.parse(localStorage.getItem(generateLocalStorageKey(PURCHASE_PARK_KEY_3)));

    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [totalElements, setTotalElements] = useState(0);
    const [inventoryList, setInventoryList] = useState([]);
    const [inventoryReport, setInventoryReport] = useState({
        totalPurchasePrice: 0,
        totalPaidAmount: 0,
        totalDueAmount: 0
    });
    const [editedInventory, setEditedInventory] = useState({
        inventoryDetailsList: []
    });

    const [purchaseParkList, setPurchaseParkList] = useState(_purchaseParkList ?? []);

    const [inventory, setInventory] = useState(
        localStoredInventory
            ? localStoredInventory
            : initInventoryData
    );

    const getPaidAmount = (sum, inventory) => {
        const discountPercent = sum * inventory.discountPercent / 100;
        const tradeDiscount = inventory.tradeDiscount;

        const discount = Math.round((discountPercent) ? discountPercent : tradeDiscount);
        return Math.round((Math.round(sum) - discount));
    }

    const refreshInventory = () => {
        const _data = localStorage.getItem(generateLocalStorageKey(INVENTORY_KEY_3));
        setInventory(_data ? JSON.parse(_data) : initInventoryData);
    }

    const getInventories = async (params) => {
        try {
            setLoading(true);
            setErrorMsg("");

            const res = await PMSInventoryServices.getInventories(params);

            setInventoryReport({
                totalPurchasePrice: res.headers.total,
                totalPaidAmount: res.headers.paid,
                totalDueAmount: res.headers.due
            });
            setInventoryList(res.data.content);
            setTotalElements(res.data.totalElements);
            setLoading(false);

            return true;
        } catch (error) {
            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    };

    const getInventoryById = async (id) => {
        try {
            setLoading(true);

            const res = await PMSInventoryServices.getInventoryById(id);
            const _data = res.data;

            _data.actionType = _data.actionType.name;

            setLoading(false);

            _data.inventoryDetailsList.map(details => {

                details.quantityInBox = details.product.quantityInBox;
                details.box = details.quantity / details.product.quantityInBox;
                details.unitType = details.product.unitType ? details.product.unitType : "PIECE";

                return details;
            });

            setEditedInventory(_data);

            return true;
        } catch (error) {

            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    };

    const createInventory = async (pharmacyId, data) => {
        try {
            setLoading(true);
            await PMSInventoryServices.createInventory(pharmacyId, data);
            localStorage.removeItem(generateLocalStorageKey(INVENTORY_KEY_3));
            setInventory(initInventoryData);

            Notification("success", "Purchased", "Purchase create successfully");

            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }

    const addInventory = data => {

        const index = inventory.inventoryDetailsList.findIndex(v => v.product.id === data.product.id);

        if (index < 0) {
            inventory.inventoryDetailsList.push(data);

            let sum = 0;

            inventory.inventoryDetailsList.forEach(value => {

                if (value.quantityInBox > 0 && value.box > 0) {
                    sum += value.totalProductPrice;
                }

            });

            inventory.totalProductPrice = Math.round(sum);
            inventory.paidAmount = getPaidAmount(sum, inventory);

            const _inventory = {...inventory}
            localStorage.setItem(generateLocalStorageKey(INVENTORY_KEY_3), JSON.stringify(_inventory));
            setInventory(_inventory);
        } else {
            inventory.inventoryDetailsList[index] = data;

            let sum = 0;
            inventory.inventoryDetailsList.forEach(value => sum += value.totalProductPrice);

            inventory.paidAmount = getPaidAmount(sum, inventory);

            const _inventory = {...inventory, totalProductPrice: Math.round(sum)};

            localStorage.setItem(generateLocalStorageKey(INVENTORY_KEY_3), JSON.stringify(_inventory));
            setInventory(_inventory);
        }
    }

    const updateInventory = (data, index) => {

        inventory.inventoryDetailsList[index] = data;

        let sum = 0;
        inventory.inventoryDetailsList.forEach(value => sum += value.totalProductPrice);
        const _inventory = {...inventory, totalProductPrice: Math.round(sum)};

        localStorage.setItem(generateLocalStorageKey(INVENTORY_KEY_3), JSON.stringify(_inventory));
        setInventory(_inventory);
    }

    const updateInventoryByKey = (key, value, extra) => {

        let _inventory = {...inventory, [key]: value, ...extra}

        if (key === "paidAmount") {
            inventory.paidAmount = value;
            _inventory = {...inventory}
        }

        if (key === "discountOperation") {
            inventory.tradeDiscount = 0;
            inventory.discountPercent = 0;

            inventory.paidAmount = inventory.totalProductPrice;

            _inventory = {...inventory}
        }

        if (_inventory.tradeDiscount > 0) {
            _inventory.discountType = "FLAT"
        }

        if (_inventory.discountPercent > 0) {
            _inventory.discountType = "PERCENTAGE"
        }

        localStorage.setItem(generateLocalStorageKey(INVENTORY_KEY_3), JSON.stringify(_inventory));
        setInventory({..._inventory});
    }

    const deletePurchase = id => {

        inventory.inventoryDetailsList = inventory.inventoryDetailsList.filter(inventoryDetails => inventoryDetails.product.id !== id);

        let sum = 0;
        inventory.inventoryDetailsList.forEach(value => sum += value.totalProductPrice);
        inventory.totalProductPrice = Math.round(sum);

        inventory.paidAmount = getPaidAmount(sum, inventory);

        if (inventory && inventory.inventoryDetailsList.length === 0) {
            inventory.discountPercent = 0;
            inventory.tradeDiscount = 0;
        }

        const _inventory = {...inventory}

        if (_inventory.inventoryDetailsList.length > 0) {
            localStorage.setItem(generateLocalStorageKey(INVENTORY_KEY_3), JSON.stringify(_inventory));
            setInventory(_inventory);
        } else {
            localStorage.removeItem(generateLocalStorageKey(INVENTORY_KEY_3));
            setInventory({...initInventoryData})
        }
    }

    const editInventoryHistory = async (id, pharmacyId, data, params) => {

        try {

            setLoading(true);
            await PMSInventoryServices.editInventory(id, pharmacyId, data, params);
            if (params && params.isCanceled === true) {
                Notification("success", "Inventory Canceled", "Inventory canceled successfully");
            } else {
                Notification("success", "Inventory Updated", "Inventory updated successfully");
            }
            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }

            Notification("error", "ERROR", `${_error}`)
            return false;
        }

    }

    const addPurchaseRecord = (data) => {

        const index = editedInventory.inventoryDetailsList.findIndex(v => v.product.id === data.product.id);

        if (index < 0) {
            editedInventory.inventoryDetailsList.push(data);
        } else {
            editedInventory.inventoryDetailsList[index] = data;
        }

        let sum = 0;
        editedInventory.inventoryDetailsList.forEach(value => sum += value.totalProductPrice);
        editedInventory.totalProductPrice = Math.round(sum);

        const _editedInventory = {...editedInventory};

        setEditedInventory(_editedInventory);
    }

    const deletePurchaseRecord = id => {

        editedInventory.inventoryDetailsList = editedInventory.inventoryDetailsList.filter(inventoryDetails => inventoryDetails.product.id !== id);

        let sum = 0;

        editedInventory.inventoryDetailsList.forEach(value => sum += value.totalProductPrice);
        editedInventory.totalProductPrice = Math.round(sum);

        if (editedInventory && editedInventory.inventoryDetailsList.length === 0) {
            editedInventory.discountPercent = 0;
            editedInventory.tradeDiscount = 0;
        }

        const _editedInventory = {...editedInventory};

        setEditedInventory(_editedInventory);
    }

    const updateInventoryHistoryByKey = (key, value, extra) => {

        let _editedInventory = {...editedInventory, [key]: value, ...extra}
        if (key === "discountOperation") {
            editedInventory.tradeDiscount = 0;
            editedInventory.discountPercent = 0;
            _editedInventory = {...editedInventory}
        }

        if (_editedInventory.tradeDiscount > 0) {
            _editedInventory.discountType = "FLAT"
        }

        if (_editedInventory.discountPercent > 0) {
            _editedInventory.discountType = "PERCENTAGE"
        }

        setEditedInventory({..._editedInventory});
    }

    const removeVendorByKey = (key, value) => {
        if (key === "vendor") {
            inventory[key] = null;
        } else {
            inventory[key] = value;
        }
        localStorage.setItem(generateLocalStorageKey(INVENTORY_KEY_3), JSON.stringify(inventory));
        setInventory({...inventory});
    }

    const removeEditedInventoryVendor = (key) => {

        editedInventory[key] = null;
        setEditedInventory({...editedInventory});
    }

    const addRequisitionToInventory = (data) => {

        let totalCost = 0;

        const detailsList = data.requisitionDetailsList.map(value => {

            value.productPrice = value.product.productPrice;
            value.quantityInBox = value.product.quantityInBox;
            value.box = value.box ? value.box : value.quantity / value.quantityInBox;

            value.totalProductPrice = value.quantity * value.productPrice;

            const _threshold = _.round(value.box * .10, 0);
            value.product.threshold = _threshold > 0 ? _threshold : 1;

            value.unitType = value.product.unitType ? value.product.unitType : "PIECE";
            value.freeProductQuantity = 0;

            totalCost += value.totalProductPrice;

            return value;
        });

        const _data = {
            actionType: "INVENTORY_PURCHASED",
            inventoryDetailsList: detailsList,
            paidAmount: 0,
            vendor: data.vendor ? data.vendor : null,
            totalProductPrice: Math.round(totalCost),
            tradeDiscount: 0,
            voucherNumber: "",
            requisitionNumber: data.requisitionNumber
        }

        setInventory(_data);

        localStorage.setItem(generateLocalStorageKey(INVENTORY_KEY_3), JSON.stringify(_data));
    }

    const parkPurchase = () => {

        if (inventory.inventoryDetailsList.length < 1) return;

        const _inventory = inventory;
        const parkTime = moment();
        const _data = [...purchaseParkList, {..._inventory, parkTime}];

        setPurchaseParkList(_data);
        localStorage.setItem(generateLocalStorageKey(PURCHASE_PARK_KEY_3), JSON.stringify(_data));
        setInventory(initInventoryData);
        localStorage.removeItem(generateLocalStorageKey(INVENTORY_KEY_3));
    }

    const parkToPurchase = index => {

        let parkAbleData = null;
        if (inventory.inventoryDetailsList.length > 0) {
            parkAbleData = inventory;
        }

        const _data = purchaseParkList[index];
        const _list = purchaseParkList.filter((value, index1) => index1 !== index);
        const parkTime = moment();

        setPurchaseParkList(parkAbleData ? [..._list, {...parkAbleData, parkTime}] : _list);
        localStorage.setItem(generateLocalStorageKey(PURCHASE_PARK_KEY_3), JSON.stringify(_list));
        setInventory(_data);
        localStorage.setItem(generateLocalStorageKey(INVENTORY_KEY_3), JSON.stringify(_data));
    }

    const removePark = removeIndex => {
        const parkList = purchaseParkList.filter((v, index) => index !== removeIndex);
        localStorage.setItem(generateLocalStorageKey(PURCHASE_PARK_KEY_3), JSON.stringify(parkList));
        setPurchaseParkList(parkList);
    }

    return (
        <PMSInventoryContext3.Provider
            value={{
                loading,
                errorMsg,
                inventory,
                totalElements,
                inventoryList,
                editedInventory,
                inventoryReport,
                purchaseParkList,
                createInventory,
                addInventory,
                addPurchaseRecord,
                addRequisitionToInventory,
                parkPurchase,
                parkToPurchase,
                getInventories,
                getInventoryById,
                updateInventory,
                updateInventoryByKey,
                updateInventoryHistoryByKey,
                editInventoryHistory,
                deletePurchase,
                deletePurchaseRecord,
                removeVendorByKey,
                removeEditedInventoryVendor,
                removePark,
                refreshInventory
            }}
        >
            {children}
        </PMSInventoryContext3.Provider>
    )
}

export default PMSInventoryContextProvider3;
