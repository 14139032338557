import * as URL from "../helpers/Constants";
import axios from "axios";

export default class BannerService {
    static getBannerList = params => {
        return axios.get(URL.GET_BANNER_LIST,
            {...BannerService.getAuthHeader(), params});
    }

    static getPharmacyBannerList = params => {
        return axios.get(URL.GET_PHARMACY_BANNER_LIST,
            {...BannerService.getAuthHeader(), params});
    }

    static getBannerById = id => {
        return axios.get(`${URL.GET_BANNER_BY_ID}/${id}`, BannerService.getAuthHeader());
    }

    static addBanner = data => {
        return axios.post(URL.ADD_BANNER,
            data, BannerService.getAuthHeader());
    }

    static editBanner = (id, data) => {
        return axios.put(`${URL.EDIT_BANNER}/${id}`,
            data, BannerService.getAuthHeader());
    }

    static deleteBanner = id => {
        return axios.delete(`${URL.DELETE_BANNER}/${id}`, BannerService.getAuthHeader());
    }

    static getAuthHeader = () => {
        const accessToken = localStorage.getItem("oushodsheba_access_token");
        const options = {headers: {"Authorization": `Bearer ${accessToken}`}}
        return options;
    }
}
