import React, {createContext, useState} from 'react';
import TagService from "../services/TagService";
import confirmationModal from "../components/common/ConfirmationModal";

export const TagContext = createContext("TagContext");

const TagContextProvider = ({children}) => {

    const [tags, setTags] = useState(null);
    const [tag, setTag] = useState(null);
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [totalElements, setTotalElements] = useState(0);

    async function getAllTags({page, size, title}) {

        try {
            setLoading(true);

            setErrorMsg('');
            const res = await TagService.getAllTags(page, size, title);
            setTags(res.data.content);
            setTotalElements(res.data.totalElements);
            setLoading(false);
            return res.data.content;
        } catch (error) {
            setLoading(false);
            setErrorMsg("Can not load Tags. Please check the console.");
            console.log("error", error)
            return false;
        }

    }

    async function getTagById(id) {

        try {
            setLoading(true);

            setErrorMsg('');
            const res = await TagService.getTagById(id);
            setTag(res.data);
            setLoading(false);
            return 200;
        } catch (error) {
            setLoading(false);
            setErrorMsg("Can not load Tags. Please check the console.");
            console.log("error", error)
            return error.response ? error.response.status : 500;
        }

    }

    async function addTag(data) {

        try {
            setErrorMsg('');
            setLoading(true);
            const res = await TagService.addTag(data)
            setTags(tags ? [...tags, res.data] : [res.data]);
            setLoading(false);
            confirmationModal("Tag saved successfully.", "success");
            return true;
        } catch (error) {
            const message = error.response ? error.response.data.message : error.message;
            setErrorMsg("Can not add Tags. Please check the console.");
            console.log("post tag error", message);
            confirmationModal("Can not save Tag. " + message, "error");
            return false;
        }

    }

    async function updateTag(DATA, id) {
        try {
            setLoading(true);
            setErrorMsg('');
            const data = await TagService.updateTag(DATA, id);
            let updatedTags = tags;
            updatedTags = tags.map(e => {
                if (e.id === id) e = data;
                return e;
            })

            setTags(updatedTags);
            setLoading(false);
            confirmationModal("Tag updated successfully.", "success");
            return true;
        } catch (error) {
            const message = error.response ? error.response.data.message : error.message;
            setErrorMsg("Can not update Tag. Please check the console.");
            confirmationModal("Can not update Tag. " + message, "error");
            console.log("Tag update error.", error);
            return false;
        }

    }

    async function deleteTag(id) {

        if (!window.confirm("Are you sure you want to delete this Tag ?")) return;

        try {
            setLoading(true);
            setErrorMsg('');
            await TagService.deleteTag(id);
            let updatedTags = tags;
            updatedTags = tags.filter(e => e.id !== id);
            setTags(updatedTags);
            setLoading(false);
            return true;
        } catch (error) {
            setErrorMsg("Can not delete Tags. Please check the console.");
            console.log("Tag delete error.", error);
            return false;
        }
    }

    return (
        <TagContext.Provider
            value={{
                tags,
                tag,
                totalElements,
                loading,
                errorMsg,
                getAllTags,
                updateTag,
                deleteTag,
                getTagById,
                addTag
            }}
        >
            {children}
        </TagContext.Provider>
    );
}

export default TagContextProvider;