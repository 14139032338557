import * as URL from "../helpers/Constants";
import axios from "axios";

export default class ReviewService {
    static getAllReviews(page, size, id, reviewType) {
        return axios.get(URL.GET_ALL_REVIEWS, {...ReviewService.getAuthHeader(), params: {page, size, id, reviewType}});
    }

    static getReviewById(id) {
        return axios.get(`${URL.GET_REVIEW_BY_ID}/${id}`, ReviewService.getAuthHeader());
    }

    static getAuthHeader() {
        const accessToken = localStorage.getItem("oushodsheba_access_token");
        const options = {headers: {"Authorization": `Bearer ${accessToken}`}}
        return options;
    }
}