import * as URL from "../helpers/Constants";
import axios from "axios";

export default class countryService {
    static getAllCountries(page, size, id, countryName) {
        return axios.get(URL.GET_ALL_COUNTRIES, {
            ...countryService.getAuthHeader(),
            params: {page, size, id, countryName}
        });
    }

    static getCountryById(id) {
        return axios.get(`${URL.GET_COUNTRY_BY_ID}/${id}`, countryService.getAuthHeader());
    }

    static addCountry(data) {
        return axios.post(URL.POST_COUNTRY, data, countryService.getAuthHeader());
    }

    static updateCountry(data, id) {
        return axios.put(`${URL.UPDATE_COUNTRY}/${id}`, data, countryService.getAuthHeader());
    }

    static deleteCountry(id) {
        return axios.delete(`${URL.DELETE_COUNTRY}/${id}`, countryService.getAuthHeader());
    }

    static getAuthHeader() {
        const accessToken = localStorage.getItem("oushodsheba_access_token");
        const options = {headers: {"Authorization": `Bearer ${accessToken}`}}
        return options;
    }

    // static changeCountryStatus(email, status) {
    //     return axios.post(`${URL.UPDATE_COUNTRY_STATUS}/${email}?status=${status}`)
    // }
}