import React from 'react';
import {Redirect, Route, useHistory} from 'react-router-dom';
import {LOGIN_PATH, PMS_LOGIN_PATH} from '../../routes/Slugs';
import {getFullPreviousRoute} from "../../helpers/Utils";

const PrivateRoute = ({isLogin, component: Component, ...rest}) => {

    const history = useHistory();

    const currentDomain = window.location.hostname;

    return (
        <Route
            {...rest}
            render={() => isLogin ?
                <Component/> :
                currentDomain.includes('admin.oushodsheba.com') ?
                    <Redirect to={`${LOGIN_PATH}?previous=${getFullPreviousRoute(history)}`}/> :
                    currentDomain.includes('pharmacy.oushodsheba.com') ?
                        <Redirect to={`${PMS_LOGIN_PATH}?previous=${getFullPreviousRoute(history)}`}/> :
                        <Redirect to={`${PMS_LOGIN_PATH}?previous=${getFullPreviousRoute(history)}`}/>
            }
        />
    );
}

export default PrivateRoute;
