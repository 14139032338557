import * as URL from "../helpers/Constants";
import axios from "axios";

export default class EventService {
    static getAllEvents(page, size, id, method, status, url) {
        return axios.get(URL.GET_ALL_EVENTS, {
            ...EventService.getAuthHeader(),
            params: {page, size, id, method, status, url}
        });
    }

    static getEventById(id) {
        return axios.get(`${URL.GET_EVENT_BY_ID}/${id}`, EventService.getAuthHeader());
    }

    static addEvent(data) {
        return axios.post(URL.POST_EVENT, data, EventService.getAuthHeader());
    }

    static updateEvent(data, id) {
        return axios.put(`${URL.UPDATE_EVENT}/${id}`, data, EventService.getAuthHeader());
    }

    static deleteEvent(id) {
        return axios.delete(`${URL.DELETE_EVENT}/${id}`, EventService.getAuthHeader());
    }

    static getAuthHeader() {
        const accessToken = localStorage.getItem("oushodsheba_access_token");
        const options = {headers: {"Authorization": `Bearer ${accessToken}`}}
        return options;
    }

    // static changeEventStatus(email, status) {
    //     return axios.post(`${URL.UPDATE_GENERIC_STATUS}/${email}?status=${status}`)
    // }
}