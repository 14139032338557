import React, {createContext, useState} from 'react';
import EventService from "../services/EventService";
import confirmationModal from "../components/common/ConfirmationModal";

export const EventContext = createContext("EventContext");


const EventContextProvider = ({children}) => {

    const [events, setEvents] = useState([]);
    const [event, setEvent] = useState(null);
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [totalElements, setTotalElements] = useState(0);

    async function getAllEvents({page, size, id, method, status, url}) {

        try {
            setLoading(true);
            setErrorMsg('');
            const res = await EventService.getAllEvents(page, size, id, method, status, url);
            setEvents(res.data.content)
            setTotalElements(res.data.totalElements);
            setLoading(false);
            return res.data.content;
        } catch (error) {
            setErrorMsg("Can not load Events. Please check the console.");
            console.log("error", console.error());
            return false;
        }

    }

    async function getEventById(id) {

        try {
            setLoading(true);
            setErrorMsg('');
            const res = await EventService.getEventById(id);
            setEvent(res.data);
            setLoading(false);
            return 200;
        } catch (error) {
            setLoading(false);
            setErrorMsg("Can not load Events. Please check the console.");
            console.log("error", error)
            return error.response ? error.response.status : 500;
        }

    }

    async function addEvent(data) {

        try {
            setErrorMsg('');
            setLoading(true);
            const res = await EventService.addEvent(data)
            setEvents(events ? [...events, res.data] : [res.data]);
            setLoading(false);
            confirmationModal("Event saved successfully. ", "success");
            return true;
        } catch (err) {
            const message = err.response ? err.response.data.message : err.message;
            setErrorMsg("Can not add Events. Please check the console.");
            console.log("post event error", message);
            confirmationModal("Can not save Event. " + message, "error");
            return false;
        }

    }

    async function updateEvent(data, id) {
        try {
            setLoading(true);
            setErrorMsg('');
            const res = await EventService.updateEvent(data, id);
            let updatedEvents = events;
            updatedEvents = events.map(e => {
                if (e.id === id) e = res.data;
                return e;
            })

            setEvents(updatedEvents);
            setLoading(false);
            return true;
        } catch (error) {
            setErrorMsg("Can not update Event. Please check the console.");
            console.log("Event update error.", error);
            return false;
        }

    }

    async function deleteEvent(id) {

        if (!window.confirm("Are you sure you want to delete this Event ?")) return;

        try {
            setLoading(true);
            setErrorMsg('');
            await EventService.deleteEvent(id);
            let updatedEvents = events;
            updatedEvents = events.filter(e => e.id !== id);
            setEvents(updatedEvents);
            setLoading(false);
            return true;
        } catch (error) {
            setErrorMsg("Can not delete Events. Please check the console.");
            console.log("Event delete error.", error);
            return false;
        }
    }


    return (
        <EventContext.Provider
            value={{
                events,
                event,
                loading,
                errorMsg,
                totalElements,
                getEventById,
                getAllEvents,
                updateEvent,
                deleteEvent,
                addEvent
            }}
        >
            {children}
        </EventContext.Provider>
    );
}

export default EventContextProvider;