import React, {createContext, useState} from 'react';
import CartService from "../services/CartService";

export const CartContext = createContext("CartContext");


const CartContextProvider = ({children}) => {

    const [carts, setCarts] = useState(null);
    const [cart, setCart] = useState(null);

    async function getAllCarts() {

        try {
            const res = await CartService.getAllCarts()
            setCarts([...res.data])
            return true;
        } catch (error) {
            console.log("Cart loading error !", error)
            return false;
        }

    }

    async function getCartById(id) {

        try {
            const res = await CartService.getCartById(id)
            setCart(res.data);
            return true;
        } catch (error) {
            console.log("Cart loading error !", error)
            return false;
        }

    }

    async function addCart(data) {

        try {
            await CartService.addCart(data)
            setCarts([...carts, data]);
            return true;
        } catch {
            return false;
        }

    }

    async function updateCart(DATA, id) {
        try {
            const data = await CartService.updateCart(DATA, id)
            let updatedCarts = carts;
            updatedCarts = carts.map(e => {
                if (e.id === id) e = data;
                return e;
            })

            setCarts(updatedCarts);
            return true;
        } catch {
            return false;
        }

    }

    return (
        <CartContext.Provider
            value={{
                carts,
                cart,
                getCartById,
                getAllCarts,
                updateCart,
                addCart
            }}
        >
            {children}
        </CartContext.Provider>
    );
}

export default CartContextProvider;