import * as URL from "../helpers/Constants";
import axios from "axios";

export default class drugService {
    static getAllDrugs() {
        return axios.get(URL.GET_ALL_DRUGS, drugService.getAuthHeader());
    }

    static addDrug(data) {
        return axios.post(URL.POST_DRUG, data, drugService.getAuthHeader());
    }

    static updateDrug(data, id) {
        return axios.put(`${URL.UPDATE_DRUG}/${id}`, data, drugService.getAuthHeader());
    }

    static deleteDrug(id) {
        return axios.delete(`${URL.DELETE_DRUG}/${id}`, drugService.getAuthHeader());
    }

    static getAuthHeader() {
        const accessToken = localStorage.getItem("oushodsheba_access_token");
        const options = {headers: {"Authorization": `Bearer ${accessToken}`}}
        return options;
    }

    // static changeDrugStatus(email, status) {
    //     return axios.post(`${URL.UPDATE_DRUG_STATUS}/${email}?status=${status}`)
    // }
}