import * as URL from "../../helpers/Constants";
import axios from "axios";
import { getPharmacyId } from "../../helpers/Utils";

export default class PMSCustomerPaymentServices {
    static getPayments(id, params) {
        return axios.get(`${URL.PMS_GET_ALL_CUSTOMER_PAYMENT_BY_PHARMACY_ID}/${getPharmacyId()}/${id}`,
            {...PMSCustomerPaymentServices.getAuthHeader(), params}
        )
    }

    static createPayment(data) {
        return axios.post(`${URL.PMS_CREATE_CUSTOMER_PAYMENT_BY_PHARMACY_ID}/${getPharmacyId()}`,
            data,
            PMSCustomerPaymentServices.getAuthHeader()
        )
    }

    static cancelPayment(id) {
        return axios.put(`${URL.PMS_CANCEL_CUSTOMER_PAYMENT_BY_ID_BY_PHARMACY_ID}/${id}/${getPharmacyId()}`, null,
            PMSCustomerPaymentServices.getAuthHeader()
        )
    }

    static getAuthHeader() {
        const accessToken = localStorage.getItem("oushodsheba_access_token");
        return {
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
        };
    }
}
