import React, {createContext, useState} from 'react';
import ProductCategoryService from "../services/ProductCategoryService";
import confirmationModal from "../components/common/ConfirmationModal";
import Notification from "../components/common/Notification";

export const ProductCategoryContext = createContext("ProductCategoryContext");

const ProductCategoryContextProvider = ({children}) => {

    const [productCategories, setProductCategories] = useState([]);
    const [productCategory, setProductCategory] = useState(null);
    const [totalElements, setTotalElements] = useState(0);
    const [loading, setLoading] = useState(false);
    const [productLoading, setProductLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');

    const [pmsMedicineTypeList, setPMSMedicineTypeList] = useState([]);

    const getAllPMSMedicineType = async (params) => {

        try {
            setLoading(true);
            setErrorMsg("");
            const res = await ProductCategoryService.getAllPMSMedicineType(params);
            console.log("res - ", res.data.content)
            setPMSMedicineTypeList(res.data.content);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }


    async function getAllProductCategories({page, size, id, title}) {

        try {
            setLoading(true);
            setErrorMsg('');
            const res = await ProductCategoryService.getAllProductCategories(page, size, id, title);
            setTotalElements(res.data.totalElements);
            setProductCategories(res.data.content);
            setLoading(false);
            return res.data.content;
        } catch (error) {
            setLoading(false);
            setErrorMsg("Can not load ProductCategories. Please check the console.");
            console.log("error", console.error())
            return false;
        }

    }

    async function getProductCategoryById(id) {

        try {
            setProductLoading(true);
            setErrorMsg('');
            const res = await ProductCategoryService.getProductCategoryById(id);
            setProductCategory(res.data);
            setProductLoading(false);
            return res.data;
        } catch (error) {
            console.log("here error")
            setProductLoading(false);
            setErrorMsg("Can not load Product Category. Please check the console.");
            console.log("error", error)
            return false;
        }

    }

    async function addProductCategory(data) {

        try {
            setLoading(true);
            // setErrorMsg('');
            const res = await ProductCategoryService.addProductCategory(data)
            setProductCategory(res.data);
            setLoading(false);
            // confirmationModal(`Product Category Id - ${res.data.id} saved successfully !`, "success");
            Notification("success", "Created", `Product Category Id - ${res.data.id} saved successfully !`);
            return true;
        } catch (error) {
            /*setLoading(false);
            const message = err.response ? err.response.data.message : err.message;
            setErrorMsg("Can not add Product Categories. Please check the console.");
            console.log("post product_category error", message);
            confirmationModal("Can not save Product Category. " + message, "error");*/
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            Notification("error", "ERROR", `${_error}`);
            return false;
        }

    }

    async function updateProductCategory(DATA, id) {
        try {
            setLoading(true);
            setErrorMsg('');
            const res = await ProductCategoryService.updateProductCategory(DATA, id);
            let updatedProductCategories = productCategories;
            updatedProductCategories = productCategories.map(e => {
                if (e.id === id) e = res.data;
                return e;
            })

            setProductCategories(updatedProductCategories);
            setLoading(false);
            confirmationModal("Product Category updated successfully !", "success");
            return true;
        } catch (error) {
            setLoading(false);
            setErrorMsg("Can not update Product Category. Please check the console.");
            console.log("Product Category update error.", error);
            return false;
        }

    }

    const setAsHomePage = async (id, homePage) => {
        try {
            setLoading(true);
            const data = await ProductCategoryService.setAsHomepage(id, homePage);
            setProductCategories(productCategories.map(e => {
                if (e.id === id) {
                    e.homePage = homePage;
                }
                return e;
            }))
            setLoading(false);
            Notification("success", "Homepage Updated", "Category home page status updated successfully.");
            return data;
        } catch (error) {
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }

    const setAsHot = async (id, hot) => {
        try {
            setLoading(true);
            const data = await ProductCategoryService.setAsHot(id, hot);
            setProductCategories(productCategories.map(e => {
                if (e.id === id) {
                    e.hot = hot;
                }
                return e;
            }))
            setLoading(false);
            Notification("success", "Hot Updated", "Category hot status updated successfully.");
            return data;
        } catch (error) {
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }

    async function deleteProductCategory(id) {

        if (!window.confirm("Are you sure you want to delete this ProductCategory ?")) return;

        try {
            setLoading(true);
            setErrorMsg('');
            await ProductCategoryService.deleteProductCategory(id);
            let updatedProductCategories = productCategories;
            updatedProductCategories = productCategories.filter(e => e.id !== id);
            setProductCategories(updatedProductCategories);
            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);
            setErrorMsg("Can not delete ProductCategories. Please check the console.");
            console.log("ProductCategory delete error.", error);
            return false;
        }
    }


    return (
        <ProductCategoryContext.Provider
            value={{
                productCategories,
                loading,
                productCategory,
                totalElements,
                errorMsg,
                productLoading,
                getAllProductCategories,
                updateProductCategory,
                deleteProductCategory,
                getProductCategoryById,
                addProductCategory,
                setAsHomePage,
                setAsHot,
                pmsMedicineTypeList,
                getAllPMSMedicineType
            }}
        >
            {children}
        </ProductCategoryContext.Provider>
    );
}

export default ProductCategoryContextProvider;
