import * as URL from "../../helpers/Constants";
import axios from "axios";
import {getPharmacyId} from "../../helpers/Utils";

export default class PMSTransactionServices {
    static getSalesWhichCashNotReceived(params) {
        return axios.get(
            `${URL.PMS_SALES_NOT_CASH_RECEIVED_BY_ID_BY_PHARMACY_ID}/${getPharmacyId()}`, {
                ...PMSTransactionServices.getAuthHeader(),
                params,
            }
        );
    }


    static postSaleWhichCashHasReceived(id, amount, isCashierChecked) {
        return axios.post(
            `${URL.PMS_SALES_CASH_RECEIVED_BY_ID_BY_PHARMACY_ID}/${id}/${getPharmacyId()}`,
            null,
            {
                params: {amount, isCashierChecked},
                ...PMSTransactionServices.getAuthHeader()
            }
        );
    }

    static pmsCashReceivedRefresh(pharmacyId) {
        return axios.post(`${URL.PMS_CASH_RECEIVED_REFRESH}/${pharmacyId}`, null, PMSTransactionServices.getAuthHeader()
        );
    }

    static getAuthHeader() {
        const accessToken = localStorage.getItem("oushodsheba_access_token");
        return {
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
        };
    }
}
