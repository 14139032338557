import React, {createContext, useState} from 'react';
import DeliveryChargeService from "../services/DeliveryChargeService";
import confirmationModal from "../components/common/ConfirmationModal";
import Notification from "../components/common/Notification";

export const DeliveryChargeContext = createContext("DeliveryChargeContext");

const DeliveryChargeContextProvider = ({children}) => {

    const [deliveryCharges, setDeliveryCharges] = useState([]);
    const [deliveryCharge, setDeliveryCharge] = useState({});
    const [loading, setLoading] = useState(false);
    const [updateLoading, setUpdateLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [totalElements, setTotalElements] = useState(0);

    async function getAllDeliveryCharges({page, size, id}) {

        try {
            setLoading(true);

            setErrorMsg('');
            const res = await DeliveryChargeService.getAllDeliveryCharges(page, size, id);
            setDeliveryCharges(res.data.content);
            setTotalElements(res.data.totalElements);
            setLoading(false);
            return res.data.content;
        } catch (error) {
            setLoading(false);
            setErrorMsg("Can not load DeliveryCharges. Please check the console.");
            console.log("error", error)
            return false;
        }

    }

    async function getDeliveryChargeById(id) {

        try {
            setLoading(true);

            setErrorMsg('');
            const res = await DeliveryChargeService.getDeliveryChargeById(id);
            setDeliveryCharge(res.data);
            setLoading(false);
            return 200;
        } catch (error) {
            setLoading(false);
            setErrorMsg("Can not load DeliveryCharges. Please check the console.");
            console.log("error", error)
            return error.response ? error.response.status : 500;
        }

    }

    async function addDeliveryCharge(data) {

        try {
            setErrorMsg('');
            setLoading(true);
            const res = await DeliveryChargeService.addDeliveryCharge(data)
            setDeliveryCharges(deliveryCharges ? [...deliveryCharges, res.data] : [res.data]);
            setLoading(false);
            confirmationModal(`Delivery Charge Id - ${res.data.id} saved successfully!`, "success");
            return true;
        } catch (error) {
            setLoading(false);
            const message = error.response ? error.response.data.message : error.message;
            setErrorMsg("Can not add DeliveryCharges. Please check the console.");
            console.log("post deliveryCharge error", message);
            confirmationModal("Can not save DeliveryCharge. " + message, "error");
            return false;
        }

    }

    async function updateDeliveryCharge(DATA, id) {
        try {
            setUpdateLoading(true);
            setErrorMsg('');
            const res = await DeliveryChargeService.updateDeliveryCharge(DATA, id);
            setDeliveryCharge(res.data);
            setUpdateLoading(false);
            confirmationModal(`Delivery Charge Id - ${res.data.id} updated successfully!`, "success");
            return true;
        } catch (error) {
            setUpdateLoading(false);
            const message = error.response ? error.response.data.message : error.message;
            setErrorMsg("Can not update DeliveryCharge. Please check the console.");
            confirmationModal("Can not update DeliveryCharge. " + message, "error");
            console.log("DeliveryCharge update error.", error);
            return false;
        }

    }

    async function deleteDeliveryCharge(id) {

        try {
            setLoading(true);
            setErrorMsg('');
            await DeliveryChargeService.deleteDeliveryCharge(id);
            let updatedDeliveryCharges = deliveryCharges;
            updatedDeliveryCharges = deliveryCharges.filter(e => e.id !== id);
            setDeliveryCharges(updatedDeliveryCharges);

            Notification("success", "DELETED", `ID: ${id}, Delivery Charge Deleted Successfully`);

            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }

    return (
        <DeliveryChargeContext.Provider
            value={{
                deliveryCharges,
                deliveryCharge,
                totalElements,
                loading,
                updateLoading,
                errorMsg,
                getAllDeliveryCharges,
                updateDeliveryCharge,
                deleteDeliveryCharge,
                getDeliveryChargeById,
                addDeliveryCharge
            }}
        >
            {children}
        </DeliveryChargeContext.Provider>
    );
}

export default DeliveryChargeContextProvider;
