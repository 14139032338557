import React, {createContext, useState} from 'react';
import CountryService from "../services/CountryService";
import confirmationModal from "../components/common/ConfirmationModal";

export const CountryContext = createContext("CountryContext");


const CountryContextProvider = ({children}) => {

    const [countries, setCountries] = useState(null);
    const [country, setCountry] = useState(null);
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [totalElements, setTotalElements] = useState(0);

    async function getAllCountries({page, size, id, countryName}) {

        try {
            setLoading(true);
            setErrorMsg('');
            const res = await CountryService.getAllCountries(page, size, id, countryName);
            setCountries(res.data.content);
            setTotalElements(res.data.totalElements);
            setLoading(false);
            return res.data.content;
        } catch (error) {
            setLoading(false);
            setErrorMsg("Can not load Countries. Please check the console.");
            console.log("error", console.error())
            return false;
        }

    }

    async function addCountry(data) {

        try {
            setErrorMsg('');
            setLoading(true);
            const res = await CountryService.addCountry(data)
            setCountries(countries ? [...countries, res.data] : [res.data]);
            setLoading(false);
            confirmationModal("Country saved successfully !", "success");
            return true;
        } catch (err) {
            setLoading(false);
            const message = err.response ? err.response.data.message : err.message;
            setErrorMsg("Can not add Countries. Please check the console.");
            console.log("post country error", message);
            confirmationModal("Can not save Country. " + message, "error");
            return false;
        }

    }

    async function updateCountry(DATA, id) {
        try {
            setLoading(true);
            setErrorMsg('');
            const res = await CountryService.updateCountry(DATA, id);
            let updatedCountries = countries;
            updatedCountries = countries.map(e => {
                if (e.id === id) e = res.data;
                return e;
            })

            setCountries(updatedCountries);
            setLoading(false);
            confirmationModal("Country updated successfully !", "success");
            return true;
        } catch (error) {
            setLoading(false);
            setErrorMsg("Can not update Country. Please check the console.");
            console.log("Country update error.", error);
            return false;
        }

    }

    async function deleteCountry(id) {

        if (!window.confirm("Are you sure you want to delete this Country ?")) return;

        try {
            setLoading(true);
            setErrorMsg('');
            await CountryService.deleteCountry(id);
            let updatedCountries = countries;
            updatedCountries = countries.filter(e => e.id !== id);
            setCountries(updatedCountries);
            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);
            setErrorMsg("Can not delete Countries. Please check the console.");
            console.log("Country delete error.", error);
            return false;
        }
    }


    async function getCountryById(id) {
        try {
            setLoading(true);
            const res = await CountryService.getCountryById(id);
            setCountry(res.data);
            setLoading(false);
            return country;
        } catch (error) {
            setLoading(false);
            confirmationModal(error.response ? error.response.data : error.message, "error");
            console.log("Can not get Country by Id.")
        }
    }


    return (
        <CountryContext.Provider
            value={{
                loading,
                errorMsg,
                countries,
                totalElements,
                country,
                getAllCountries,
                updateCountry,
                deleteCountry,
                getCountryById,
                addCountry
            }}
        >
            {children}
        </CountryContext.Provider>
    );
}

export default CountryContextProvider;