import React, {createContext, useState} from 'react';
import DistrictService from "../services/DistrictService";
import confirmationModal from "../components/common/ConfirmationModal";

export const DistrictContext = createContext("DistrictContext");


const DistrictContextProvider = ({children}) => {

    const [districts, setDistricts] = useState([]);
    const [district, setDistrict] = useState(null);
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [totalElements, setTotalElements] = useState(0);

    async function getAllDistricts({page, size, id, districtName}) {

        try {
            setLoading(true);

            setErrorMsg('');
            const res = await DistrictService.getAllDistricts(page, size, id, districtName);
            setDistricts(res.data.content);
            setTotalElements(res.data.totalElements);
            setLoading(false);
            return res.data.content;
        } catch (error) {
            setLoading(false);
            setErrorMsg("Can not load Districs. Please check the console.");
            console.log("error", error)
            return false;
        }

    }

    async function getDistrictById(id) {

        try {
            setLoading(true);
            setErrorMsg('');
            const res = await DistrictService.getDistrictById(id);
            console.log(res.data)
            setDistrict(res.data);
            setLoading(false);
            return res.data;
        } catch (error) {
            setLoading(false);
            setErrorMsg("Can not load Districs. Please check the console.");
            console.log("error", error)
            return false;
        }

    }

    async function addDistrict(data) {

        try {
            setErrorMsg('');
            setLoading(true);
            const res = await DistrictService.addDistrict(data)
            setDistrict(res.data);
            setLoading(false);
            confirmationModal("Distric saved successfully.", "success");
            return true;
        } catch (error) {
            const message = error.response ? error.response.data.message : error.message;
            setErrorMsg("Can not add Districs. Please check the console.");
            console.log("post district error", message);
            confirmationModal("Can not save Distric. " + message, "error");
            return false;
        }

    }

    async function updateDistrict(data, id) {
        try {
            setLoading(true);
            setErrorMsg('');
            const res = await DistrictService.updateDistrict(data, id);
            setDistrict(res.data);
            setLoading(false);
            confirmationModal("Distric updated successfully.", "success");
            return true;
        } catch (error) {
            const message = error.response ? error.response.data.message : error.message;
            setErrorMsg("Can not update Distric. Please check the console.");
            confirmationModal("Can not update Distric. " + message, "error");
            console.log("Distric update error.", error);
            return false;
        }

    }

    async function deleteDistrict(id) {

        if (!window.confirm("Are you sure you want to delete this Distric ?")) return;

        try {
            setLoading(true);
            setErrorMsg('');
            await DistrictService.deleteDistrict(id);
            let updatedDistrics = districts;
            updatedDistrics = districts.filter(e => e.id !== id);
            setDistricts(updatedDistrics);
            setLoading(false);
            return true;
        } catch (error) {
            setErrorMsg("Can not delete Districs. Please check the console.");
            console.log("Distric delete error.", error);
            return false;
        }
    }

    return (
        <DistrictContext.Provider
            value={{
                districts,
                district,
                totalElements,
                loading,
                errorMsg,
                getAllDistricts,
                updateDistrict,
                deleteDistrict,
                getDistrictById,
                addDistrict
            }}
        >
            {children}
        </DistrictContext.Provider>
    );
}

export default DistrictContextProvider;