import React, {createContext, useState} from 'react';
import GenericService from "../services/GenericService";
import confirmationModal from "../components/common/ConfirmationModal";

export const GenericContext = createContext("GenericContext");


const GenericContextProvider = ({children}) => {

    const [generics, setGenerics] = useState(null);
    const [generic, setGeneric] = useState();
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [totalElements, setTotalElements] = useState('');

    const getAllGenerics = async ({page, size, id, genericName}) => {

        try {
            setLoading(true);
            setErrorMsg('');
            const res = await GenericService.getAllGenerics(page, size, id, genericName);
            setGenerics(res.data.content)
            setTotalElements(res.data.totalElements);
            setLoading(false);
            return res.data.content;
        } catch (error) {
            setErrorMsg("Can not load Generics. Please check the console.");
            console.log("error", console.error());
            return false;
        }

    }

    const getGenericById = async (id) => {

        try {
            setLoading(true);
            setErrorMsg('');
            const res = await GenericService.getGenericById(id);
            setGeneric(res.data);
            setLoading(false);
            return 200;
        } catch (error) {
            setLoading(false);
            setErrorMsg("Can not load Generics. Please check the console.");
            console.log("error", error)
            return error.response ? error.response.status : 500;
        }

    }

    const addGeneric = async (data) => {

        try {
            setErrorMsg('');
            setLoading(true);
            const res = await GenericService.addGeneric(data)
            setGeneric(res.data);
            setLoading(false);
            confirmationModal(`Generic Id - ${res.data.id} saved successfully. `, "success");
            return true;
        } catch (err) {
            const message = err.response ? err.response.data.message : err.message;
            setErrorMsg("Can not add Generics. Please check the console.");
            console.log("post generic error", message);
            confirmationModal("Can not save Generic. " + message, "error");
            return false;
        }

    }

    const updateGeneric = async (data, id) => {
        try {
            setLoading(true);
            setErrorMsg('');
            const res = await GenericService.updateGeneric(data, id);
            setGeneric(res.data);
            setLoading(false);
            confirmationModal(`Generic Id - ${res.data.id} updated successfully!`, "success");
            return true;
        } catch (error) {
            setErrorMsg("Can not update Generic. Please check the console.");
            console.log("Generic update error.", error);
            confirmationModal("Generic updated failed. ", "error");
            return false;
        }

    }

    const deleteGeneric = async (id) => {

        try {
            setLoading(true);
            setErrorMsg('');
            await GenericService.deleteGeneric(id);
            let updatedGenerics = generics;
            updatedGenerics = generics.filter(e => e.id !== id);
            setGenerics(updatedGenerics);
            setLoading(false);
            return true;
        } catch (error) {
            setErrorMsg("Can not delete Generics. Please check the console.");
            console.log("Generic delete error.", error);
            return false;
        }
    }


    return (
        <GenericContext.Provider
            value={{
                generics,
                generic,
                getGenericById,
                loading,
                errorMsg,
                totalElements,
                getAllGenerics,
                updateGeneric,
                deleteGeneric,
                addGeneric
            }}
        >
            {children}
        </GenericContext.Provider>
    );
}

export default GenericContextProvider;