import React from 'react';
import {Redirect} from "react-router-dom";
import {PAGE_403_PATH, PAGE_500_PATH} from "../../../../routes/Slugs";


export default (status, context) => {

    return (
        <div>
            {
                    status === 500 ? <Redirect to={PAGE_500_PATH}/> :
                        status === 403 ? <Redirect to={PAGE_403_PATH}/> :
                            context.errorMsg
            }
        </div>

    )
}
