import React, {createContext, useState} from 'react';
import ItemService from "../services/ItemService";
import confirmationModal from "../components/common/ConfirmationModal";

export const ItemContext = createContext("ItemContext");

const ItemContextProvider = ({children}) => {

    const [items, setItems] = useState(null);
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');

    async function getAllItems() {

        try {
            setLoading(true);
            setErrorMsg('');
            const res = await ItemService.getAllItems();
            setItems(res.data)
            setLoading(false);
            return true;
        } catch (error) {
            setErrorMsg("Can not load Items. Please check the console.");
            console.log("error", console.error())
            return false;
        }

    }

    async function addItem(data) {

        try {
            setErrorMsg('');
            setLoading(true);
            const res = await ItemService.addItem(data)
            setItems(items ? [...items, res.data] : [res.data]);
            setLoading(false);
            confirmationModal("Item saved successfully.", "success");
            return true;
        } catch (err) {
            const message = err.response ? err.response.data.message : err.message;
            setErrorMsg("Can not add Items. Please check the console.");
            console.log("post item error", message);
            confirmationModal("Can not save Item. " + message, "error");
            return false;
        }

    }

    async function updateItem(DATA, id) {
        try {
            setLoading(true);
            setErrorMsg('');
            const res = await ItemService.updateItem(DATA, id);
            let updatedItems = items;
            updatedItems = items.map(e => {
                if (e.id === id) e = res.data;
                return e;
            })

            setItems(updatedItems);
            setLoading(false);
            confirmationModal("Item saved successfully.", "success");
            return true;
        } catch (error) {
            const message = error.response ? error.response.data.message : error.message;
            setErrorMsg("Can not update Item. Please check the console.");
            confirmationModal("Can not update Item. " + message, "error");
            console.log("Item update error.", error);
            return false;
        }

    }

    async function deleteItem(id) {

        if (!window.confirm("Are you sure you want to delete this Item ?")) return;

        try {
            setLoading(true);
            setErrorMsg('');
            await ItemService.deleteItem(id);
            let updatedItems = items;
            updatedItems = items.filter(e => e.id !== id);
            setItems(updatedItems);
            setLoading(false);
            return true;
        } catch (error) {
            setErrorMsg("Can not delete Items. Please check the console.");
            console.log("Item delete error.", error);
            return false;
        }
    }


    async function getItemById(id) {
        try {
            const item = await ItemService.getItemById(id);
            return item;
        } catch (error) {
            confirmationModal(error.response ? error.response.data : error.message, "error");
            console.log("Can not get Item by Id.")
        }
    }


    return (
        <ItemContext.Provider
            value={{
                items,
                loading,
                errorMsg,
                getAllItems,
                updateItem,
                deleteItem,
                getItemById,
                addItem
            }}
        >
            {children}
        </ItemContext.Provider>
    );
}

export default ItemContextProvider;