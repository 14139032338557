import axios from "axios";
import * as URL from "../helpers/Constants";

export default class DeliveryPlanServices {

    static getAllDeliveryPlans(page, size, id, planName) {
        return axios.get(URL.GET_ALL_DELIVERY_PLANS, {
            ...DeliveryPlanServices.getAuthHeader(),
            params: {page, size, id, planName}
        });
    }

    static getDeliveryPlanById(id) {
        return axios.get(`${URL.GET_DELIVERY_PLAN_BY_ID}/${id}`, DeliveryPlanServices.getAuthHeader());
    }

    static createDeliveryPlan(data) {
        return axios.post(URL.CREATE_DELIVERY_PLAN, data, DeliveryPlanServices.getAuthHeader());
    }


    static updateDeliveryPlanById(data, id) {
        return axios.put(`${URL.UPDATE_DELIVERY_PLAN_BY_ID}/${id}`, data, DeliveryPlanServices.getAuthHeader());
    }

    static deleteDeliveryPlanById(id) {
        return axios.delete(`${URL.DELETE_DELIVERY_PLAN_BY_ID}/${id}`, DeliveryPlanServices.getAuthHeader());
    }

    static getAuthHeader() {
        const accessToken = localStorage.getItem("oushodsheba_access_token");
        const options = {headers: {"Authorization": `Bearer ${accessToken}`}};
        return options;
    }

};
