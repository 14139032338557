import * as URL from "../../helpers/Constants";
import axios from "axios";

export default class PMSCustomerServices {

    static getAllCustomersByPharmacyId(id, params) {
        return axios.get(`${URL.PMS_GET_ALL_CUSTOMER_BY_PHARMACY_ID}/${id}`, {
            ...PMSCustomerServices.getAuthHeader(),
            params
        })
    }

    static getAllLiteCustomersByPharmacyId(page, size, id) {
        return axios.get(`${URL.PMS_GET_ALL_LITE_CUSTOMER_BY_PHARMACY_ID}/${id}`, {
            ...PMSCustomerServices.getAuthHeader(),
            params: {page, size}
        })
    }

    static getCustomerByPharmacyIdById(pharmacyId, id) {
        return axios.get(`${URL.PMS_GET_CUSTOMER_BY_PHARMACY_ID_BY_ID}/${pharmacyId}/${id}`, PMSCustomerServices.getAuthHeader())
    }

    static getCustomerByPharmacyIdByLocalId(pharmacyId, localId) {
        return axios.get(`${URL.PMS_GET_CUSTOMER_BY_PHARMACY_ID_BY_LOCAL_ID}/${pharmacyId}/${localId}`, PMSCustomerServices.getAuthHeader())
    }

    static createCustomerByPharmacyId(id, data) {
        return axios.post(`${URL.PMS_CREATE_CUSTOMER_BY_PHARMACY_ID}/${id}`, data, PMSCustomerServices.getAuthHeader())
    }

    static updateCustomerByIdByPharmacyId(id, pharmacyId, data) {
        return axios.put(`${URL.PMS_UPDATE_CUSTOMER_BY_ID_PHARMACY_ID}/${id}/${pharmacyId}`, data, PMSCustomerServices.getAuthHeader())
    }

    static deleteCustomerById(id) {
        return axios.delete(`${URL.PMS_DELETE_CUSTOMER_BY_ID}/${id}`, PMSCustomerServices.getAuthHeader())
    }

    static getAuthHeader() {
        const accessToken = localStorage.getItem("oushodsheba_access_token");
        return {
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
        };
    }

}
