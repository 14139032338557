import React, {createContext, useState} from 'react';
import PharmacistService from "../services/PharmacistService";
import pharmacistService from "../services/PharmacistService";
import ErrorModal from "../components/common/ErrorModal";
import Notification from "../components/common/Notification";

export const PharmacistContext = createContext("PharmacistContext");

const PharmacistContextProvider = ({children}) => {

    const [pharmacists, setPharmacists] = useState([]);
    const [loading, setLoading] = useState(false);
    const [errorMsg] = useState("");
    const [pharmacist, setPharmacist] = useState(null);
    const [profileLoading, setProfileLoading] = useState(false);
    const[totalElements, setTotalElements] = useState(0);

    async function getAllPharmacists({page, size, id, name, email}) {

        try {
            setLoading(true);
            const res = await PharmacistService.getAllPharmacists(page, size, id, name, email);
            setPharmacists(res.data.content);
            setTotalElements(res.data.totalElements);
            setLoading(false);
            return res.data.content;
        } catch (error) {
            setLoading(false);
            ErrorModal(error)
            return false;
        }

    }

    async function getPharmacistById(id) {

        try {
            setLoading(true);
            const res = await PharmacistService.getPharmacistById(id);
            setPharmacist(res.data);
            setLoading(false);
            return res.data;
        } catch (error) {
            setLoading(false);
            ErrorModal(error)
            return false;
        }

    }

    async function addPharmacist(data) {

        try {
            const res = await PharmacistService.addPharmacistFromAdmin(data);
            setPharmacist(res.data);
            Notification("success", "Created", "Representative added successfully!")
            return res.data;
        } catch (err) {
            ErrorModal(err)
            return null;
        }

    }

    async function getPharmacistProfile() {
        try {
            setProfileLoading(true);
            const res = await PharmacistService.getProfile();
            setPharmacist(res.data);
            setProfileLoading(false);
            return true;
        } catch (error) {
            setProfileLoading(false);
            ErrorModal(error)
            return false;
        }
    }

    async function updatePharmacist(data, id) {
        try {
            const res = await pharmacistService.updatePharmacistFromAdmin(data, id);
            setPharmacist(res.data);
            Notification("success", "Updated", "Customer updated successfully!");
            return res.data;
        } catch (error) {
            ErrorModal(error)
            return false;
        }

    }

    async function deletePharmacist(id) {

        if (!window.confirm("Are you sure you want to delete this Pharmacist ?")) return;

        try {
            await PharmacistService.deletePharmacist(id);
            let updatedPharmacists = pharmacists;
            updatedPharmacists = pharmacists.filter(e => e.id !== id)
            setPharmacists(updatedPharmacists);
            Notification("success", "Deleted", "Representative has been deleted successfully!");
            return true;
        } catch(error) {
            ErrorModal(error)
            return false;
        }

    }

    const changePharmacistStatus = async (email, status) => {

        try {
            await PharmacistService.changePharmacistStatus(email, status);
            let updatedPharmacists = pharmacists;
            updatedPharmacists = pharmacists.map(e => {
                if (e.email === email) e.status = status;
                return e;
            })
            setPharmacists(updatedPharmacists);
            Notification("success", "Changed Status", "Representative's status has been changed!");
            return true;
        } catch (error){
            ErrorModal(error)
            return false;
        }

    }


    return (
        <PharmacistContext.Provider
            value={{
                loading,
                errorMsg,
                totalElements,
                pharmacists,
                pharmacist,
                getPharmacistById,
                getAllPharmacists,
                profileLoading,
                updatePharmacist,
                deletePharmacist,
                addPharmacist,
                getPharmacistProfile,
                changePharmacistStatus
            }}
        >
            {children}
        </PharmacistContext.Provider>
    );
}

export default PharmacistContextProvider;
