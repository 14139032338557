import React, {createContext, useState} from "react";
import PMSTransactionServices from "../../services/pms_services/PMSTransactionServices";
import Notification from "../../components/common/Notification";

export const PMSTransactionContext = createContext("PMSTransactionContext");

const PMSTransactionContextProvider = ({children}) => {
    const [loading, setLoading] = useState(false);
    const [loadingCancelTransaction, setLoadingCancelTransaction] = useState(
        false
    );
    const [loadingCreateTransaction, setLoadingCreateTransaction] = useState(
        false
    );
    const [errorMsg, setErrorMsg] = useState("");

    const [totalElements, setTotalElements] = useState(0);
    const [transactions, setTransactions] = useState([]);
    const [transaction, setTransaction] = useState(null);

    const getTransactions = async (accId, params) => {
        try {
            setLoading(true);
            setErrorMsg("");

            const res = await PMSTransactionServices.getTransactions(accId, params);

            setTransactions(res.data.content);
            setTotalElements(res.data.totalElements);
            setLoading(false);

            return true;
        } catch (error) {
            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }

            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    };

    const getTransactionById = async (id) => {
        try {
            setLoading(true);
            setErrorMsg("");

            const res = await PMSTransactionServices.getTransactionById(id);

            setTransaction(res.data);
            setLoading(false);

            return true;
        } catch (error) {
            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }

            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    };

    const createTransaction = async (data) => {
        try {
            setLoadingCreateTransaction(true);
            setErrorMsg("");

            await PMSTransactionServices.createTransaction(data);

            Notification("success", "Success", "Transaction successfully!");

            setLoadingCreateTransaction(false);
            return true;
        } catch (error) {
            setLoadingCreateTransaction(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }

            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    };

    // const cancelTransaction = async (accId, search, id) => {
    const cancelTransaction = async id => {
        try {
            setLoadingCancelTransaction(true);
            setErrorMsg("");

            await PMSTransactionServices.cancelTransaction(id);

            Notification("success", "Canceled", "Transaction hase been canceled!");

            setLoadingCancelTransaction(false);
            return true;
        } catch (error) {
            setLoadingCancelTransaction(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }

            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    };

    return (
        <PMSTransactionContext.Provider
            value={{
                loading,
                errorMsg,
                totalElements,
                transactions,
                transaction,
                loadingCreateTransaction,
                getTransactions,
                getTransactionById,
                createTransaction,
                cancelTransaction,
                loadingCancelTransaction,
            }}
        >
            {children}
        </PMSTransactionContext.Provider>
    );
};

export default PMSTransactionContextProvider;
