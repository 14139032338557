import React, {createContext, useState} from 'react';
import AreaService from "../services/AreaService";
import confirmationModal from "../components/common/ConfirmationModal";

export const AreaContext = createContext("AreaContext");


const AreaContextProvider = ({children}) => {

    const [areas, setAreas] = useState(null);
    const [area, setArea] = useState();
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [totalElements, setTotalElements] = useState('');

    async function getAllAreas({page, size, id, areaName, districtName}) {

        try {
            setLoading(true);
            setErrorMsg('');
            const res = await AreaService.getAllAreas(page, size, id, areaName, districtName);
            setAreas(res.data.content)
            setTotalElements(res.data.totalElements);
            setLoading(false);
            return res.data.content;
        } catch (error) {
            setErrorMsg("Can not load Areas. Please check the console.");
            console.log("error", console.error());
            return false;
        }

    }

    async function getAreaById(id) {

        try {
            setLoading(true);
            setErrorMsg('');
            const res = await AreaService.getAreaById(id);
            setArea(res.data);
            setLoading(false);
            return 200;
        } catch (error) {
            setLoading(false);
            setErrorMsg("Can not load Areas. Please check the console.");
            console.log("error", error)
            return error.response ? error.response.status : 500;
        }

    }

    async function addArea(data) {

        try {
            setErrorMsg('');
            setLoading(true);
            const res = await AreaService.addArea(data);
            setArea(res.data);
            setLoading(false);
            confirmationModal("Area saved successfully. ", "success");
            return res.data;
        } catch (err) {
            const message = err.response ? err.response.data.message : err.message;
            setErrorMsg("Can not add Areas. Please check the console.");
            console.log("post area error", message);
            confirmationModal("Can not save Area. " + message, "error");
            return false;
        }

    }

    async function updateArea(data, id) {
        try {
            setLoading(true);
            setErrorMsg('');
            const res = await AreaService.updateArea(data, id);
            setArea(res.data);
            setLoading(false);
            confirmationModal("Area updated successfully. ", "success");
            return res.data;
        } catch (error) {
            setErrorMsg("Can not update Area. Please check the console.");
            console.log("Area update error.", error);
            confirmationModal("Can't update area ", "error");
            return false;
        }

    }

    async function deleteArea(id) {

        if (!window.confirm("Are you sure you want to delete this Area ?")) return;

        try {
            setLoading(true);
            setErrorMsg('');
            await AreaService.deleteArea(id);
            let updatedAreas = areas;
            updatedAreas = areas.filter(e => e.id !== id);
            setAreas(updatedAreas);
            setLoading(false);
            return true;
        } catch (error) {
            setErrorMsg("Can not delete Areas. Please check the console.");
            console.log("Area delete error.", error);
            return false;
        }
    }


    return (
        <AreaContext.Provider
            value={{
                areas,
                area,
                getAreaById,
                loading,
                errorMsg,
                totalElements,
                getAllAreas,
                updateArea,
                deleteArea,
                addArea,
            }}
        >
            {children}
        </AreaContext.Provider>
    );
}

export default AreaContextProvider;