import React, {createContext, useState} from 'react';
import DrugService from "../services/DrugService";
import confirmationModal from "../components/common/ConfirmationModal";

export const DrugContext = createContext("DrugContext");


const DrugContextProvider = ({children}) => {

    const [drugs, setDrugs] = useState(null);
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');

    async function getAllDrugs() {

        try {
            setLoading(true);
            setErrorMsg('');
            const res = await DrugService.getAllDrugs();
            setDrugs(res.data)
            setLoading(false);
            return true;
        } catch (error) {
            setErrorMsg("Can not load Drugs. Please check the console.");
            console.log("error", console.error())
            return false;
        }

    }

    async function addDrug(data) {

        try {
            setErrorMsg('');
            setLoading(true);
            const res = await DrugService.addDrug(data)
            setDrugs(drugs ? [...drugs, res.data] : [res.data]);
            setLoading(false);
            return true;
        } catch (err) {
            const message = err.response ? err.response.data.message : err.message;
            setErrorMsg("Can not add Drugs. Please check the console.");
            console.log("post drug error", message);
            confirmationModal("Can not save Drug. " + message, "error");
            return false;
        }

    }

    async function updateDrug(DATA, id) {
        try {
            setLoading(true);
            setErrorMsg('');
            const data = await DrugService.updateDrug(DATA, id);
            let updatedDrugs = drugs;
            updatedDrugs = drugs.map(e => {
                if (e.id === id) e = data;
                return e;
            })

            setDrugs(updatedDrugs);
            setLoading(false);
            return true;
        } catch (error) {
            setErrorMsg("Can not update Drug. Please check the console.");
            console.log("Drug update error.", error);
            return false;
        }

    }

    async function deleteDrug(id) {

        if (!window.confirm("Are you sure you want to delete this Drug ?")) return;

        try {
            setLoading(true);
            setErrorMsg('');
            await DrugService.deleteDrug(id);
            let updatedDrugs = drugs;
            updatedDrugs = drugs.filter(e => e.id !== id);
            setDrugs(updatedDrugs);
            setLoading(false);
            return true;
        } catch (error) {
            setErrorMsg("Can not delete Drugs. Please check the console.");
            console.log("Drug delete error.", error);
            return false;
        }
    }

    async function getDrugById(id) {
        try {
            const drug = await DrugService.getDrugById(id);
            return drug;
        } catch (error) {
            confirmationModal(error.response ? error.response.data : error.message, "error");
            console.log("Can not get Drug by Id.")
        }
    }


    return (
        <DrugContext.Provider
            value={{
                drugs,
                loading,
                errorMsg,
                getAllDrugs,
                updateDrug,
                deleteDrug,
                getDrugById,
                addDrug
            }}
        >
            {children}
        </DrugContext.Provider>
    );
}

export default DrugContextProvider;