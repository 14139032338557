import * as URL from "../helpers/Constants";
import axios from "axios";

export default class hospitalService {
    static getAllHospitals(hospitalName, page, size) {
        return axios.get(URL.GET_ALL_HOSPITALS, {
            ...hospitalService.getAuthHeader(),
            params: {hospitalName, page, size}
        });
    }

    // static addHospital(data) {
    //     return axios.post(URL.POST_HOSPITAL, data, hospitalService.getAuthHeader());
    // }

    // static updateHospital(data, id) {
    //     return axios.put(`${URL.UPDATE_HOSPITAL}/${id}`, data, hospitalService.getAuthHeader());
    // }

    // static deleteHospital(id) {
    //     return axios.delete(`${URL.DELETE_HOSPITAL}/${id}`, hospitalService.getAuthHeader());
    // }

    static getAuthHeader() {
        const accessToken = localStorage.getItem("oushodsheba_access_token");
        const options = {headers: {"Authorization": `Bearer ${accessToken}`}}
        return options;
    }

    // static changeHospitalStatus(email, status) {
    //     return axios.post(`${URL.UPDATE_HOSPITAL_STATUS}/${email}?status=${status}`)
    // }
}