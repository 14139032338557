import React, {createContext, useState} from "react";

export const PMSPharmacyContext = createContext("PMSPharmacyContext");

const PMSPharmacyContextProvider = ({children}) => {

    const p = JSON.parse(localStorage.getItem("selectedPharmacy"));

    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [selectedPharmacy, setSelectedPharmacy] = useState(p ? p : null);

    const addSelectedPharmacy = (data) => {
        try {
            setLoading(true);
            setErrorMsg('');
            localStorage.setItem("selectedPharmacy", JSON.stringify(data));
            setSelectedPharmacy(data);
            setLoading(false);
            return true;
        } catch (e) {
            setLoading(false);
            console.log(e);
            return false;
        }
    }

    return (
        <PMSPharmacyContext.Provider
            value={{
                loading,
                errorMsg,
                selectedPharmacy,
                addSelectedPharmacy,
                setSelectedPharmacy
            }}
        >
            {children}
        </PMSPharmacyContext.Provider>
    )
}

export default PMSPharmacyContextProvider;