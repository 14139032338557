import * as URL from "../helpers/Constants";
import axios from "axios";

export default class itemService {
    static getAllItems() {
        return axios.get(URL.GET_ALL_ITEMS, itemService.getAuthHeader());
    }

    static addItem(data) {
        return axios.post(URL.POST_ITEM, data, itemService.getAuthHeader());
    }

    static updateItem(data, id) {
        return axios.put(`${URL.UPDATE_ITEM}/${id}`, data, itemService.getAuthHeader());
    }

    static deleteItem(id) {
        return axios.delete(`${URL.DELETE_ITEM}/${id}`, itemService.getAuthHeader());
    }

    static getAuthHeader() {
        const accessToken = localStorage.getItem("oushodsheba_access_token");
        const options = {headers: {"Authorization": `Bearer ${accessToken}`}}
        return options;
    }

    // static changeItemStatus(email, status) {
    //     return axios.post(`${URL.UPDATE_ITEM_STATUS}/${email}?status=${status}`)
    // }
}