import * as URL from "../helpers/Constants";
import axios from "axios";

export default class TagService {
    static getAllTags(page, size, title) {
        return axios.get(URL.GET_ALL_TAGS, {...TagService.getAuthHeader(), params: {page, size, title}});
    }

    static getTagById(id) {
        return axios.get(`${URL.GET_TAG_BY_ID}/${id}`, TagService.getAuthHeader());
    }

    static addTag(data) {
        return axios.post(URL.POST_TAG, data, TagService.getAuthHeader());
    }

    static updateTag(data, id) {
        return axios.put(`${URL.UPDATE_TAG}/${id}`, data, TagService.getAuthHeader());
    }

    static deleteTag(id) {
        return axios.delete(`${URL.DELETE_TAG}/${id}`, TagService.getAuthHeader());
    }

    static getAuthHeader() {
        const accessToken = localStorage.getItem("oushodsheba_access_token");
        const options = {headers: {"Authorization": `Bearer ${accessToken}`}}
        return options;
    }

    // static changeTagStatus(email, status) {
    //     return axios.post(`${URL.UPDATE_TAG_STATUS}/${email}?status=${status}`)
    // }
}