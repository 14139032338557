import * as URL from "../../helpers/Constants";
import axios from "axios";

export default class PMSVendorServices {

    static getAllVendorsByPharmacyId(id, params) {
        return axios.get(`${URL.PMS_GET_ALL_VENDOR_BY_PHARMACY_ID}/${id}`, {
            ...PMSVendorServices.getAuthHeader(),
            params
        })
    }

    static getAllLiteVendorsByPharmacyId(page, size, id) {
        return axios.get(`${URL.PMS_GET_ALL_LITE_VENDOR_BY_PHARMACY_ID}/${id}`, {
            ...PMSVendorServices.getAuthHeader(),
            params: {page, size}
        })
    }

    static getVendorByPharmacyIdById(pharmacyId, id) {
        return axios.get(`${URL.PMS_GET_VENDOR_BY_PHARMACY_ID_BY_ID}/${pharmacyId}/${id}`, PMSVendorServices.getAuthHeader())
    }

    static getVendorByPharmacyIdByLocalId(pharmacyId, localId) {
        return axios.get(`${URL.PMS_GET_VENDOR_BY_PHARMACY_ID_BY_LOCAL_ID}/${pharmacyId}/${localId}`, PMSVendorServices.getAuthHeader())
    }

    static createVendorByPharmacyId(id, data) {
        return axios.post(`${URL.PMS_CREATE_VENDOR_BY_PHARMACY_ID}/${id}`, data, PMSVendorServices.getAuthHeader())
    }

    static updateVendorByIdByPharmacyId(id, pharmacyId, data) {
        return axios.put(`${URL.PMS_UPDATE_VENDOR_BY_ID_PHARMACY_ID}/${id}/${pharmacyId}`, data, PMSVendorServices.getAuthHeader())
    }

    static deleteVendorById(id) {
        return axios.delete(`${URL.PMS_DELETE_VENDOR_BY_ID}/${id}`, PMSVendorServices.getAuthHeader())
    }


    static getAuthHeader() {
        const accessToken = localStorage.getItem("oushodsheba_access_token");
        return {headers: {"Authorization": `Bearer ${accessToken}`}};
    }

}