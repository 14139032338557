import * as URL from "../helpers/Constants"
import axios from "axios";

class PregnancyCategoryService {

    static getAllPregnancyCategory() {
        return axios.get(URL.PREGNANCY_CATEGORY, PregnancyCategoryService.getAuthHeader());
    }


    static getAuthHeader() {
        const accessToken = localStorage.getItem("oushodsheba_access_token");
        const options = {headers: {"Authorization": `Bearer ${accessToken}`}}
        return options;
    }
}


export default PregnancyCategoryService;