import * as URL from "../helpers/Constants";
import axios from "axios";

export default class ProductTypeService {
    static getAllProductTypes(params) {
        return axios.get(URL.GET_ALL_PRODUCT_TYPES, {
            ...ProductTypeService.getAuthHeader(),
            params: params
        });
    }

    static getProductTypeById(id) {
        return axios.get(`${URL.GET_PRODUCT_TYPE_BY_ID}/${id}`, ProductTypeService.getAuthHeader());
    }

    static addProductType(data) {
        return axios.post(URL.POST_PRODUCT_TYPE, data, ProductTypeService.getAuthHeader());
    }

    static updateProductType(data, id) {
        return axios.put(`${URL.UPDATE_PRODUCT_TYPE}/${id}`, data, ProductTypeService.getAuthHeader());
    }

    static deleteProductType(id) {
        return axios.delete(`${URL.DELETE_PRODUCT_TYPE}/${id}`, ProductTypeService.getAuthHeader());
    }

    static getAuthHeader() {
        const accessToken = localStorage.getItem("oushodsheba_access_token");
        const options = {headers: {"Authorization": `Bearer ${accessToken}`}}
        return options;
    }

}