import * as URL from "../helpers/Constants";
import axios from "axios";

export default class DeliveryPersonService {
    static getAllDeliveryPersons(page, size, id, name, email, primaryPhone, areaName) {
        return axios.get(URL.GET_ALL_DELEVERY_PERSONS, {
            ...DeliveryPersonService.getAuthHeader(),
            params: {page, size, id, name, email, primaryPhone, areaName}
        });
    }

    static addDeliveryPerson(data) {
        return axios.post(URL.POST_DELEVERY_PERSON, data, DeliveryPersonService.getAuthHeader());
    }

    static getDeliveryPersonById(id) {
        return axios.get(`${URL.GET_DELEVERY_PERSON_BY_ID}/${id}`, DeliveryPersonService.getAuthHeader());
    }

    static updateDeliveryPerson(data, id) {
        return axios.put(`${URL.UPDATE_DELEVERY_PERSON}/${id}`, data, DeliveryPersonService.getAuthHeader());
    }

    static deleteDeliveryPerson(id) {
        return axios.delete(`${URL.DELETE_DELEVERY_PERSON}/${id}`, DeliveryPersonService.getAuthHeader());
    }

    static changeDeliveryPersonStatus = (deliveryPersonId, status) => {
        return axios.patch(`${URL.DELIVERY_PERSON_STATUS}/${deliveryPersonId}`, null,
            {
                ...DeliveryPersonService.getAuthHeader(),
                params: {status}
            });
    }


    static getAuthHeader() {
        const accessToken = localStorage.getItem("oushodsheba_access_token");
        const options = {headers: {"Authorization": `Bearer ${accessToken}`}}
        return options;
    }

    // static changeDeliveryPersonStatus(email, status) {
    //     return axios.post(`${URL.UPDATE_DELEVERY_PERSON_STATUS}/${email}?status=${status}`)
    // }
}
