import React, {createContext, useState} from "react";

export const PMSPaymentContext = createContext("PMSPaymentContext");

const PMSPaymentContextProvider = ({children}) => {

    const [loading] = useState(false);
    const [errorMsg] = useState("");
    const [pagination] = useState({
        total: 0,
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ["10", "30", "50", "100"]
    });


    return (
        <PMSPaymentContext.Provider
            value={{
                loading,
                errorMsg,
                pagination
            }}
        >
            {children}
        </PMSPaymentContext.Provider>
    )
}

export default PMSPaymentContextProvider;
