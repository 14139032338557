import React, {createContext, useState} from 'react';
import PregnancyCategoryService from '../services/PregnancyCategoryService';

export const PregnancyCategoryContext = createContext("PregnancyCategoryContext");

const PregnancyCategoryContextProvider = ({children}) => {

    const [pregnancyCategory, setPregnancyCategory] = useState(null);
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    const getAllPregnancyCategory = () => {
        try {
            setLoading(true);
            setErrorMsg("");
            const res = PregnancyCategoryService.getAllPregnancyCategory();
            setPregnancyCategory(res.data);
            setLoading(false);
            return pregnancyCategory;

        } catch (error) {
            setLoading(false);
            setErrorMsg("Can not load Pregnancy Category. Please check the console.");
            console.log("error", error)
            return false;

        }
    }


    return (

        <PregnancyCategoryContext.Provider
            value={{
                loading,
                errorMsg,
                pregnancyCategory,
                getAllPregnancyCategory
            }}

        >
            {children}
        </PregnancyCategoryContext.Provider>

    )


}
export default PregnancyCategoryContextProvider;