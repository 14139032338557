import * as URL from "../helpers/Constants";
import axios from "axios";

export default class ThanaService {
    static getAllThanas(page, size, id, name, districtName) {
        return axios.get(URL.GET_ALL_THANAS, {
            ...ThanaService.getAuthHeader(),
            params: {page, size, id, name, districtName}
        });
    }

    static getThanaById(id) {
        return axios.get(`${URL.GET_THANA_BY_ID}/${id}`, ThanaService.getAuthHeader());
    }

    static addThana(data) {
        return axios.post(URL.POST_THANA, data, ThanaService.getAuthHeader());
    }

    static updateThana(data, id) {
        return axios.put(`${URL.UPDATE_THANA}/${id}`, data, ThanaService.getAuthHeader());
    }

    static deleteThana(id) {
        return axios.delete(`${URL.DELETE_THANA}/${id}`, ThanaService.getAuthHeader());
    }

    static getAuthHeader() {
        const accessToken = localStorage.getItem("oushodsheba_access_token");
        const options = {headers: {"Authorization": `Bearer ${accessToken}`}}
        return options;
    }

    // static changeThanaStatus(email, status) {
    //     return axios.post(`${URL.UPDATE_THANA_STATUS}/${email}?status=${status}`)
    // }
}