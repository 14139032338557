import React, {createContext, useState} from "react";
import Notification from "../components/common/Notification";
import BannerService from "../services/BannerService";

export const BannerContext = createContext("BannerContext");

const BannerContextProvider = ({children}) => {

    const [banners, setBanners] = useState([]);
    const [banner, setBanner] = useState(null);
    const [pharmacyBanner, setPharmacyBanner] = useState(null);
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [totalElements, setTotalElements] = useState(0);

    const getBannerList = async params => {

        try {
            setLoading(true);
            setErrorMsg('');

            const res = await BannerService.getBannerList(params);

            setBanners(res.data.content);
            setTotalElements(res.data.totalElements);
            setLoading(false);

            return res.data.content;
        } catch (error) {
            setLoading(false);
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }

    }

    const getPharmacyBannerList = async params => {

        try {
            setLoading(true);
            setErrorMsg('');

            const res = await BannerService.getPharmacyBannerList(params);

            setPharmacyBanner(res.data.content);
            setTotalElements(res.data.totalElements);
            setLoading(false);

            return res.data.content;
        } catch (error) {
            setLoading(false);
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }

    }


    const addBanner = async data => {

        try {
            setLoading(true);
            const res = await BannerService.addBanner(data)
            setLoading(false);

            Notification("success", "Created", "Banner Created Successfully");

            return res.data;
        } catch (error) {
            setLoading(false);
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }

    }

    const editBanner = async (id, data) => {

        try {
            setLoading(true);
            await BannerService.editBanner(id, data);
            setLoading(false);

            Notification("success", "Updated", "Banner Updated Successfully");

            return true;
        } catch (error) {
            setLoading(false);
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }

    }

    const deleteBanner = async id => {

        try {
            setLoading(true);
            await BannerService.deleteBanner(id);
            setLoading(false);

            Notification("success", "Deleted", "Banner Deleted Successfully");

            return true;
        } catch (error) {
            setLoading(false);
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }

    }

    const getBannerById = async id => {

        try {
            setLoading(true);
            setErrorMsg('');

            const res = await BannerService.getBannerById(id);

            setBanner(res.data);
            setLoading(false);

            return res.data;
        } catch (error) {
            setLoading(false);
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }

    }


    return (
        <BannerContext.Provider
            value={{
                loading,
                errorMsg,
                totalElements,
                banners,
                banner,
                pharmacyBanner,
                getPharmacyBannerList,
                getBannerList,
                getBannerById,
                addBanner,
                editBanner,
                deleteBanner,
            }}
        >
            {children}
        </BannerContext.Provider>
    );
}

export default BannerContextProvider;
