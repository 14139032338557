import React, {createContext, useState} from 'react';
import OrderService from "../services/OrderService";
import confirmationModal from "../components/common/ConfirmationModal";

export const OrderContext = createContext("OrderContext");

const OrderContextProvider = ({children}) => {

    const [orders, setOrders] = useState(null);
    const [stackedOrders, setStackedOrders] = useState(null);
    const [order, setOrder] = useState(null);
    const [orderCalculation, setOrderCalculation] = useState(null);
    const [calculationLoading, setCalculationLoading] = useState(false);
    const [orderTracking, setOrderTracking] = useState(null);
    const [loading, setLoading] = useState(true);
    const [orderTrackingLoading, setOrderTrackingLoading] = useState(null);
    const [errorMsg, setErrorMsg] = useState('');
    const [totalElements, setTotalElements] = useState(0);
    const [pendingOrdersForDeliveryPerson, setPendingOrdersForDeliveryPerson] = useState([]);
    const [loadingPendingOrdersForDeliveryPerson, setLoadingPendingOrdersForDeliveryPerson] = useState(true);
    const [prescriptionProducts, setPrescriptionProducts] = useState([]);
    const [hasNonDigitalPrescription, setHasNonDigitalPrescription] = useState(false);
    const [defaultDeliveryPersonOrderStack, setDefaultDeliveryPersonOrderStack] = useState([]);
    const [defaultPharmacyOrderStack, setDefaultPharmacyOrderStack] = useState([]);
    const [updateLoading, setUpdateLoading] = useState(false);

    const [editedOrder, setEditedOrder] = useState(null);


    async function getAllOrders({page, size, from, to, id, customer, status}) {

        try {
            setLoading(true);

            setErrorMsg('');
            const res = await OrderService.getAllOrders(page, size, from, to, id, customer, status);
            setOrders(res.data.content);
            setTotalElements(res.data.totalElements);
            setLoading(false);
            return res.data.content;
        } catch (error) {
            setLoading(false);
            setErrorMsg("Can not load Orders. Please check the console.");
            console.log("error", error)
            return false;
        }

    }

    async function getAllStackedOrders({from, to, page, size, id, customerId, customerName, status}) {

        try {
            setLoading(true);

            setErrorMsg('');
            const res = await OrderService.getAllStackedOrders(from, to, page, size, id, customerId, customerName, status);
            setStackedOrders(res.data.content);
            setTotalElements(res.data.totalElements);
            setLoading(false);
            return res.data.content;
        } catch (error) {
            setLoading(false);
            setErrorMsg("Can not load Orders. Please check the console.");
            console.log("error", error)
            return false;
        }

    }

    async function getAllPendingOrdersForDeliveryPerson(page, size) {

        try {
            setLoadingPendingOrdersForDeliveryPerson(true);

            setErrorMsg('');
            const res = await OrderService.getAllPendingOrdersForDeliveryPerson(page, size);
            setPendingOrdersForDeliveryPerson(res.data.content);
            setTotalElements(res.data.totalElements);
            setLoadingPendingOrdersForDeliveryPerson(false);
            return res.data.content;
        } catch (error) {
            setLoadingPendingOrdersForDeliveryPerson(false);
            setErrorMsg("Can not load Orders. Please check the console.");
            console.log("error", error)
            return false;
        }

    }


    async function getOrderById(id) {

        try {
            setLoading(true);

            setErrorMsg('');
            const res = await OrderService.getOrderById(id);
            setOrder(res.data);
            let hasNonDP = false;

            res.data.prescriptionList.forEach(p => {
                if (!p.digitalized) {
                    setHasNonDigitalPrescription(true);
                    hasNonDP = true;
                }
            })

            if (!hasNonDP) setHasNonDigitalPrescription(false);

            addPrescriptionProduct(res.data);

            setLoading(false);
            return 200;
        } catch (error) {
            setLoading(false);
            setErrorMsg("Can not load Orders. Please check the console.");
            console.log("error", error)
            return error.response ? error.response.status : 500;
        }
    }

    async function getEditedOrderById(id) {

        try {
            setLoading(true);

            setErrorMsg('');
            const res = await OrderService.getOrderById(id);
            setEditedOrder(res.data);
            let hasNonDP = false;

            res.data.prescriptionList.forEach(p => {
                if (!p.digitalized) {
                    setHasNonDigitalPrescription(true);
                    hasNonDP = true;
                }
            })

            if (!hasNonDP) setHasNonDigitalPrescription(false);

            addPrescriptionProduct(res.data);

            setLoading(false);
            return res.data;
        } catch (error) {
            setLoading(false);
            setErrorMsg("Can not load Orders");
            return null;
        }
    }

    const addPrescriptionProduct = async (data, name) => {
        let products = [];

        !hasNonDigitalPrescription && data.prescriptionList.forEach(p => {
            p.prescriptionDetailsList.forEach(e => {
                if (!name) products.push(e.product);
                else if (e.product.productName.toLowerCase().includes(name.toLowerCase()))
                    products.push(e.product)
            })
        })


        setPrescriptionProducts(products);
    }

    async function searchPrescriptionProducts(name) {

        addPrescriptionProduct(order, name);

    }

    async function getOrderTrackingById(id) {

        try {
            setOrderTrackingLoading(true);

            setErrorMsg('');
            const res = await OrderService.getOrderTrackingById(id);
            setOrderTracking(res.data);
            setOrderTrackingLoading(false);
            return 200;
        } catch (error) {
            setOrderTrackingLoading(false);
            setErrorMsg("Can not load Orders. Please check the console.");
            console.log("error", error)
            return error.response ? error.response.status : 500;
        }

    }

    async function addOrder(data) {

        try {
            setErrorMsg('');
            setLoading(true);
            const res = await OrderService.addOrder(data)
            setOrders(orders ? [...orders, res.data] : [res.data]);
            setLoading(false);
            confirmationModal("Order saved successfully.", "success");
            return true;
        } catch (error) {
            const message = error.response ? error.response.data.message : error.message;
            setErrorMsg("Can not add Orders. Please check the console.");
            console.log("post order error", message);
            confirmationModal("Can not save Order. " + message, "error");
            return false;
        }

    }

    async function processOrder(id, cancel) {

        try {
            setErrorMsg('');
            setLoading(true);
            await OrderService.processOrder(order.orderDetailsList, id, cancel);
            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);
            const message = error.response ? error.response.data.message : error.message;
            setErrorMsg("Can not add Orders. Please check the console.");
            console.log("post order error", message);
            confirmationModal("Can not process Order. " + message, "error");
            return false;
        }

    }

    async function updateOrder(data, id, params) {
        try {
            setUpdateLoading(true);
            setErrorMsg('');
            await OrderService.updateOrder(data, id, params);
            // const res = await OrderService.updateOrder(DATA, id);
            // let updatedOrders = orders;
            // updatedOrders = orders.map(e => {
            //     if (e.id === id) e = res.data;
            //     return e;
            // })
            //
            // setOrders(updatedOrders);
            setUpdateLoading(false);
            confirmationModal("Order updated successfully.", "success");
            return true;
        } catch (error) {
            setUpdateLoading(false);
            const message = error.response ? error.response.data.message : error.message;
            setErrorMsg("Can not update Order. Please check the console.");
            confirmationModal("Can not update Order. " + message, "error");
            console.log("Order update error.", error);
            return false;
        }

    }

    function handleChangeQuantity(item, quantity) {

        let orderDetailsList = order.orderDetailsList.map(e => {
            if (e.product.id === item.product.id) {
                e.quantity = quantity;
                e.totalPrice = e.product.unitPrice * quantity;
            }
            return e;
        });
        setOrder({...order, orderDetailsList});

    }

    function addItemHandle(item) {

        setOrder({
            ...order,
            orderDetailsList: [...order.orderDetailsList, {
                product: item,
                quantity: 1,
                totalPrice: item.unitPrice,
                unit: 'PIECE'
            }]
        });
    }

    function handleDeleteItem(id) {
        let orderDetailsList = order.orderDetailsList.filter(e => e.product.id !== id);
        setOrder({...order, orderDetailsList});
    }


    async function processOrderCalculation() {
        try {
            setCalculationLoading(true);
            setErrorMsg('');
            const res = await OrderService.processOrderCalculation(order.orderDetailsList, order.id);
            setOrderCalculation(res.data);
            setCalculationLoading(false);
            // confirmationModal("Order updated successfully.", "success");
            return true;
        } catch (error) {
            setCalculationLoading(false);
            const message = error.response ? error.response.data.message : error.message;
            setErrorMsg("Can not process Order. Please check the console.");
            confirmationModal("Can not process Order. " + message, "error");
            console.log("Order process error.", error);
            return false;
        }
    }

    async function deleteOrder(id) {

        if (!window.confirm("Are you sure you want to delete this Order ?")) return;

        try {
            setLoading(true);
            setErrorMsg('');
            await OrderService.deleteOrder(id);
            let updatedOrders = orders;
            updatedOrders = orders.filter(e => e.id !== id);
            setOrders(updatedOrders);
            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);
            setErrorMsg("Can not delete Orders. Please check the console.");
            console.log("Order delete error.", error);
            return false;
        }
    }

    const assignOrderToDeliveryPerson = async (data, id) => {
        try {
            setLoading(true);
            setErrorMsg('');
            await OrderService.assignOrdersToDeliveryPerson(data, id);
            getAllPendingOrdersForDeliveryPerson();
            confirmationModal(`Assign to delivery person Id: ${id}`, "success");
            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);
            setErrorMsg("Can assign Orders. Please check the console.");
            confirmationModal(`${error.response.data.message}`, "error");
            console.log("Order assign error.", error);
            return false;
        }
    }

    const cancelOrderById = async (id) => {
        try {
            setLoading(true);
            const res = await OrderService.cancelOrder(id);
            console.log(res, res.data)
            setLoading(false);
            return true;
        } catch (e) {
            setLoading(false);
            return false;
        }
    }

    const getAllDefaultDeliveryPersonOrderStack = async ({page, size, id, areaName, expressDelivery}) => {
        try {
            setLoading(true);
            const res = await OrderService.getAllDefaultDeliveryPersonOrderStack(page, size, id, areaName, expressDelivery);
            console.log(res)
            setDefaultDeliveryPersonOrderStack(res.data.content);
            setTotalElements(res.data.totalElements);
            setLoading(false);
            return res.data.content;
        } catch (e) {
            setLoading(false);
            setErrorMsg("Can't load data, please Check the console!")
            console.log(e);
            return null;
        }
    }

    const getAllDefaultPharmacyOrderStack = async ({page, size, id, areaName, expressDelivery}) => {
        try {
            setLoading(true);
            const res = await OrderService.getAllDefaultPharmacyOrderStack(page, size, id, areaName, expressDelivery);
            console.log(res)
            setDefaultPharmacyOrderStack(res.data.content);
            setTotalElements(res.data.totalElements);
            setLoading(false);
            return res.data.content;
        } catch (e) {
            setLoading(false);
            setErrorMsg("Can't load data, please Check the console!")
            console.log(e);
            return null;
        }
    }

    return (
        <OrderContext.Provider
            value={{
                loading,
                errorMsg,
                orders,
                order,
                setOrder,
                orderCalculation,
                calculationLoading,
                stackedOrders,
                getAllStackedOrders,
                orderTracking,
                getOrderTrackingById,
                handleChangeQuantity,
                addItemHandle,
                handleDeleteItem,
                processOrderCalculation,
                defaultDeliveryPersonOrderStack,
                defaultPharmacyOrderStack,
                totalElements,
                orderTrackingLoading,
                getAllOrders,
                updateOrder,
                deleteOrder,
                getOrderById,
                addOrder,
                processOrder,
                pendingOrdersForDeliveryPerson,
                loadingPendingOrdersForDeliveryPerson,
                getAllPendingOrdersForDeliveryPerson,
                assignOrderToDeliveryPerson,
                prescriptionProducts,
                hasNonDigitalPrescription,
                searchPrescriptionProducts,
                cancelOrderById,
                getAllDefaultDeliveryPersonOrderStack,
                getAllDefaultPharmacyOrderStack,
                editedOrder,
                getEditedOrderById,
                updateLoading
            }}
        >
            {children}
        </OrderContext.Provider>
    );
}

export default OrderContextProvider;
