import { Modal } from "antd";

export default (error) => {
  const message = error.response ? error.response.data.message : error.message;
  let secondsToGo = 5;
  const modal = Modal["error"]({
    title: message,
    content: `${secondsToGo}`,
  });
  const timer = setInterval(() => {
    secondsToGo -= 1;
    modal.update({
      content: `${secondsToGo}`,
    });
  }, 1000);
  setTimeout(() => {
    clearInterval(timer);
    modal.destroy();
  }, secondsToGo * 1000);
};
