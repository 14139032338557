import React, {createContext, useState} from 'react';
import CustomerGroupService from "../services/CustomerGroupService";
import confirmationModal from "../components/common/ConfirmationModal";

export const CustomerGroupContext = createContext("CustomerGroupContext");


const CustomerGroupContextProvider = ({children}) => {

    const [customerGroups, setCustomerGroups] = useState(null);
    const [customerGroup, setCustomerGroup] = useState();
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [totalElements, setTotalElements] = useState('');

    async function getAllCustomerGroups({page, size, id, customerGroupsName}) {

        try {
            setLoading(true);
            setErrorMsg('');
            const res = await CustomerGroupService.getAllCustomerGroups(page, size, id, customerGroupsName);
            setCustomerGroups(res.data.content)
            setTotalElements(res.data.totalElements);
            setLoading(false);
            return res.data.content;
        } catch (error) {
            setErrorMsg("Can not load CustomerGroups. Please check the console.");
            console.log("error", console.error());
            return false;
        }

    }

    async function getCustomerGroupById(id) {

        try {
            setLoading(true);
            setErrorMsg('');
            const res = await CustomerGroupService.getCustomerGroupById(id);
            setCustomerGroup(res.data);
            setLoading(false);
            return 200;
        } catch (error) {
            setLoading(false);
            setErrorMsg("Can not load CustomerGroups. Please check the console.");
            console.log("error", error)
            return error.response ? error.response.status : 500;
        }

    }

    async function addCustomerGroup(data) {

        try {
            setErrorMsg('');
            setLoading(true);
            const res = await CustomerGroupService.addCustomerGroup(data)
            setCustomerGroup(res.data);
            setLoading(false);
            confirmationModal("CustomerGroup saved successfully. ", "success");
            return true;
        } catch (err) {
            const message = err.response ? err.response.data.message : err.message;
            setErrorMsg("Can not add CustomerGroups. Please check the console.");
            console.log("post customer_group error", message);
            confirmationModal("Can not save CustomerGroup. " + message, "error");
            return false;
        }

    }

    async function updateCustomerGroup(data, id) {
        try {
            setLoading(true);
            setErrorMsg('');
            const res = await CustomerGroupService.updateCustomerGroup(data, id);
            setCustomerGroup(res.data);
            setLoading(false);
            confirmationModal(`Customer Group updated successfully`, "success");
            return true;
        } catch (error) {
            setErrorMsg("Can not update CustomerGroup. Please check the console.");
            console.log("CustomerGroup update error.", error);
            confirmationModal(`Can't update Customer Group`, "success");
            return false;
        }

    }

    async function deleteCustomerGroup(id) {

        if (!window.confirm("Are you sure you want to delete this CustomerGroup ?")) return;

        try {
            setLoading(true);
            setErrorMsg('');
            await CustomerGroupService.deleteCustomerGroup(id);
            let updatedCustomerGroups = customerGroups;
            updatedCustomerGroups = customerGroups.filter(e => e.id !== id);
            setCustomerGroups(updatedCustomerGroups);
            setLoading(false);
            return true;
        } catch (error) {
            setErrorMsg("Can not delete CustomerGroups. Please check the console.");
            console.log("CustomerGroup delete error.", error);
            return false;
        }
    }


    return (
        <CustomerGroupContext.Provider
            value={{
                customerGroups,
                customerGroup,
                getCustomerGroupById,
                loading,
                errorMsg,
                totalElements,
                getAllCustomerGroups,
                updateCustomerGroup,
                deleteCustomerGroup,
                addCustomerGroup
            }}
        >
            {children}
        </CustomerGroupContext.Provider>
    );
}

export default CustomerGroupContextProvider;