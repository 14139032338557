import React, {createContext, useState} from 'react';
import Notification from "../components/common/Notification";
import ReferralHistoryService from "../services/ReferralHistoryService";

export const ReferralHistoryContext = createContext("ReferralHistoryContext");

const ReferralHistoryContextProvider = ({children}) => {

    const [referralHistoryList, setReferralHistoryList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [totalElements, setTotalElements] = useState(0);

    const getAllReferralHistory = async params => {

        try {
            setLoading(true);
            setErrorMsg('');

            const res = await ReferralHistoryService.getAllReferralHistory(params);

            setReferralHistoryList(res.data.content);
            setTotalElements(res.data.totalElements);
            setLoading(false);

            return res.data.content;
        } catch (error) {
            setLoading(false);
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }

    }

    return (
        <ReferralHistoryContext.Provider
            value={{
                referralHistoryList,
                getAllReferralHistory,
                totalElements,
                loading,
                errorMsg
            }}
        >
            {children}
        </ReferralHistoryContext.Provider>
    );
}

export default ReferralHistoryContextProvider;