import React, {createContext, useState} from 'react';
import OrderRoutingScheduleService from "../services/OrderRoutingScheduleService";

export const OrderRoutingScheduleContext = createContext("OrderRoutingScheduleContext");

const OrderRoutingScheduleContextProvider = ({children}) => {

    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [totalElements, setTotalElements] = useState(0);
    const [orderRoutingSchedules, setOrderRoutingSchedules] = useState(null);

    const getAllOrderRoutingSchedule = async (params) => {
        try {
            setLoading(true);
            setErrorMsg("");

            const res = await OrderRoutingScheduleService.getAllOrderRoutingSchedule(params);

            setOrderRoutingSchedules(res.data.content);
            setTotalElements(res.data.totalElements);
            setLoading(false);
            return res.data.content;
        } catch (error) {

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }

            setLoading(false);
            setErrorMsg(_error);
            return false;
        }


    }


    return (
        <OrderRoutingScheduleContext.Provider
            value={{
                loading,
                errorMsg,
                totalElements,
                orderRoutingSchedules,
                getAllOrderRoutingSchedule
            }}
        >
            {children}
        </OrderRoutingScheduleContext.Provider>
    );
};

export default OrderRoutingScheduleContextProvider;
