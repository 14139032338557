import React, {createContext, useState} from 'react';
import SubscriptionPlanService from "../services/SubscriptionPlanService";
import confirmationModal from "../components/common/ConfirmationModal";

export const SubscriptionPlanContext = createContext("SubscriptionPlanContext");

const SubscriptionPlanContextProvider = ({children}) => {

    const [subscriptionPlans, setSubscriptionPlans] = useState(null);
    const [subscriptionPlan, setSubscriptionPlan] = useState(null);
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');

    async function getAllSubscriptionPlans(params) {

        try {
            setLoading(true);
            setErrorMsg('');
            const res = await SubscriptionPlanService.getAllSubscriptionPlans(params);
            setSubscriptionPlans(res.data.content)
            setLoading(false);
            return res.data.content;
        } catch (error) {
            setLoading(false);
            setErrorMsg("Can not load SubscriptionPlans. Please check the console.");
            console.log("error", console.error())
            return false;
        }

    }

    async function addSubscriptionPlan(data) {

        try {
            setErrorMsg('');
            setLoading(true);
            const res = await SubscriptionPlanService.addSubscriptionPlan(data)
            setSubscriptionPlan(res.data)
            confirmationModal(`Subscription Plan Id - ${res.data.id} saved successfully. `, "success");
            setLoading(false);
            return true;
        } catch (err) {
            setLoading(false);
            const message = err.response ? err.response.data.message : err.message;
            setErrorMsg("Can not add SubscriptionPlans. Please check the console.");
            console.log("post subscription_plan error", message);
            confirmationModal("Can not save SubscriptionPlan. " + message, "error");
            return false;
        }

    }

    async function updateSubscriptionPlan(DATA, id) {
        try {
            setLoading(true);
            setErrorMsg('');
            const res = await SubscriptionPlanService.updateSubscriptionPlan(DATA, id);
            setSubscriptionPlan(res.data);
            confirmationModal(`Subscription Plan Id - ${res.data.id} updated successfully. `, 'success');
            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);
            confirmationModal("Can not update SubscriptionPlan.", 'error');
            setErrorMsg("Can not update SubscriptionPlan. Please check the console.");
            console.log("SubscriptionPlan update error.", error);
            return false;
        }

    }

    async function deleteSubscriptionPlan(id) {

        if (!window.confirm("Are you sure you want to delete this SubscriptionPlan ?")) return;

        try {
            setLoading(true);
            setErrorMsg('');
            await SubscriptionPlanService.deleteSubscriptionPlan(id);
            let updatedSubscriptionPlans = subscriptionPlans;
            updatedSubscriptionPlans = subscriptionPlans.filter(e => e.id !== id);
            setSubscriptionPlans(updatedSubscriptionPlans);
            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);
            confirmationModal("Can not delete SubscriptionPlans. Please check the console.", 'error');
            setErrorMsg("Can not delete SubscriptionPlans. Please check the console.");
            console.log("SubscriptionPlan delete error.", error);
            return false;
        }
    }


    async function getSubscriptionPlanById(id) {
        try {
            setLoading(true);
            const res = await SubscriptionPlanService.getSubscriptionPlanById(id);
            setSubscriptionPlan(res.data);
            setLoading(false);
            return res.data;
        } catch (error) {
            setLoading(false)
            confirmationModal(error.response ? error.response.data : error.message, "error");
            console.log("Can not get SubscriptionPlan by Id.")
        }
    }

    async function getSubscriptionPlanByName(Name) {
        try {
            setLoading(true);
            const res = await SubscriptionPlanService.getSubscriptionPlanByName(Name);
           if(res) {
               setSubscriptionPlans([res.data]);
           }
            setLoading(false);
            return res.data;
        } catch (error) {
            setLoading(false)
            confirmationModal(error.response ? error.response.data : error.message, "error");
            console.log("Can not get SubscriptionPlan by Id.")
        }
    }


    return (
        <SubscriptionPlanContext.Provider
            value={{
                subscriptionPlans,
                subscriptionPlan,
                loading,
                errorMsg,
                getAllSubscriptionPlans,
                updateSubscriptionPlan,
                deleteSubscriptionPlan,
                getSubscriptionPlanById,
                addSubscriptionPlan,
                getSubscriptionPlanByName
            }}
        >
            {children}
        </SubscriptionPlanContext.Provider>
    );
}

export default SubscriptionPlanContextProvider;