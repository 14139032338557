import React, {createContext, useState} from 'react';
import BillService from "../services/BillService";
import Notification from "../components/common/Notification";

export const BillContext = createContext("BillContext");


const BillContextProvider = ({children}) => {

    const [bills, setBills] = useState(null);
    const [bill, setBill] = useState(null);
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [totalElements, setTotalElements] = useState(0);

    async function getAllBills({page, size, id, billType}) {

        try {
            setLoading(true);
            setErrorMsg('');

            const res = await BillService.getAllBills(page, size, id, billType);

            setBills(res.data.content);
            setTotalElements(res.data.totalElements);
            setLoading(false);

            return res.data.content;
        } catch (error) {
            setLoading(false);
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }

    async function getBillById(id) {

        try {
            setLoading(true);
            setErrorMsg('');
            const res = await BillService.getBillById(id);
            setBill(res.data)
            setLoading(false);
            return res.data;
        } catch (error) {
            setLoading(false);
            setErrorMsg('Can not load bill. Please check the console.');
            console.log("error", console.error())
            return false;
        }

    }


    return (
        <BillContext.Provider
            value={{
                bills,
                bill,
                getAllBills,
                getBillById,
                totalElements,
                loading,
                errorMsg

            }}
        >
            {children}
        </BillContext.Provider>
    );
}

export default BillContextProvider;