import axios from "axios";
import * as URL from "../../helpers/Constants";

export default class PMSSaleService {

    static getSaleHistoryListByPharmacyId(id, params) {
        return axios.get(`${URL.PMS_GET_ALL_SALE_BY_PHARMACY_ID}/${id}`, {
            params, ...PMSSaleService.getAuthHeader()
        });
    }

    static getSaleById(pharmacyId, id) {
        return axios.get(`${URL.PMS_GET_SALE_BY_ID}/${pharmacyId}/${id}`, PMSSaleService.getAuthHeader());
    }

    static createSale(pharmacyId, data) {
        return axios.post(`${URL.PMS_CREATE_SALES_BY_PHARMACY_ID}/${pharmacyId}`, data, PMSSaleService.getAuthHeader());
    }

    static editSale(id, pharmacyId, data, params) {
        return axios.put(`${URL.PMS_UPDATE_SALES_BY_ID_BY_PHARMACY_ID}/${id}/${pharmacyId}`, data, {
            ...PMSSaleService.getAuthHeader(), params
        });
    }

    static cancelSale(id, pharmacyId, data) {
        return axios.put(`${URL.PMS_UPDATE_SALES_BY_ID_BY_PHARMACY_ID}/${id}/${pharmacyId}`, data, {
            ...PMSSaleService.getAuthHeader(), params: {isCanceled: true}
        });
    }

    static getAuthHeader() {
        const accessToken = localStorage.getItem("oushodsheba_access_token");
        return {headers: {"Authorization": `Bearer ${accessToken}`}};
    }

}
