import React, {createContext, useState} from 'react';
import TemporaryOrderService from "../services/TemporaryOrderService";
import confirmationModal from "../components/common/ConfirmationModal";

export const TemporaryOrderContext = createContext("TemporaryOrderContext");

const TemporaryOrderContextProvider = ({children}) => {

    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [totalElements, setTotalElements] = useState(0);

    const [temporaryOrders, setTemporaryOrders] = useState([]);
    const [temporaryOrder, setTemporaryOrder] = useState(null)

    const getAllTemporaryOrders = async ({page, size}) => {
        try {
            setLoading(true);
            const res = await TemporaryOrderService.getAllDeliveryPlans(page, size);
            setTemporaryOrders(res.data.content);
            setTotalElements(res.data.totalElements);
            setLoading(false);
            return res.data.content;
        } catch (error) {
            setLoading(false);
            setErrorMsg("Can't load Temporary Orders, Please check the console.")
            console.log(error);
            return false;
        }
    }


    const createTemporaryOrder = async (data) => {
        try {
            setLoading(true);
            const res = await TemporaryOrderService.createTemporaryOrder(data);
            setTemporaryOrders(res.data)
            console.log(res.data);
            confirmationModal("temporary_order created successfully !", "success");
            return res.data;
        } catch (error) {
            setLoading(false);
            setErrorMsg("Can't load Temporary Orders, Please check the console.")
            console.log(error);
            confirmationModal("temporary_order created failed !", "error");
            return false;
        }
    }


    const updateTemporaryOrderById = async (data, id) => {
        try {
            setLoading(true);
            const res = await TemporaryOrderService.updateTemporaryOrderById(data, id);
            setTemporaryOrders(res.data)
            console.log(res.data);
            confirmationModal("temporary_order updated successfully !", "success");
            return true;
        } catch (error) {
            setLoading(false);
            setErrorMsg("Can't load Temporary Orders, Please check the console.")
            console.log(error);
            confirmationModal("temporary_order update failed !", "error");
            return false;
        }
    }

    const deleteTemporaryOrder = async (id) => {
        try {
            setLoading(true);
            await TemporaryOrderService.deleteTemporaryOrder(id);
            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);
            setErrorMsg("Can't load Temporary Orders, Please check the console.")
            console.log(error);
            return false;
        }
    }

    const getTemporaryOrderById = async (id) => {
        try {
            setLoading(true);
            const res = await TemporaryOrderService.getTemporaryOrderById(id);
            setTemporaryOrder(res.data);
            return res.data;
        } catch (error) {
            setLoading(false);
            setErrorMsg("Can't load Temporary Orders, Please check the console.")
            console.log(error);
            return false;
        }
    }


    return (
        <TemporaryOrderContext.Provider
            value={{
                loading,
                errorMsg,
                temporaryOrders,
                temporaryOrder,
                totalElements,
                getAllTemporaryOrders,
                createTemporaryOrder,
                updateTemporaryOrderById,
                deleteTemporaryOrder,
                getTemporaryOrderById
            }}
        >
            {children}
        </TemporaryOrderContext.Provider>
    );
};

export default TemporaryOrderContextProvider;
