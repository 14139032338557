import * as URL from "../helpers/Constants";
import axios from "axios";

export default class OfferService {
    static getAllOffers(page, size, id, offerType) {
        return axios.get(URL.GET_ALL_OFFERS, {...OfferService.getAuthHeader(), params: {page, size, id, offerType}});
    }

    static getOfferById(id) {
        return axios.get(`${URL.GET_OFFER_BY_ID}/${id}`, OfferService.getAuthHeader());
    }

    static addOffer(data) {
        return axios.post(URL.POST_OFFER, data, OfferService.getAuthHeader());
    }


    static getAuthHeader() {
        const accessToken = localStorage.getItem("oushodsheba_access_token");
        const options = {headers: {"Authorization": `Bearer ${accessToken}`}}
        return options;
    }
}