import React, {createContext, useState} from 'react';
import ReviewService from "../services/ReviewService";

export const ReviewContext = createContext("ReviewContext");

const ReviewContextProvider = ({children}) => {

    const [reviews, setReviews] = useState(null);
    const [review, setReview] = useState(null);
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [totalElements, setTotalElements] = useState(0);

    async function getAllReviews({page, size, id, reviewType}) {

        try {
            setLoading(true);
            setErrorMsg('');
            const res = await ReviewService.getAllReviews(page, size, id, reviewType);
            setReviews(res.data.content);
            setTotalElements(res.data.totalElements);
            setLoading(false);
            return res.data.content;
        } catch (error) {
            setLoading(false);
            setErrorMsg('Can not load reviews. Please check the console.');
            console.log("error", console.error())
            return false;
        }

    }

    async function getReviewById(id) {

        try {
            setLoading(true);
            setErrorMsg('');
            const res = await ReviewService.getReviewById(id);
            setReview(res.data)
            setLoading(false);
            return res.data;
        } catch (error) {
            setLoading(false);
            setErrorMsg('Can not load review. Please check the console.');
            console.log("error", console.error())
            return false;
        }

    }


    return (
        <ReviewContext.Provider
            value={{
                reviews,
                review,
                getAllReviews,
                getReviewById,
                totalElements,
                loading,
                errorMsg

            }}
        >
            {children}
        </ReviewContext.Provider>
    );
}

export default ReviewContextProvider;