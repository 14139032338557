import React, {createContext, useState} from 'react';
import ProfitSharingPlanService from "../services/ProfitSharingPlanService";
import confirmationModal from "../components/common/ConfirmationModal";

export const ProfitSharingPlanContext = createContext("ProfitSharingPlanContext");

const ProfitSharingPlanContextProvider = ({children}) => {

    const [profitSharingPlans, setProfitSharingPlans] = useState(null);
    const [loading, setLoading] = useState(false);
    const [profitSharingPlan, setProfitSharingPlan] = useState(null);
    const [errorMsg, setErrorMsg] = useState('');

    async function getAllProfitSharingPlans() {

        try {
            setLoading(true);
            setErrorMsg('');
            const res = await ProfitSharingPlanService.getAllProfitSharingPlans();
            setProfitSharingPlans(res.data.content)
            setLoading(false);
            return res.data.content;
        } catch (error) {
            setLoading(false);
            setErrorMsg('Can not load profit sharing plan. Please check the console.');
            console.log("error", console.error())
            return false;
        }

    }

    async function getProfitSharingPlanById(id) {

        try {
            setLoading(true);

            setErrorMsg('');
            const res = await ProfitSharingPlanService.getProfitSharingPlanById(id);
            setProfitSharingPlan(res.data);
            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);
            setErrorMsg("Can not load ProfitSharingPlans. Please check the console.");
            console.log("error", error)
            return false;
        }

    }

    async function addProfitSharingPlan(data) {

        try {
            const res = await ProfitSharingPlanService.addProfitSharingPlan(data)
            setProfitSharingPlans(profitSharingPlans ? [...profitSharingPlans, res.data] : [res.data]);
            return true;
        } catch (err) {
            const message = err.response ? err.response.data.message : err.message;
            console.log("post profitSharingPlan error", message);
            confirmationModal("Can not save ProfitSharingPlan. " + message, "error");
            return false;
        }

    }

    async function updateProfitSharingPlan(data, id) {
        try {
            const res = await ProfitSharingPlanService.updateProfitSharingPlan(data, id)
            let updatedProfitSharingPlans = profitSharingPlans;
            updatedProfitSharingPlans = profitSharingPlans.map(e => {
                if (e.id === id) e = res.data;
                return e;
            })

            setProfitSharingPlans(updatedProfitSharingPlans);
            return true;
        } catch {
            return false;
        }

    }

    async function deleteProfitSharingPlan(id) {

        if (!window.confirm("Are you sure you want to delete this ProfitSharingPlan ?")) return;

        try {
            await ProfitSharingPlanService.deleteProfitSharingPlan(id);
            let updatedProfitSharingPlans = profitSharingPlans;
            updatedProfitSharingPlans = profitSharingPlans.filter(e => e.id !== id)
            setProfitSharingPlans(updatedProfitSharingPlans);
            return true;
        } catch {
            return false;
        }

    }

    const changeProfitSharingPlanStatus = async (email, status) => {

        if (!window.confirm(`Are you sure you want to change this ProfitSharingPlan status to ${status} ?`)) return;

        try {
            await ProfitSharingPlanService.changeProfitSharingPlanStatus(email, status);
            let updatedProfitSharingPlans = profitSharingPlans;
            updatedProfitSharingPlans = profitSharingPlans.map(e => {
                if (e.email === email) e.status = status;
                return e;
            })
            setProfitSharingPlans(updatedProfitSharingPlans);
            return true;
        } catch {
            return false;
        }

    }


    return (
        <ProfitSharingPlanContext.Provider
            value={{
                profitSharingPlans,
                profitSharingPlan,
                getProfitSharingPlanById,
                loading,
                errorMsg,
                getAllProfitSharingPlans,
                updateProfitSharingPlan,
                deleteProfitSharingPlan,
                addProfitSharingPlan,
                changeProfitSharingPlanStatus
            }}
        >
            {children}
        </ProfitSharingPlanContext.Provider>
    );
}

export default ProfitSharingPlanContextProvider;