import * as URL from "../helpers/Constants";
import axios from "axios";

export default class pharmacistService {

    static getAllPharmacists(page, size, id, name, email) {
        return axios.get(URL.GET_ALL_PHARMACISTS, {
            ...pharmacistService.getAuthHeader(),
            params: {page, size, id, name, email}
        });
    }

    static getPharmacistById(id) {
        return axios.get(`${URL.GET_PHARMACIST_BY_ID}/${id}`, pharmacistService.getAuthHeader());
    }

    static getProfile() {
        return axios.get(URL.GET_PHARMACIST_PROFILE, pharmacistService.getAuthHeader());
    }

    static addPharmacistFromAdmin(data) {
        return axios.post(URL.POST_PHARMACIST_FROM_ADMIN, data, pharmacistService.getAuthHeader());
    }

    static addPharmacistFromPharmacyOwner(data) {
        return axios.post(URL.POST_PHARMACIST_FROM_PHARMACY_OWNER, data, pharmacistService.getAuthHeader());
    }

    static updatePharmacistFromPharmacyOwner(data, id) {
        return axios.put(`${URL.UPDATE_PHARMACIST_FROM_PHARMACY_OWNER}/${id}`, data, pharmacistService.getAuthHeader());
    }

    static updatePharmacistFromAdmin(data, id) {
        return axios.put(`${URL.UPDATE_PHARMACIST_FROM_ADMIN}/${id}`, data, pharmacistService.getAuthHeader());
    }

    static deletePharmacist(id) {
        return axios.delete(`${URL.DELETE_PHARMACIST}/${id}`, pharmacistService.getAuthHeader());
    }

    static getAuthHeader() {
        const accessToken = localStorage.getItem("oushodsheba_access_token");
        const options = {headers: {"Authorization": `Bearer ${accessToken}`}}
        return options;
    }

    // static changePharmacistStatus(email, status) {
    //     return axios.post(`${URL.UPDATE_PHARMACIST_STATUS}/${email}?status=${status}`)
    // }
}