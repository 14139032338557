import axios from "axios";
import * as URL from "../helpers/Constants";

export default class UploadProductFilesService {

    static updateProductByFiles(data) {
        return axios.put(`${URL.UPLOAD_PRODUCT_FILES}`, data, UploadProductFilesService.getAuthHeader());
    }

    static getAuthHeader() {
        const accessToken = localStorage.getItem("oushodsheba_access_token");
        return {headers: {"Authorization": `Bearer ${accessToken}`, 'content-type' :'multipart/form-data'}};
    }
}
