import * as URL from "../../helpers/Constants";
import axios from "axios";
import { getPharmacyId } from "../../helpers/Utils";

export default class PMSTransactionAccountServices {
  static getTransactionAccounts(params) {
    return axios.get(
      `${URL.PMS_GET_PHARMACY_TRANSACTION_ACCOUNTS}/${getPharmacyId()}`,
      {
        ...PMSTransactionAccountServices.getAuthHeader(),
        params,
      }
    );
  }

  static getTransactionAccountById(id) {
    return axios.get(
      `${URL.PMS_GET_PHARMACY_TRANSACTION_ACCOUNTS}/${id}/${getPharmacyId()}`,
      PMSTransactionAccountServices.getAuthHeader()
    );
  }

  static createTransactionAccount(params) {
    return axios.post(
      `${URL.PMS_CREATE_PHARMACY_TRANSACTION_ACCOUNT}/${getPharmacyId()}`,
      params,
      PMSTransactionAccountServices.getAuthHeader()
    );
  }

  static updateTransactionAccount(id, data) {
    return axios.put(
      `${URL.PMS_UPDATE_PHARMACY_TRANSACTION_ACCOUNT}/${id}/${getPharmacyId()}`,
      data,
      PMSTransactionAccountServices.getAuthHeader()
    );
  }

  static getAuthHeader() {
    const accessToken = localStorage.getItem("oushodsheba_access_token");
    return {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    };
  }
}
