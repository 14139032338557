import * as URL from "../helpers/Constants";
import axios from "axios";

export default class CustomerService {
    static getAllCustomers(params) {
        return axios.get(URL.GET_ALL_CUSTOMERS, {
            ...CustomerService.getAuthHeader(),
            params
        });
    }

    static getCustomerById(id) {
        return axios.get(`${URL.GET_CUSTOMER_BY_ID}/${id}`, CustomerService.getAuthHeader());
    }

    static addCustomer(data) {
        return axios.post(URL.POST_CUSTOMER, data, CustomerService.getAuthHeader());
    }

    static updateCustomer(data, id) {
        return axios.put(`${URL.UPDATE_CUSTOMER}/${id}`, data, CustomerService.getAuthHeader());
    }

    static deleteCustomer(id) {
        return axios.delete(`${URL.DELETE_CUSTOMER}/${id}`, CustomerService.getAuthHeader());
    }

    static changeCustomerStatus = (email, status) => {
        return axios.put(``, CustomerService.getAuthHeader());
    }

    static getAuthHeader() {
        const accessToken = localStorage.getItem("oushodsheba_access_token");
        const options = {headers: {"Authorization": `Bearer ${accessToken}`}}
        return options;
    }
}