import * as URL from "../helpers/Constants";
import axios from "axios";

export default class AreaService {
    static getAllAreas(page, size, id, areaName, districtName) {
        return axios.get(URL.GET_ALL_AREAS, {
            ...AreaService.getAuthHeader(),
            params: {page, size, id, areaName, districtName}
        });
    }

    static getAreaById = id => {
        return axios.get(`${URL.GET_AREA_BY_ID}/${id}`, AreaService.getAuthHeader());
    }

    static addArea = (data) => {
        return axios.post(URL.POST_AREA, data, AreaService.getAuthHeader());
    }

    static updateArea = (data, id) => {
        return axios.put(`${URL.UPDATE_AREA}/${id}`, data, AreaService.getAuthHeader());
    }

    static deleteArea = (id) => {
        return axios.delete(`${URL.DELETE_AREA}/${id}`, AreaService.getAuthHeader());
    }

    static getAuthHeader = () => {
        const accessToken = localStorage.getItem("oushodsheba_access_token");
        const options = {headers: {"Authorization": `Bearer ${accessToken}`}}
        return options;
    }

    // static changeAreaStatus(email, status) {
    //     return axios.post(`${URL.UPDATE_GENERIC_STATUS}/${email}?status=${status}`)
    // }
}