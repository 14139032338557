import * as URL from "../helpers/Constants";
import axios from 'axios';

export default class OrderRoutingScheduleService {


    static getAllOrderRoutingSchedule(params) {
        return axios.get(URL.GET_ALL_ORDER_ROUTING_SCHEDULE,
            {...OrderRoutingScheduleService.getAuthHeader(), params})
    }

    static getAuthHeader() {
        const accessToken = localStorage.getItem("oushodsheba_access_token");
        const options = {headers: {"Authorization": `Bearer ${accessToken}`}}
        return options;
    }
}
