import * as URL from "../helpers/Constants";
import axios from "axios";

export default class InvoiceService {
    static getAllInvoices(page, size, id) {
        return axios.get(URL.GET_ALL_INVOICES, {...InvoiceService.getAuthHeader(), params: {size, page, id}});
    }

    static getInvoiceById(id) {
        return axios.get(`${URL.GET_INVOICE_BY_ID}/${id}`, InvoiceService.getAuthHeader());
    }

    static getAuthHeader() {
        const accessToken = localStorage.getItem("oushodsheba_access_token");
        const options = {headers: {"Authorization": `Bearer ${accessToken}`}}
        return options;
    }
}