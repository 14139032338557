import React, {createContext, useState} from "react";
import PMSVendorServices from "../../services/pms_services/PMSVendorServices";
import Notification from "../../components/common/Notification";

export const PMSVendorContext = createContext("PMSVendorContext");

const PMSVendorContextProvider = ({children}) => {

    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    const [totalElements, setTotalElements] = useState(0);
    const [vendors, setVendors] = useState(null);
    const [vendor, setVendor] = useState(null);
    const [vendorDueReport, setVendorDueReport] = useState({
        totalDueVendor: 0,
        totalDue: 0
    });


    const getAllVendorsByPharmacyId = async (id, params) => {

        try {
            setLoading(true);
            setErrorMsg("");

            const res = await PMSVendorServices.getAllVendorsByPharmacyId(id, params);

            setVendorDueReport({
                totalDueVendor: res.headers.count,
                totalDue: res.headers.due
            });

            setVendors(res.data.content);
            setTotalElements(res.data.totalElements);
            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }

    const getAllLiteVendorsByPharmacyId = async ({page, size, id}) => {

        try {
            setLoading(true);
            setErrorMsg("");
            const res = await PMSVendorServices.getAllLiteVendorsByPharmacyId(page, size, id);
            setVendors(res.data.content);
            setTotalElements(res.data.totalElements);
            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }

    const getVendorByPharmacyIdById = async (pharmacyId, id) => {
        try {
            setLoading(true);
            setErrorMsg("");
            const res = await PMSVendorServices.getVendorByPharmacyIdById(pharmacyId, id);
            setVendor(res.data);
            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }

    const getVendorByPharmacyIdByLocalId = async (pharmacyId, localId) => {
        try {
            setLoading(true);

            const res = await PMSVendorServices.getVendorByPharmacyIdByLocalId(pharmacyId, localId);

            setVendor(res.data);
            setLoading(false);

            return true;
        } catch (error) {

            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }

    const clearVendor = () => {
        setVendor(null);
    }

    const _getVendorByPharmacyIdByLocalId = async (pharmacyId, localId) => {
        try {
            const res = await PMSVendorServices.getVendorByPharmacyIdByLocalId(pharmacyId, localId);
            return res.data;
        } catch (error) {

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }

            Notification("error", "ERROR", `${_error}`);
            return null;
        }
    }

    const createVendorByPharmacyId = async (id, data) => {

        try {
            setLoading(true);
            setErrorMsg("");
            await PMSVendorServices.createVendorByPharmacyId(id, data);
            Notification("success", "Success", "Supplier successfully added!");
            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }

    const updateVendorByIdByPharmacyId = async (id, pharmacyId, data) => {

        try {
            setLoading(true);
            setErrorMsg("");
            await PMSVendorServices.updateVendorByIdByPharmacyId(id, pharmacyId, data);
            Notification("success", "Updated", "Supplier successfully updated");
            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }

    const deleteVendorById = async (id) => {

        try {
            setLoading(true);
            setErrorMsg("");
            await PMSVendorServices.deleteVendorById(id);
            setVendors(vendors.filter(vendor => vendor.id !== id));
            Notification("success", "Deleted", "Supplier has been deleted!");
            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }


    return (
        <PMSVendorContext.Provider
            value={{
                loading,
                errorMsg,
                totalElements,
                vendors,
                vendor,
                vendorDueReport,
                getAllVendorsByPharmacyId,
                getAllLiteVendorsByPharmacyId,
                getVendorByPharmacyIdById,
                getVendorByPharmacyIdByLocalId,
                createVendorByPharmacyId,
                updateVendorByIdByPharmacyId,
                deleteVendorById,
                _getVendorByPharmacyIdByLocalId,
                clearVendor
            }}
        >
            {children}
        </PMSVendorContext.Provider>
    )
}

export default PMSVendorContextProvider;