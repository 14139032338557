import React, {createContext, useState} from 'react';
import PrescriptionService from "../services/PrescriptionService";
import confirmationModal from "../components/common/ConfirmationModal";

export const PrescriptionContext = createContext("PrescriptionContext");

const PrescriptionContextProvider = ({children}) => {

    const [prescriptions, setPrescriptions] = useState(null);
    const [prescription, setPrescription] = useState(null)
    const [digitalizedPrescriptions, setDigitalizedPrescriptions] = useState(null);
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [totalElements, setTotalElements] = useState('');

    async function getAllPrescriptions({page, size}) {

        try {
            setLoading(true);
            setErrorMsg('');
            const res = await PrescriptionService.getAllPrescriptions(0, page, size);
            setPrescriptions(res.data.content)
            setTotalElements(res.data.totalElements);
            setLoading(false);
            return res.data.content;
        } catch (error) {
            setLoading(false);
            setErrorMsg("Can not load Prescriptions. Please check the console.");
            console.log("error", console.error());
            return false;
        }

    }

    async function getPrescriptionById(id) {

        try {
            setLoading(true);
            setErrorMsg('');
            const res = await PrescriptionService.getPrescriptionById(id);
            setPrescription(res.data);
            setLoading(false);
            return res.data;
        } catch (error) {
            setLoading(false);
            setErrorMsg("Can not load Prescriptions. Please check the console.");
            console.log("error", error)
            return false;
        }

    }

    async function getAllDigitalizedPrescriptions({page, size, id, alias, patientName, doctorName}) {

        try {
            setLoading(true);
            setErrorMsg('');
            const res = await PrescriptionService.getAllPrescriptions(1, page, size, id, alias, patientName, doctorName);
            setDigitalizedPrescriptions(res.data.content);
            setTotalElements(res.data.totalElements);
            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);
            setErrorMsg("Can not load Prescriptions. Please check the console.");
            console.log("error", console.error());
            return false;
        }

    }

    async function addPrescription(data) {

        try {
            setErrorMsg('');
            setLoading(true);
            const res = await PrescriptionService.addPrescription(data);
            setPrescription(res.data)
            setLoading(false);
            confirmationModal("Prescription saved successfully. ", "success");
            return true;
        } catch (err) {
            setLoading(false);
            const message = err.response ? err.response.data.message : err.message;
            setErrorMsg("Can not add Prescriptions. Please check the console.");
            console.log("post prescription error", message);
            confirmationModal("Can not save Prescription. " + message, "error");
            return false;
        }

    }

    async function updatePrescription(data, id) {
        try {
            setLoading(true);
            setErrorMsg('');
            const res = await PrescriptionService.updatePrescription(data, id);

            setLoading(false);
            confirmationModal("Prescription saved successfully. ", "success");
            return res.data;
        } catch (error) {
            setLoading(false);
            const message = error.response ? error.response.data.message : error.message;
            setErrorMsg("Can not update Prescription. Please check the console.");
            console.log("Prescription update error.", error);
            confirmationModal("Can not save Prescription. " + message, "error");
            return false;
        }

    }

    async function deletePrescription(id) {

        if (!window.confirm("Are you sure you want to delete this Prescription ?")) return;

        try {
            setLoading(true);
            setErrorMsg('');
            await PrescriptionService.deletePrescription(id);
            let updatedPrescriptions = prescriptions;
            updatedPrescriptions = prescriptions.filter(e => e.id !== id);
            setPrescriptions(updatedPrescriptions);
            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);
            setErrorMsg("Can not delete Prescriptions. Please check the console.");
            console.log("Prescription delete error.", error);
            return false;
        }
    }


    return (
        <PrescriptionContext.Provider
            value={{
                prescriptions,
                prescription,
                getPrescriptionById,
                loading,
                errorMsg,
                totalElements,
                getAllPrescriptions,
                getAllDigitalizedPrescriptions,
                digitalizedPrescriptions,
                updatePrescription,
                deletePrescription,
                addPrescription
            }}
        >
            {children}
        </PrescriptionContext.Provider>
    );
}

export default PrescriptionContextProvider;