//modules
// export const ACCOUNT_MODULE = 'Account-Module';
// export const CART_MODULE = 'Cart-Module';
// export const CATEGORY_MODULE = 'Category-Module';
// export const COMPANY_MODULE = 'Company-Module';
// export const COUNTRY_MODULE = 'Country-Module';
// export const CUSTOMER_GROUP_MODULE = 'Customer-Group-Module';
// export const DASHBOARD_MODULE = 'Dashboard-Module';
// export const DISTRICT_MODULE = 'District-Module';
// export const GENERIC_MODULE = 'Generic-Module';
// export const HOSPITAL_MODULE = 'Hospital-Module';
// export const ORDER_MODULE = 'Order-Module';
// export const PHARMACY_MODULE = 'Account-Module';

export const PHARMACY_COUNT = true;
export const ALL = 'ALL';
export const READ_PRODUCT = 'READ_PRODUCT';
export const CREATE_PRODUCT = 'CREATE_PRODUCT';
export const MODIFY_PRODUCT = 'MODIFY_PRODUCT';
export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const READ_CATEGORY = 'READ_CATEGORY';
export const MODIFY_CATEGORY = 'MODIFY_CATEGORY';
export const CREATE_CATEGORY = 'CREATE_CATEGORY';

export const READ_PRODUCT_OF_PHARMACY = 'READ_PRODUCT_OF_PHARMACY';
export const MODIFY_PRODUCT_BY_PHARMACY = 'MODIFY_PRODUCT_BY_PHARMACY';
export const DELETE_PRODUCT_BY_PHARMACY = 'DELETE_PRODUCT_BY_PHARMACY';

export const READ_USER = 'READ_USER';
export const CREATE_USER = 'CREATE_USER';
export const MODIFY_USER = 'MODIFY_USER';
export const DELETE_USER = 'DELETE_USER';

export const READ_PHARMACY_OWNER = 'READ_PHARMACY_OWNER';
export const CREATE_PHARMACY_OWNER = 'CREATE_PHARMACY_OWNER';
export const MODIFY_PHARMACY_OWNER = 'MODIFY_PHARMACY_OWNER';
export const READ_ROLE = 'READ_ROLE';
export const CREATE_ROLE = 'CREATE_ROLE';
export const MODIFY_ROLE = 'MODIFY_ROLE';


export const READ_ORDER = 'READ_ORDER';
export const READ_VERIFICATION_PENDING_ORDER = 'READ_VERIFICATION_PENDING_ORDER';
export const PROCESS_VERIFICATION_PENDING_ORDER = 'PROCESS_VERIFICATION_PENDING_ORDER';


export const READ_CUSTOMER = 'READ_CUSTOMER';
export const CREATE_CUSTOMER = 'CREATE_CUSTOMER';
export const MODIFY_CUSTOMER = 'MODIFY_CUSTOMER';
export const DELETE_CUSTOMER = 'DELETE_CUSTOMER';


export const READ_CUSTOMER_GROUP = 'READ_CUSTOMER_GROUP';
export const CREATE_CUSTOMER_GROUP = 'CREATE_CUSTOMER_GROUP';
export const MODIFY_CUSTOMER_GROUP = 'MODIFY_CUSTOMER_GROUP';
export const DELETE_CUSTOMER_GROUP = 'DELETE_CUSTOMER_GROUP';


export const READ_PHARMACY = 'READ_PHARMACY';
export const CREATE_PHARMACY = 'CREATE_PHARMACY';
export const MODIFY_PHARMACY = 'MODIFY_PHARMACY';
export const DELETE_PHARMACY = 'DELETE_PHARMACY';

export const READ_PROFIT_SHARING_PLAN = 'READ_PROFIT_SHARING_PLAN';
export const CREATE_PROFIT_SHARING_PLAN = 'CREATE_PROFIT_SHARING_PLAN';
export const READ_COMPANY = 'READ_COMPANY';
export const CREATE_COMPANY = 'CREATE_COMPANY';
export const MODIFY_COMPANY = 'MODIFY_COMPANY';


export const READ_GENERIC = 'READ_GENERIC';
export const CREATE_GENERIC = 'CREATE_GENERIC';
export const MODIFY_GENERIC = 'MODIFY_GENERIC';
export const DELETE_GENERIC = 'DELETE_GENERIC';


export const READ_COUNTRY = 'READ_COUNTRY';
export const CREATE_COUNTRY = 'CREATE_COUNTRY';
export const MODIFY_COUNTRY = 'MODIFY_COUNTRY';
export const READ_PROFILE = 'READ_PROFILE';

export const MODIFY_PRESCRIPTION = "MODIFY_PRESCRIPTION";
export const READ_PRESCRIPTION = 'READ_PRESCRIPTION';
export const CREATE_PRESCRIPTION = 'CREATE_PRESCRIPTION';
export const DELETE_PRESCRIPTION = 'DELETE_PRESCRIPTION';

export const CREATE_PHARMACIST = "CREATE_PHARMACIST";
export const MODIFY_PHARMACIST = "MODIFY_PHARMACIST";
export const READ_PHARMACIST = "READ_PHARMACIST";
export const DELETE_PHARMACIST = "READ_PHARMACIST";


export const READ_DELIVERY_PERSON = "READ_DELIVERY_PERSON";
export const CREATE_DELIVERY_PERSON = "CREATE_DELIVERY_PERSON";
export const MODIFY_DELIVERY_PERSON = "MODIFY_DELIVERY_PERSON";
export const DELETE_DELIVERY_PERSON = "DELETE_DELIVERY_PERSON";

export const READ_DELIVERY_CHARGE = "READ_DELIVERY_CHARGE";
export const CREATE_DELIVERY_CHARGE = "CREATE_DELIVERY_CHARGE";
export const MODIFY_DELIVERY_CHARGE = "MODIFY_DELIVERY_CHARGE";
export const DELETE_DELIVERY_CHARGE = "DELETE_DELIVERY_CHARGE";

export const READ_PROMO = "READ_PROMO";
export const CREATE_PROMO = "CREATE_PROMO";
export const MODIFY_PROMO = "MODIFY_PROMO";
export const DELETE_PROMO = "DELETE_PROMO";

export const READ_SUBSCRIPTION_PLAN = "READ_SUBSCRIPTION_PLAN";
export const CREATE_SUBSCRIPTION_PLAN = "CREATE_SUBSCRIPTION_PLAN";
export const MODIFY_SUBSCRIPTION_PLAN = "MODIFY_SUBSCRIPTION_PLAN";
export const DELETE_SUBSCRIPTION_PLAN = "DELETE_SUBSCRIPTION_PLAN";

export const READ_THANA = "READ_THANA";
export const CREATE_THANA = "CREATE_THANA";
export const MODIFY_THANA = "MODIFY_THANA";
export const DELETE_THANA = "DELETE_THANA";

export const READ_DISTRICT = "READ_DISTRICT";
export const CREATE_DISTRICT = "CREATE_DISTRICT";
export const MODIFY_DISTRICT = "MODIFY_DISTRICT";
export const DELETE_DISTRICT = "DELETE_DISTRICT";


export const READ_AREA = "READ_AREA";
export const CREATE_AREA = "CREATE_AREA";
export const MODIFY_AREA = "MODIFY_AREA";
export const DELETE_AREA = "DELETE_AREA";

export const READ_OFFER = "READ_OFFER";
export const CREATE_OFFER = "CREATE_OFFER";

export const READ_EVENT = "READ_EVENT";

export const READ_INVOICE = "READ_INVOICE";

export const READ_BILL = "READ_BILL";

export const READ_REVIEW = "READ_REVIEW";

export const HOMEPAGE_PRODUCT_MANAGE = "HOMEPAGE_PRODUCT_MANAGE";

export const READ_BANNER = "READ_BANNER";
export const CREATE_BANNER = "CREATE_BANNER";
export const MODIFY_BANNER = "MODIFY_BANNER";
export const DELETE_BANNER = "DELETE_BANNER";

//TEMPORARY ORDER
export const CREATE_TEMPORARY_ORDER = "CREATE_TEMPORARY_ORDER"

export const READ_PHARMACY_ORDER_ACCOUNT = "READ_PHARMACY_ORDER_ACCOUNT";

// Test
export const READ_LAB_TEST = 'READ_LAB_TEST';
export const CREATE_LAB_TEST = 'CREATE_LAB_TEST';
export const MODIFY_LAB_TEST = 'MODIFY_LAB_TEST';
export const DELETE_LAB_TEST = 'DELETE_LAB_TEST';
export const READ_LAB_TEST_BY_CUSTOMER = 'READ_LAB_TEST_BY_CUSTOMER';

// --------------------------  pms ------------------------------

// pms-Customers===================
export const READ_PMS_CUSTOMER = 'READ_PMS_CUSTOMER';
export const CREATE_PMS_CUSTOMER = 'CREATE_PMS_CUSTOMER';
export const MODIFY_PMS_CUSTOMER = 'MODIFY_PMS_CUSTOMER';
export const DELETE_PMS_CUSTOMER = 'DELETE_PMS_CUSTOMER';
export const SYNC_PMS_CUSTOMER = 'SYNC_PMS_CUSTOMER';

// pms-Expense======================
export const READ_PMS_EXPENSE = 'READ_PMS_EXPENSE';
export const CREATE_PMS_EXPENSE = 'CREATE_PMS_EXPENSE';
export const MODIFY_PMS_EXPENSE = 'MODIFY_PMS_EXPENSE';
export const DELETE_PMS_EXPENSE = 'DELETE_PMS_EXPENSE';
export const SYNC_PMS_EXPENSE = 'SYNC_PMS_EXPENSE';

//pms-Inventory=====================
export const READ_PMS_INVENTORY = 'READ_PMS_INVENTORY';
export const CREATE_PMS_INVENTORY = 'CREATE_PMS_INVENTORY';
export const MODIFY_PMS_INVENTORY = 'MODIFY_PMS_INVENTORY';
export const DELETE_PMS_INVENTORY = 'DELETE_PMS_INVENTORY';
export const SYNC_PMS_INVENTORY = 'SYNC_PMS_INVENTORY';
export const READ_PMS_PAYMENT = 'READ_PMS_PAYMENT';
export const CREATE_PMS_PAYMENT = 'CREATE_PMS_PAYMENT';
export const MODIFY_PMS_PAYMENT = 'MODIFY_PMS_PAYMENT';
export const DELETE_PMS_PAYMENT = 'DELETE_PMS_PAYMENT';
export const SYNC_PMS_PAYMENT = 'SYNC_PMS_PAYMENT';
export const READ_PMS_PRODUCT = 'READ_PMS_PRODUCT';
export const CREATE_PMS_PRODUCT = 'CREATE_PMS_PRODUCT';
export const MODIFY_PMS_PRODUCT = 'MODIFY_PMS_PRODUCT';
export const DELETE_PMS_PRODUCT = 'DELETE_PMS_PRODUCT';
export const SYNC_PMS_PRODUCT = 'SYNC_PMS_PRODUCT';
export const READ_PMS_SALE = 'READ_PMS_SALE';
export const CREATE_PMS_SALE = 'CREATE_PMS_SALE';
export const MODIFY_PMS_SALE = 'MODIFY_PMS_SALE';
export const DELETE_PMS_SALE = 'DELETE_PMS_SALE';
export const SYNC_PMS_SALE = 'SYNC_PMS_SALE';
export const READ_PMS_VENDOR = 'READ_PMS_VENDOR';
export const CREATE_PMS_VENDOR = 'CREATE_PMS_VENDOR';
export const MODIFY_PMS_VENDOR = 'MODIFY_PMS_VENDOR';
export const DELETE_PMS_VENDOR = 'DELETE_PMS_VENDOR';
export const SYNC_PMS_VENDOR = 'SYNC_PMS_VENDOR';
export const READ_PMS_REPORT = 'READ_PMS_REPORT';
export const READ_PMS_CUSTOM_REPORT = 'READ_PMS_CUSTOM_REPORT';
export const READ_PMS_SALE_SUMMERY = 'READ_PMS_SALE_SUMMERY';
export const READ_PMS_INVENTORY_SUMMERY = 'READ_PMS_INVENTORY_SUMMERY';
export const READ_PMS_PROFIT = 'READ_PMS_PROFIT_SUMMERY';
export const READ_PMS_REQUISITION = 'READ_PMS_REQUISITION';
export const CREATE_PMS_REQUISITION = 'CREATE_PMS_REQUISITION';
export const MODIFY_PMS_REQUISITION = 'MODIFY_PMS_REQUISITION';
export const DELETE_PMS_REQUISITION = 'DELETE_PMS_REQUISITION';
export const SYNC_PMS_REQUISITION = 'SYNC_PMS_REQUISITION';

// pms-Pharmacist========================
export const CREATE_PHARMACIST_BY_PHARMACY = 'CREATE_PHARMACIST_BY_PHARMACY';
export const READ_PHARMACIST_OF_PHARMACY = 'READ_PHARMACIST_OF_PHARMACY';
export const MODIFY_PHARMACIST_BY_PHARMACY = 'MODIFY_PHARMACIST_BY_PHARMACY';
export const DELETE_PHARMACIST_BY_PHARMACY = 'DELETE_PHARMACIST_BY_PHARMACY';
export const CASH_RECEIVED_PMS_SALE = 'CASH_RECEIVED_PMS_SALE';
export const PHARMACY_CHECK_AS_ONLINE = 'PHARMACY_CHECK_AS_ONLINE';
export const READ_PMS_PHARMACY_USER = 'READ_PMS_PHARMACY_USER';
export const CREATE_PMS_PHARMACY_USER = 'CREATE_PMS_PHARMACY_USER';
export const MODIFY_PMS_PHARMACY_USER = 'MODIFY_PMS_PHARMACY_USER';
export const DELETE_PMS_PHARMACY_USER = 'DELETE_PMS_PHARMACY_USER';
export const SYNC_PMS_PHARMACY_USER = 'SYNC_PMS_PHARMACY_USER';

// pms-Transactions=====================
export const READ_PMS_PHARMACY_USER_TRANSACTION = 'READ_PMS_PHARMACY_USER_TRANSACTION';
export const CREATE_PMS_PHARMACY_USER_TRANSACTION = 'CREATE_PMS_PHARMACY_USER_TRANSACTION';
export const MODIFY_PMS_PHARMACY_USER_TRANSACTION = 'MODIFY_PMS_PHARMACY_USER_TRANSACTION';
export const DELETE_PMS_PHARMACY_USER_TRANSACTION = 'DELETE_PMS_PHARMACY_USER_TRANSACTION';
export const SYNC_PMS_PHARMACY_USER_TRANSACTION = 'SYNC_PMS_PHARMACY_USER_TRANSACTION';

// pms reconciliation
export const CREATE_RECONCILIATION = 'CREATE_RECONCILIATION';
export const CANCEL_RECONCILIATION = 'CANCEL_RECONCILIATION';
export const READ_RECONCILIATION = 'READ_RECONCILIATION';

//notification

// global config














