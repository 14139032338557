import React, { createContext, useState } from "react";
import Notification from "../../components/common/Notification";
import PMSReportService from "../../services/pms_services/PMSReportService";

export const PMSReportContext = createContext("PMSReportContext");

const PMSReportContextProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [pharmacyReportLoading, setPharmacyReportLoading] = useState(false);
  const [pharmacyGraphReportLoading, setPharmacyGraphReportLoading] = useState(false);
  const [pharmacyTopLessReportLoading, setPharmacyTopLessReportLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const [totalElements, setTotalElements] = useState(0);

  const [report, setReport] = useState(null);
  const [accountBalanceReport, setAccountBalanceReport] = useState(null);

  //rsp = Requisition, Sale, Purchase
  const [rspData, setRSPData] = useState(null);
  const [rspGraphData, setRSPGraphData] = useState([]);
  const [rspTopLessData, setRspTopLessData] = useState(null);

  const [pmsDashBoardTopDataLoading, setPMSDashboardTopDataLoading] = useState(false);
  const [pmsDashBoardTopData, setPMSDashboardTopData] = useState(null);

  // Journals
  const [reportsJournals, setReportsJournals] = useState([]);
  const [totalDebit, setTotalDebit] = useState(0);
  const [totalCredit, setTotalCredit] = useState(0);

  // Lowest Selling product and Top selling Porduct
  const [topLowSellingProduct, setTopLowSellingProduct] = useState([]);
  // const [topSellingProduct, setTopSellingProduct] = useState([]);

  const getLowestAndTopSellingProduct = async (pharmacyId, params) => {
    try {
      setLoading(true);
      setErrorMsg("");
      const res = await PMSReportService.getPharmacyLowestAndTopSellingProductReportByCompany(pharmacyId, params);

      setTopLowSellingProduct(res.data);
      setLoading(false);
      return res.data;
    } catch (error) {
      setLoading(false);

      let _error = "Something went wrong.";

      if (error.message === "Network Error") {
        _error = error.message;
      }

      if (error.response && error.response.data) {
        _error = error.response.data.message;
      }

      Notification("error", "ERROR", `${_error}`);
      return false;
    }
  };

  const getPMSReportByPharmacyId = async (pharmacyId, params) => {
    try {
      setLoading(true);
      setErrorMsg("");
      const res = await PMSReportService.getPMSReportByPharmacyId(pharmacyId, params);

      setReport(res.data);
      setLoading(false);
      return res.data;
    } catch (error) {
      setLoading(false);

      let _error = "Something went wrong.";

      if (error.message === "Network Error") {
        _error = error.message;
      }

      if (error.response && error.response.data) {
        _error = error.response.data.message;
      }

      Notification("error", "ERROR", `${_error}`);
      return false;
    }
  };

  const getPMSAccountBalanceReport = async (pharmacyId) => {
    try {
      setLoading(true);
      setErrorMsg("");
      const res = await PMSReportService.getPMSAccountBalanceReport(pharmacyId);

      setAccountBalanceReport(res.data);
      setLoading(false);
      return true;
    } catch (error) {
      setLoading(false);

      let _error = "Something went wrong.";

      if (error.message === "Network Error") {
        _error = error.message;
      }

      if (error.response && error.response.data) {
        _error = error.response.data.message;
      }

      Notification("error", "ERROR", `${_error}`);
      return false;
    }
  };

  const generatePMSExcelReportByPharmacyId = async (pharmacyId, params) => {
    try {
      setLoading(true);
      setErrorMsg("");
      const res = await PMSReportService.generatePMSExcelReportByPharmacyId(pharmacyId, params);
      if (res) {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", res.headers["content-disposition"].split("filename=")[1]);
        document.body.appendChild(link);
        link.click();
      }
      setLoading(false);
      return res.data;
    } catch (error) {
      setLoading(false);

      let _error = "Something went wrong.";

      if (error.message === "Network Error") {
        _error = error.message;
      }

      if (error.response && error.response.data) {
        _error = error.response.data.message;
      }

      Notification("error", "ERROR", `${_error}`);
      return false;
    }
  };

  const getPharmacyReportByCompany = async (pharmacyId, params) => {
    try {
      setPharmacyReportLoading(true);
      const res = await PMSReportService.getPharmacyReportByCompany(pharmacyId, params);
      setRSPData(res.data);
      setPharmacyReportLoading(false);
    } catch (error) {
      setPharmacyReportLoading(false);
      let _error = "Something went wrong.";
      if (error.message === "Network Error") {
        _error = error.message;
      }
      if (error.response && error.response.data) {
        _error = error.response.data.message;
      }

      Notification("error", "ERROR", `${_error}`);
      return false;
    }
  };

  const getPharmacyGraphReportByCompany = async (pharmacyId, params) => {
    try {
      setPharmacyGraphReportLoading(true);
      const res = await PMSReportService.getPharmacyGraphReportByCompany(pharmacyId, params);
      setRSPGraphData(res.data);
      setPharmacyGraphReportLoading(false);
    } catch (error) {
      setPharmacyGraphReportLoading(false);
      let _error = "Something went wrong.";
      if (error.message === "Network Error") {
        _error = error.message;
      }
      if (error.response && error.response.data) {
        _error = error.response.data.message;
      }
      Notification("error", "ERROR", `${_error}`);
      return false;
    }
  };

  const getPharmacyTopLessReportByCompany = async (pharmacyId, params) => {
    try {
      setPharmacyTopLessReportLoading(true);
      const res = await PMSReportService.getPharmacyTopLessReportByCompany(pharmacyId, params);
      console.log(res.data);
      setRspTopLessData(res.data);
      setPharmacyTopLessReportLoading(false);
    } catch (error) {
      setPharmacyTopLessReportLoading(false);
      let _error = "Something went wrong.";
      if (error.message === "Network Error") {
        _error = error.message;
      }
      if (error.response && error.response.data) {
        _error = error.response.data.message;
      }
      Notification("error", "ERROR", `${_error}`);
      return false;
    }
  };

  const getPMSDashboardTopData = async (pharmacyId) => {
    try {
      setPMSDashboardTopDataLoading(true);
      const res = await PMSReportService.getPMSDashboardTopData(pharmacyId);
      setPMSDashboardTopData(res.data);
      setPMSDashboardTopDataLoading(false);
    } catch (error) {
      setPMSDashboardTopDataLoading(false);
      let _error = "Something went wrong.";
      if (error.message === "Network Error") {
        _error = error.message;
      }
      if (error.response && error.response.data) {
        _error = error.response.data.message;
      }
      Notification("error", "ERROR", `${_error}`);
      return false;
    }
  };

  const getReportJournalsListByShopId = async (id, params) => {
    let totalDebit = 0;
    let totalCredit = 0;

    try {
      setLoading(true);
      setErrorMsg("");

      const res = await PMSReportService.getAllJournalByShopId(id, params);

      setReportsJournals(res.data.content);
      setTotalElements(res.data.totalElements);

      res.data.content.forEach((journal) => {
        journal.journalEntries.forEach((journalEntry) => {
          if (journalEntry.transactionType === "DEBIT") {
            totalDebit += journalEntry.amount;
          } else {
            totalCredit += journalEntry.amount;
          }
        });
      });

      setTotalDebit(totalDebit);
      setTotalCredit(totalCredit);

      setLoading(false);
      return true;
    } catch (error) {
      setLoading(false);

      let _error = "Something went wrong.";

      if (error.message === "Network Error") {
        _error = error.message;
      }

      if (error.response && error.response.data) {
        _error = error.response.data.message;
      }

      setErrorMsg(_error);
      Notification("error", "ERROR", `${_error}`);
      return false;
    }
  };

  return (
    <PMSReportContext.Provider
      value={{
        loading,
        errorMsg,
        report,
        accountBalanceReport,
        pharmacyReportLoading,
        pharmacyGraphReportLoading,
        pharmacyTopLessReportLoading,
        rspData,
        rspGraphData,
        rspTopLessData,
        pmsDashBoardTopDataLoading,
        pmsDashBoardTopData,
        getPMSReportByPharmacyId,
        generatePMSExcelReportByPharmacyId,
        getPMSAccountBalanceReport,
        getPharmacyReportByCompany,
        getPharmacyGraphReportByCompany,
        getPharmacyTopLessReportByCompany,
        getPMSDashboardTopData,
        getReportJournalsListByShopId,
        reportsJournals,
        totalDebit,
        totalCredit,
        totalElements,
        getLowestAndTopSellingProduct,
        topLowSellingProduct,
      }}
    >
      {children}
    </PMSReportContext.Provider>
  );
};

export default PMSReportContextProvider;
