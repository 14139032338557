import React, {createContext, useState} from "react";
import {generateLocalStorageKey} from "../../helpers/Utils";
import Notification from "../../components/common/Notification";
import PMSConfigServices from "../../services/pms_services/PMSConfigServices";

export const PMSConfigContext = createContext("PMSConfigContext");

const PMSConfigContextProvider = ({children}) => {

    const _retail = JSON.parse(localStorage.getItem(generateLocalStorageKey("retail")));
    const _printingConfig = JSON.parse(localStorage.getItem("printing_config"));

    const [retail, setRetail] = useState(_retail ?? true);
    const [printingConfig, setPrintingConfig] = useState(_printingConfig ?? null);
    const [printingConfigAddLoading, setPrintingConfigAddLoading] = useState(_printingConfig ?? null);
    const [printingConfigLoading, setPrintingConfigLoading] = useState(_printingConfig ?? null);

    const addRetailConfig = data => {
        localStorage.setItem(generateLocalStorageKey("retail"), JSON.stringify(data.retail));
        setRetail(data.retail);
        if (data.retail) {
            Notification("success", "Retail", "Now you are in Retail Mode");
        } else {
            Notification("success", "Wholesale", "Now you are in Wholesale Mode");
        }
    }

    const addPrintingConfig = async (data, id) => {

        setPrintingConfigAddLoading(true);

        localStorage.setItem("printing_config", JSON.stringify(data))
        setPrintingConfig(data);
        try {
            await PMSConfigServices.addPrintingConfig(data, id);
            Notification("success", "Updated", "Printing Config has been updated successfully")
            setPrintingConfigAddLoading(false);
            return true;
        } catch (error) {
            setPrintingConfigAddLoading(false);
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }

    const getPMSPrintingConfigByPharmacyId = async id => {

        setPrintingConfigLoading(true);

        try {
            const res = await PMSConfigServices.getPrintingConfigByPharmacyId(id);
            setPrintingConfig(res.data);
            localStorage.setItem("printing_config", JSON.stringify(res.data))
            setPrintingConfigLoading(false);
            return true;
        } catch (error) {
            setPrintingConfigLoading(false);
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }

    return (
        <PMSConfigContext.Provider
            value={{
                addRetailConfig,
                addPrintingConfig,
                getPMSPrintingConfigByPharmacyId,
                printingConfigAddLoading,
                printingConfigLoading,
                printingConfig,
                retail
            }}
        >
            {children}
        </PMSConfigContext.Provider>
    )
}

export default PMSConfigContextProvider;
