import * as URL from "../../helpers/Constants";
import axios from "axios";

export default class TableService {
    static getAllTables(page, size) {
        return axios.get(URL.GET_ALL_TABLES, {...TableService.getAuthHeader(), params: {page, size}});
    }

    static getColumnConfig(tableId) {
        return axios.get(`${URL.GET_COLUMN_CONFIGS}/${tableId}`, TableService.getAuthHeader())
    }

    static addTable(data) {
        return axios.post(URL.POST_TABLE, data, TableService.getAuthHeader());
    }

    static addColumn(data, tableId) {
        return axios.post(`${URL.POST_TABLE_COLUMN}/${tableId}`, data, TableService.getAuthHeader());
    }

    static updateTable(data, id) {
        return axios.put(`${URL.UPDATE_TABLE}/${id}`, data, TableService.getAuthHeader());
    }

    static updateTableColumn(data, id) {
        return axios.put(`${URL.UPDATE_TABLE_COLUMN}/${id}/${data.id}`, data, TableService.getAuthHeader());
    }

    static updateAllTableColumns(data, id) {
        return axios.put(`${URL.UPDATE_ALL_TABLE_COLUMNS}/${id}`, data, TableService.getAuthHeader());
    }

    static deleteTable(id) {
        return axios.delete(`${URL.DELETE_TABLE}/${id}`, TableService.getAuthHeader());
    }

    static getAuthHeader() {
        const accessToken = localStorage.getItem("oushodsheba_access_token");
        const options = {headers: {"Authorization": `Bearer ${accessToken}`}}
        return options;
    }

    // static changeTableStatus(email, status) {
    //     return axios.post(`${URL.UPDATE_TABLE_STATUS}/${email}?status=${status}`)
    // }
}